import React, { useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

import {
  RiFacebookFill,
  RiLinkedinFill,
  RiTwitterXFill,
  RiYoutubeFill,
  RiInstagramFill,
  RiThreadsFill,
} from "react-icons/ri";

import axios from "../../axios";
import Icons from "../../context/Icons";

const images = {
  catimg:
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-footerwebp/Group1171277084.webp",
};
const logo =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-footerwebp/image16407.webp",
  peteyeimg =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-footerwebp/Frame1171275292.webp";

const LinkSection = () => {
  const [isNavigationOpen, setNavigationOpen] = useState(false);
  const [isLegalOpen, setLegalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [emailAdded, setEmailAdded] = useState(false);

  const toggleNavigation = () => {
    setNavigationOpen(!isNavigationOpen);
  };

  const toggleLegal = () => {
    setLegalOpen(!isLegalOpen);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.trim() !== "") {
      axios
        .post("/web/emailsubscription", { email })
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status == "200") {
            setEmail("");
            setEmailAdded(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const footerData = [
    {
      id: 1,
      icon: <Icons name={"shop-deliver-icon"} />,
      description: "Same Day Vaccination",
    },
    {
      id: 2,
      icon: <Icons name={"shop-free-retun"} />,
      description: "Free Return ",
    },
    {
      id: 3,
      icon: <Icons name={"shop-doorstep-icon"} />,
      description: "Rapid Care ",
    },
  ];

  return (
    <section>
      <section className="bg-white text-black h-[24vh]  w-full flex flex-row align-top items-center justify-evenly">
        {footerData.map((item) => (
          <div className="flex flex-col md:flex-row items-center gap-[3vw] md:gap-[1vw] ">
            <span className="flex flex-col gap-[.5vh] justify-center items-center rounded-[50%] bg-black w-[40px] h-[40px]  md:w-[50px] md:h-[50px] xl:h-[auto] xl:w-[auto] xl:p-[1vw] text-white shadow-[0px_.2vw_.5vw_0px_rgba(0,0,0,0.15)] overflow-hidden ">
              {item.icon}
            </span>
            <p className="text-[clamp(.8rem,1vw,3rem)] font-[Prata]  text-center">
              {item.description}
            </p>
          </div>
        ))}
      </section>
      <section className=" bg-black text-white  h-[32vh] flex items-center justify-center  md:h-[35vh]">
        <div className=" w-full p-[1rem]  md:w-[80vw] xl:w-[70vw] m-auto flex items-center justify-center flex-col h-auto md:h-[35vh] gap-[2vh]">
          <div className=" flex flex-col items-center gap-[1vw] md:gap-[2vw] xl:gap-[1vh]  ">
            <h4 className="text-[clamp(1.1rem,4vw,7rem)] font-bold md:text-[clamp(2rem,3vw,7rem)] xl:text-[clamp(1.5rem,1.5vw,6rem)] font-[Prata] text-center">
              PetEye's Inbox: Safe & Connected!
            </h4>
            <p className="text-[clamp(.7rem,2vw,5rem)] md:text-[clamp(.8rem,1vw,4.5rem)] text-center font-[Poppins] md:w-[50vw]">
              Sign up for product updates, offers, and learn more about PET EYE
              and its Services. Must be over 16 years to sign up.
            </p>
          </div>
          <div className="w-full flex justify-center">
            <form onSubmit={handleSubmit}>
              <div className="flex items-center gap-[1.5vw]">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="h-[4vh] md:h-[5vh] xl:h-[6vh] p-[2vw] md:p-[.7vw] rounded-[1vw] md:rounded-[.7vw]  border-none outline-none text-black text-[clamp(.8rem,1vw,4.5rem)] placeholder:text-gray-400 placeholder:text-[clamp(.8rem,1vw,4.5rem)] w-[50vw] md:w-[30vw] xl:w-[20vw]"
                />
                <button
                  type="submit"
                  className="bg-white text-black h-[4vh] md:h-[5vh]  xl:h-[6vh] rounded-[2vw] border-none outline-none md:rounded-[.7vw] p-[2vw] px-[3vw]  md:py-[.8vw] md:px-[1.5vw] text-[clamp(.8rem,1vw,4.5rem)] font-[Prata]"
                >
                  {emailAdded ? "Joined !" : "Join Us"}
                </button>
              </div>
            </form>
          </div>
          <div className="">
            <p className="text-[clamp(.8rem,2.2vw,5rem)] md:text-[clamp(.8rem,1vw,4.5rem)] text-center font-[Poppins] md:w-[50vw]">
              See our privacy statement to find out how we collect and use your
              data, to contact us with privacy questions, or to exercise your
              personal data rights.
            </p>
          </div>
        </div>
      </section>
      <section className="  md:flex justify-center items-center flex-col  m-auto  w-[80vw] ">
        <section className="w-full  hidden md:block">
          <div className="">
            <div className="footer-content border-bottom py-[2vh]   ">
              <div className="flex gap-[5vw] ">
                <div className="flex gap-[1vh] flex-col">
                  <b className="text-[clamp(1rem,1.2vw,4rem)] font-[Prata]">
                    NAVIGATION
                  </b>
                  <div className="flex gap-[.5vh] flex-col">
                    <li>
                      <a
                        href="/#hero"
                        className="text-[clamp(.9rem,1vw,4rem)] font-[Prata]"
                      >
                        Home
                      </a>
                    </li>
                    <li>
                      <Link
                        to="/home"
                        className="text-[clamp(.9rem,1vw,4rem)] font-[Prata]"
                      >
                        MyPet
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/nfc"
                        className="text-[clamp(.9rem,1vw,4rem)] font-[Prata]"
                      >
                        PetLink
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/article"
                        className="text-[clamp(.9rem,1vw,4rem)] font-[Prata]"
                      >
                        Pet'O Pedia
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/store"
                        className="text-[clamp(.9rem,1vw,4rem)] font-[Prata]"
                      >
                        Shop
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/search/"
                        className="text-[clamp(.9rem,1vw,4rem)] font-[Prata]"
                      >
                        Search
                      </Link>
                    </li>
                  </div>
                </div>
                <div className="flex gap-[1vh] flex-col">
                  <b className="text-[clamp(1rem,1.2vw,4rem)] font-[Prata]">
                    LEGAL
                  </b>
                  <div className="flex gap-[.5vh] flex-col ">
                    <li>
                      <Link
                        to="/refundpolicy/"
                        className="text-[clamp(.9rem,1vw,4rem)] font-[Prata]"
                      >
                        Refund Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/privacypolicy/"
                        className="text-[clamp(.9rem,1vw,4rem)] font-[Prata]"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/terms&condition/"
                        className="text-[clamp(.9rem,1vw,4rem)] font-[Prata]"
                      >
                        Terms and condition
                      </Link>
                    </li>
                  </div>
                </div>
              </div>
              <div className="footer-content-sec-2  ">
                <div>
                  <img
                    src={
                      "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/cat.png"
                    }
                    alt="petcare support"
                    title="petcare support"
                    className="h-[25vh] md:w-[25vw]  object-contain"
                  />
                  <a href="mailto:care@peteye.pet" target="_blank">
                    <button className="bg-black text-white rounded-[.6vw] p-[.4vw] text-[clamp(.8rem,1vw,3rem)] font-[Prata] ">
                      Contact Us
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="footer w-full  hidden  md:flex items-center justify-between">
          <div className="col-md-4 d-flex jusify-content-start">
            <div className="">
              <img
                src={logo}
                alt="img"
                title="peteye logo"
                className=" h-[3.5vh] xl:h-[5vh] object-contain"
              />
            </div>
          </div>
          <>
            <div className="col-md-4 ">
              <p className="footer_para text-[clamp(1rem,1.2vw,4rem)]">
                &copy;2023 K R PET EYE LLP. All Right Reserved.
              </p>
            </div>
            <div className="col-md-4 d-flex justify-content-end">
              <div className="socialmedia d-flex justify-content-end">
                <span className="w-[30px] h-[30px] xl:w-[2.5vw] xl:h-[4.5vh] ">
                  <a
                    href="https://www.facebook.com/PeteyeHub/"
                    style={{ textDecoration: "none", color: "black" }}
                    target="_blank"
                    className=""
                  >
                    <RiFacebookFill className=" footer_icon" />
                  </a>
                </span>
                <span className="w-[30px] h-[30px] xl:w-[2.5vw] xl:h-[4.5vh] ">
                  <a
                    href="https://www.linkedin.com/company/kr-pet-eye-llp/"
                    style={{ textDecoration: "none", color: "black" }}
                    target="_blank"
                  >
                    <RiLinkedinFill className=" footer_icon" />
                  </a>
                </span>
                <span className="w-[30px] h-[30px] xl:w-[2.5vw] xl:h-[4.5vh] ">
                  <a
                    href="https://twitter.com/Peteyehub"
                    style={{ textDecoration: "none", color: "black" }}
                    target="_blank"
                  >
                    <RiTwitterXFill className=" footer_icon" />
                  </a>
                </span>
                <span className="w-[30px] h-[30px] xl:w-[2.5vw] xl:h-[4.5vh] ">
                  <a
                    href="https://www.instagram.com/peteyehub?igsh=eGVkdHByN3RwZXZi"
                    style={{ textDecoration: "none", color: "black" }}
                    target="_blank"
                  >
                    <RiInstagramFill className=" footer_icon" />
                  </a>
                </span>
                <span className="w-[30px] h-[30px] xl:w-[2.5vw] xl:h-[4.5vh] ">
                  <a
                    href="https://www.threads.net/@peteyehub'"
                    style={{ textDecoration: "none", color: "black" }}
                    target="_blank"
                  >
                    <RiThreadsFill className=" footer_icon" />
                  </a>
                </span>
              </div>
            </div>
          </>
        </section>
      </section>
      {/* mobilefooter */}
      <section className="mobilefooter p-5">
        <div className="flex items-center justify-start">
          <img
            className="mobilefooterlogo w-100 h-[4vh] object-contain"
            src={logo}
            alt="peteye logo"
            title="peteye logo"
          />
          <img
            src={peteyeimg}
            className="w-100 h-[4vh] object-contain"
            alt="peteye logo"
            title=" peteye text"
          />
        </div>
        <div>
          <h4 onClick={toggleNavigation}>
            <span className="navplus">NAVIGATION</span>{" "}
            {isNavigationOpen ? "-" : "+"}
          </h4>
          {isNavigationOpen && (
            <div className="mfnav">
              <a href="/#hero">Home</a>
              <Link to="/home">MyPet</Link>
              <Link to="/nfc">PetLink</Link>
              <Link to="/article">Pet'o Pedia</Link>
              <Link to="/store">Shop</Link>
              <Link to="/search/">Search</Link>
            </div>
          )}
        </div>
        <div>
          <h4 onClick={toggleLegal}>
            <span className="legalplus">LEGAL</span> {isLegalOpen ? "-" : "+"}
          </h4>
          {isLegalOpen && (
            <div className="mfnav">
              <Link to="/refundpolicy/">Refund Policy</Link>
              <Link to="/privacypolicy/">Privacy Policy</Link>
              <Link to="/terms&condition/">Terms & Conditions</Link>
            </div>
          )}
        </div>
        <div className="mfsocial">
          <span>
            <a
              href="https://www.facebook.com/PeteyeHub/"
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
            >
              {" "}
              <RiFacebookFill />
            </a>
          </span>
          <span>
            <a
              href="https://www.linkedin.com/company/kr-pet-eye-llp/"
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
            >
              {" "}
              <RiLinkedinFill />
            </a>
          </span>
          <span>
            <a
              href="https://twitter.com/Peteyehub"
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
            >
              {" "}
              <RiTwitterXFill />
            </a>
          </span>
          <span>
            <a
              href="https://www.youtube.com/channel/UC4XYxZ8YILbvGPy4lUkHmqw"
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
            >
              {" "}
              <RiYoutubeFill />
            </a>
          </span>
          <span>
            <a
              href="https://www.instagram.com/peteyehub?igsh=eGVkdHByN3RwZXZi"
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
            >
              {" "}
              <RiInstagramFill />
            </a>
          </span>
          <span>
            <a
              href="https://www.threads.net/@peteyehub'"
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
            >
              {" "}
              <RiThreadsFill />
            </a>
          </span>
        </div>

        <p className="mflasttext">
          &copy;2023 K R PET EYE LLP. All Right Reserved.
        </p>
      </section>
    </section>
  );
};

export default LinkSection;
