import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "../../../axios";
import DogPettingIcon from "../../../Pages/Homepage/DogPettingIcon";
import ReturnCancel from "./ReturnCancel";

export default function TrackDetails({ Eachorder }) {
  const navigate = useNavigate();
  const loginToken = Cookies.get("loginToken");
  const { login } = useSelector((state) => state);
  const userInfo = login.details.userInfo;
  const [returnCancel, setreturnCancel] = useState(false);
  const [timeSlot, setTimeSlot] = useState("");
  const [slotDate, setSlotDate] = useState("");

  const fetchTimeSlot = async () => {
    try {
      const response = await axios.get(`/shop/gettimeslots/${Eachorder.id}`, {
        headers: {
          Authorization: loginToken,
        },
      });
      if (response.data) {
        const { date, timeslot } = response.data.message;
        const formattedDate = formatDate(date); // Format the date
        const formattedSlot = formatTimeSlot(timeslot); // Format the timeslot

        setTimeSlot(formattedSlot);
        setSlotDate(formattedDate);

        console.log(`On ${formattedDate}, ${formattedSlot} slot`);
      }
    } catch (error) {
      console.error("Error fetching time slot:", error);
    }
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options).replace(",", ""); // Example: "25th Feb 2025"
  };

  const formatTimeSlot = (timeslot) => {
    const [startTime, endTime] = timeslot.split(" - "); // Split the timeslot into start and end times
    return `${convertTo12Hour(startTime)} to ${convertTo12Hour(endTime)}`;
  };

  const convertTo12Hour = (time) => {
    let [hour, minute] = time.split(":").map(Number); // Split time into hour and minute
    const period = hour >= 12 ? "pm" : "am"; // Determine am/pm
    hour = hour % 12 || 12; // Convert hour to 12-hour format
    return `${hour}:${minute.toString().padStart(2, "0")}${period}`;
  };

  useEffect(() => {
    fetchTimeSlot();
  }, []);

  const handleReturnCancel = () => {
    setreturnCancel(!returnCancel);
  };

  if (!Eachorder?.ShippingAddress) {
    return null;
  }
  console.log(Eachorder, "Eachorder");

  const vaccineStatuses = [
    "Vaccination Scheduled",
    "Para-Vet is on the Way",
    "Para-Vet has Arrived",
    "Vaccination Completed",
  ];

  // Helper function to determine if a status is checked
  const isStatusChecked = (currentStatus, stepStatus, statusList) => {
    return statusList.indexOf(currentStatus) >= statusList.indexOf(stepStatus);
  };

  // Define status steps based on purchase_status
  const getStatusSteps = () => {
    const returnStatus = Eachorder.return_status || "Not_Returned";

    if (vaccineStatuses.includes(Eachorder.purchase_status)) {
      return vaccineStatuses.map((status, index) => {
        const date =
          status === "Vaccination Scheduled"
            ? Eachorder.CreatedAt.split("T")[0]
            : Eachorder[`${status.toLowerCase().replace(/\s+/g, "_")}_date`];

        return {
          id: index + 1,
          name: status,
          date: date || null, // Only include date if it exists
          location: "Hyderabad",
          icon: isStatusChecked(
            Eachorder.purchase_status,
            status,
            vaccineStatuses
          )
            ? "trackorder-status-checked"
            : "trackorder-status-unchecked",
        };
      });
    }

    if (Eachorder.purchase_status === "Cancelled") {
      return [
        {
          id: 1,
          name: "Order Received",
          date: Eachorder.CreatedAt.split("T")[0],
          location: "Hyderabad",
          icon: "trackorder-status-checked",
        },
        {
          id: 2,
          name: "Cancelled",
          date: Eachorder.cancelled_date || null, // Show only if date exists
          location: "Hyderabad",
          icon: "trackorder-status-cancelled",
        },
      ];
    }

    if (Eachorder.purchase_status === "Delivered") {
      const steps = [
        {
          id: 1,
          name: "Order Received",
          date: Eachorder.CreatedAt.split("T")[0],
          location: "Hyderabad",
          icon: "trackorder-status-return",
        },
      ];

      if (returnStatus !== "Not_Returned") {
        const returnSteps = [
          "Return Requested",
          "Return Approved",
          "Return Picked",
          "Refund Initiated",
          "Refund Completed",
        ];

        return steps.concat(
          returnSteps.map((status, index) => {
            const date =
              Eachorder[`${status.toLowerCase().replace(/\s+/g, "_")}_date`];
            return {
              id: index + 2,
              name: status,
              date: date || null, // Show only if date exists
              location: "Hyderabad",
              icon:
                returnStatus.replace(/_/g, " ") === status
                  ? "trackorder-status-return"
                  : "trackorder-status-unchecked",
            };
          })
        );
      }

      return steps;
    }

    const deliveryStatuses = [
      "Order Received",
      "Order Packed",
      "In-Transit",
      "Out for Delivery",
      "Delivered",
    ];

    return deliveryStatuses.map((status, index) => {
      const date =
        status === "Order Received"
          ? Eachorder.CreatedAt.split("T")[0]
          : Eachorder[
              `${status
                .toLowerCase()
                .replace(/-/g, "_")
                .replace(/\s+/g, "_")}_date`
            ];

      return {
        id: index + 1,
        name: status,
        date: date || null, // Show only if date exists
        location: "Hyderabad",
        icon: isStatusChecked(
          Eachorder.purchase_status,
          status,
          deliveryStatuses
        )
          ? "trackorder-status-checked"
          : "trackorder-status-unchecked",
      };
    });
  };

  const status = getStatusSteps();

  return (
    <>
      {Eachorder.ShippingAddress.map((item, index) => (
        <div
          key={index}
          className="flex flex-col items-start w-full p-[1vw] gap-[2.5vh]"
        >
          <div className="flex flex-col items-start w-[80%]">
            <h2 className="text-[rgba(0,0,0,0.5)] text-[clamp(.9rem,1vw,2.5rem)]">
              Delivery Address:
            </h2>
            <p className="text-[clamp(.9rem,1vw,2.5rem)] text-start">
              {item.houseNo} {item.area} {item.city} {item.pincode}
            </p>
          </div>
          <div className="flex flex-row items-start justify-between w-[80%]">
            <div className="flex flex-col items-start w-[40%]">
              <h2 className="text-[rgba(0,0,0,0.5)] text-[clamp(.9rem,1vw,2.5rem)]">
                Order Id
              </h2>
              <p className="text-[clamp(.9rem,1vw,2.5rem)] text-start">
                {Eachorder.order_id}
              </p>
            </div>
            <div className="flex flex-col items-start w-[50%]">
              <h2 className="text-[rgba(0,0,0,0.5)] text-[clamp(.9rem,1vw,2.5rem)]">
                Telephone Number
              </h2>
              <p className="text-[clamp(.9rem,1vw,2.5rem)] text-start">
                {item.mobile}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-start justify-between w-[80%]">
            <div className="flex flex-col items-start w-[40%]">
              <h2 className="text-[rgba(0,0,0,0.5)] text-[clamp(.9rem,1vw,2.5rem)]">
                Name
              </h2>
              <p className="text-[clamp(.9rem,1vw,2.5rem)] text-start">
                {item.name}
              </p>
            </div>
            <div className="flex flex-col items-start w-[50%]">
              <h2 className="text-[rgba(0,0,0,0.5)] text-[clamp(.9rem,1vw,2.5rem)]">
                Email ID
              </h2>
              <p className="text-[clamp(.9rem,1vw,2.5rem)] text-start">
                {userInfo.email}
              </p>
            </div>
          </div>
          {vaccineStatuses.includes(Eachorder.purchase_status) && (
            <div className="flex flex-row items-start justify-between w-[80%]">
              <div className="flex flex-col items-start w-[100%]">
                <h2 className="text-[rgba(0,0,0,0.5)] text-[clamp(.9rem,1vw,2.5rem)]">
                  You Choose Home Service Vaccination
                </h2>
                <p className="text-[clamp(.9rem,1vw,2.5rem)] text-start">
                  On {slotDate}, {timeSlot} slot
                </p>
              </div>
            </div>
          )}
          <div className="flex flex-col items-start w-full gap-[2vh] mt-[3vh]">
            {status.map((step) => (
              <div
                key={step.id}
                className="flex items-center justify-between p-[1vw] bg-[#f5f5f5] w-full rounded-[1.5vw]"
              >
                <div className="flex items-center flex-row gap-[2vw]">
                  <div className="flex items-center justify-center">
                    <DogPettingIcon name={step.icon} />
                  </div>
                  <div className="flex flex-col items-start">
                    <h1 className="text-[clamp(1rem,1.2vw,4rem)] font-[600] font-[Manrope]">
                      {step.name}
                    </h1>
                    {step.date && (
                      <p className="text-[clamp(.8rem,1vw,3rem)] text-[rgba(0,0,0,0.5)] font-[Manrope]">
                        {step.date !== "00-00-0000"
                          ? `(${step.date})`
                          : "(00-00-0000)"}
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  {[
                    "Cancelled",
                    "Order Packed",
                    "In-Transit",
                    "Out for Delivery",
                    "Delivered",
                  ].includes(Eachorder.purchase_status) && (
                    <p className="flex flex-row items-center">
                      <span>
                        <DogPettingIcon name="trackorder-status-location" />
                      </span>
                      <span className="text-[#000] text-[clamp(.8rem,.9vw,3rem)] capitalize">
                        {step.location}
                      </span>
                    </p>
                  )}
                </div>
              </div>
            ))}
            {![
              "Cancelled",
              "Order Packed",
              "In-Transit",
              "Out for Delivery",
              "Delivered",
              "Para-Vet is on the Way",
              "Para-Vet has Arrived",
              "Vaccination Completed",
            ].includes(Eachorder.purchase_status) && (
              <div
                className="flex items-center justify-center w-full"
                onClick={handleReturnCancel}
              >
                <button className="bg-black text-[#fff] text-[clamp(1rem,1vw,3rem)] px-[1vw] py-[.5vw] shadow-[0px_.2vw_.7vw_rgba(0,0,0,0.25)] rounded-[1vw] capitalize font-[OpenSans] font-[600] outline-none border-none">
                  {Eachorder.purchase_status === "Delivered"
                    ? "Return order"
                    : "Cancel Order"}
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
      {returnCancel && (
        <ReturnCancel
          isOpen={handleReturnCancel}
          isClose={handleReturnCancel}
          ReturnCancel={
            Eachorder.purchase_status === "Delivered"
              ? "Return Order"
              : "Cancel Order"
          }
          Eachorder={Eachorder}
        />
      )}
    </>
  );
}
