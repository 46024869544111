import React, { useState, useRef } from "react";
import axios from "../../axios";
import Cookies from "js-cookie";

export const OtpInput = ({
  length,
  onChange,
  placeholders,
  updateOtpStatus,
  otpMessage,
  selectPetModal,
}) => {
  const loginToken = Cookies.get("loginToken");

  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);
  const handleChange = async (element, index) => {
    const value = element.value.slice(0, 1); // Ensure only one character is entered

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Automatically focus the next input field if value is entered
    if (index < length - 1 && value) {
      inputRefs.current[index + 1].focus();
    }

    const otpString = newOtp.join("");

    // Handle the case when OTP is fully entered
    if (otpString.length === length) {
      // Set status to 'verifying' and show message
      updateOtpStatus("verifying");
      otpMessage("Verifying");

      // Delay the verification process by 1500 milliseconds
      setTimeout(async () => {
        try {
          const response = await axios.get(`/nfc/checkqrcode/P${otpString}`, {
            headers: {
              Authorization: loginToken,
            },
          });
          console.log("response of qr", response);

          if (response.data.status === 200) {
            console.log("response.data.success " + response.data.success);
            console.log("response.data.status " + response.data.status);

            selectPetModal(true);
            console.log(response.data);
          } else {
            console.log("response.data.success " + response.data.success);
            console.log("response.data.status " + response.data.status);

            console.log(response.data);
            updateOtpStatus("error");
            otpMessage("Code Not Found");
          }
        } catch (error) {
          console.error("Error verifying OTP:", error);
          updateOtpStatus("error");
          otpMessage("Error Verifying Code");
        }
      }, 1500); // 1500 milliseconds delay
    } else if (otpString.length === 0) {
      // No OTP entered, show "Looking for code"
      updateOtpStatus("looking");
      otpMessage("Looking for code");
    } else {
      // OTP is incomplete, show "Looking for code"
      updateOtpStatus("looking");
      otpMessage("Looking for code");
    }

    // Handle backspace key
    if (element.value === "" && otp[index] !== "") {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      // Set message to "Looking for code" when deleting characters
      if (otpString.length === 0) {
        updateOtpStatus("looking");
        otpMessage("Looking for code");
      }
    }

    if (onChange) {
      onChange(otpString);
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (!otp[index] && index > 0) {
        inputRefs.current[index - 1].focus();
      }

      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      const otpString = newOtp.join("");
      if (otpString.length < length) {
        // Revert the otpStatus to 'normal' when the user starts removing characters
        updateOtpStatus("normal");
        otpMessage("Looking for code");
      }

      if (onChange) {
        onChange(otpString);
      }
    }
  };

  return (
    <>
      <input
        type="text"
        value="P"
        readOnly
        className=""
        style={{
          width: "1.2vw",
          height: "2vh",
          margin: ".5vw",
          textAlign: "center",
          fontSize: "clamp(14px, 1vw, 48px)",
        }}
      />
      {otp.map((data, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          value={data}
          onChange={(e) => handleChange(e.target, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (inputRefs.current[index] = el)}
          placeholder="*"
          style={{
            width: "1.2vw",
            height: "2vh",
            margin: ".5vw",
            textAlign: "center",
            fontSize: "clamp(14px, 1vw, 48px)",
          }}
        />
      ))}
    </>
  );
};
