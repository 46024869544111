import React, { useState } from "react";
import "./Overview.css";
import Companion from "./Companion/Companion";
import Reminder from "./Reminder/Reminder";
import Weight from "./Weight/Weight";
import PetFamily from "./PetFamily/PetFamily";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import { AcceptInvite } from "../../../../Components/AcceptInvite/AcceptInvite";
import { BreedHub } from "./BreedHub/BreedHub";
import { Quirks } from "./Quirks/Quirks";
import { RabiesVaccination } from "../Settings/RabiesVaccination";

const formatDate = (dateString) => {
  const options = { day: "numeric", month: "long", year: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", options);
};

const Overview = ({ petInfo, login, currentPetId }) => {
  const [isCompanionOpen, setIsCompanionOpen] = useState(false);
  const [isWeightOpen, setIsWeightOpen] = useState(false);
  const [isReminderOpen, setIsReminderOpen] = useState(false);
  const [isRedVisible, setIsRedVisible] = useState(false);
  const [modalContent, setModalContent] = useState("lost");
  const [isRedVisible2, setIsRedVisible2] = useState(false);
  const [isPetFamilyOpen, setIsPetFamilyOpen] = useState(false);
  const [isBreedHubOpen, setIsBreedHubOpen] = useState(false);
  const [isQuirksOpen, setIsQuirksOpen] = useState(false);
  const [bookVaccine, setBookVaccine] = useState(false);
    const [vaccinationData, setVaccinationData] = useState([]);
  
  console.log("Info:", petInfo);

  const handleOpenQuirks = () => {
    setIsQuirksOpen(true);
  };
  const handleCloseQuirks = () => {
    setIsQuirksOpen(false);
  };

  const handleOpenBreedHub = () => {
    setIsBreedHubOpen(true);
  };

  const handleCloseBreedHub = () => {
    setIsBreedHubOpen(false);
  };

  const handleBookVaccine = () => {
    setBookVaccine(true);
    console.log("button clicked");
  };

  const handleOpenWeight = () => {
    setIsWeightOpen(true);
  };
  const handleCloseWeight = () => {
    setIsWeightOpen(false);
  };

  const handleOpenCompanion = () => {
    setIsCompanionOpen(true);
    setIsRedVisible(false);
  };

  const handleOpenReminder = () => {
    setIsReminderOpen(true);
    setIsRedVisible2(false);
  };

  const handleCloseCompanion = () => {
    setIsCompanionOpen(false);
  };

  const handleCloseReminder = () => {
    setIsReminderOpen(false);
  };
  const handleCompanionSubmit = () => {
    setIsRedVisible(true);
    handleCloseCompanion();
    setIsCompanionOpen(false);
    setModalContent("found");
  };

  const handleOpenPetFamily = () => {
    setIsPetFamilyOpen((prev) => !prev);
  };

  // Assuming petInfo has a property called birthDate
  const birthDate = petInfo?.birthDate
    ? formatDate(petInfo.birthDate)
    : "Unknown Date";

  const getWeightNumber = (weight) => {
    const match = weight.match(/(\d+)\s*(\w+)/);
    return match
      ? { number: match[1], unit: match[2] }
      : { number: "0", unit: "" };
  };
  const weightDetails = getWeightNumber(petInfo?.petWeight);
  console.log("Weight:", weightDetails.number, weightDetails.unit);

  const handleVaccinationDataChange = (data) => {
    setVaccinationData(data);
    setBookVaccine(false)
    };
  const overviewData = [
    {
      id: 1,
      img: <DogPettingIcon name={"birthday-cake"} />,
      title: "Date of Birth",
      data: birthDate,
    },
    {
      id: 2,
      img: <DogPettingIcon name={"gender"} />,
      title: "Gender",
      data: petInfo?.sex || "Unknown",
    },
    {
      id: 3,
      img: <DogPettingIcon name={"weight-icon"} />,
      title: "Weight",
      data: `${weightDetails.number} ${weightDetails.unit}` || "Unknown",
    },
    // {
    //   id: 4,
    //   img: <DogPettingIcon name={"Breedhub"} />,
    //   title: " Breed Hub",
    //   data: "",
    // },

    {
      id: 4,
      img: <DogPettingIcon name={"bookvaccineinjection"} />,
      data: "Vaccination",
      title: "Book Vaccination",
    },
    {
      id: 5,
      img: <DogPettingIcon name={"Quirks"} />,
      data: "Quirks",
      title: "Key health and care information",
    },
    {
      id: 6,
      img: <DogPettingIcon name={"companion"} />,
      data: "Report Lost",
      title: "Report to our wider pet community",
    },
  ];

  const petType =
    petInfo?.petType?.trim().toLowerCase() === "dog"
      ? "Dog or Canids"
      : petInfo?.petType?.trim().toLowerCase() === "cat"
      ? "Cat or Feline"
      : petInfo?.petType || "Unknown";

  console.log(petInfo, "petInfo.id");

  return (
    <div className="grid-container">
      {overviewData.map((item, index) => (
        <div
          key={item.id}
          className="grid-item"
          onClick={
            index === 2
              ? handleOpenWeight
              : index === 3
              ? handleBookVaccine
              : index === 4
              ? handleOpenQuirks
              : index === 5
              ? handleOpenCompanion
              : null
          }
        >
          {((index === 4 && isRedVisible) ||
            (index === 5 && isRedVisible2)) && (
            <div className="small-red-div"></div>
          )}
          <div className="svgicon">{item.img}</div>
          <h3>{item.data}</h3>
          <p>{item.title}</p>
        </div>
      ))}
      <Weight
        isOpen={isWeightOpen}
        onClose={handleCloseWeight}
        petId={petInfo}
        initialWeight={petInfo?.petWeight}
      />
      <RabiesVaccination
        isOpen={bookVaccine}
        isClose={()=>setBookVaccine(false)}
        vaccinated={false}
        petType={petType}
        onVaccinationDataChange={handleVaccinationDataChange}
        isFromMedicalRecord={true}
        petId={petInfo}
      />
      <Companion
        isOpen={isCompanionOpen}
        onClose={handleCloseCompanion}
        onSubmit={handleCompanionSubmit}
        content={modalContent}
        setcontent={setModalContent}
        redBubble={setIsRedVisible}
        userInfo={login?.details?.userInfo}
        currentPetId={currentPetId}
        petInfo={petInfo}
      />
      {/* <BreedHub
        isOpen={isBreedHubOpen}
        isClose={handleCloseBreedHub}
        petId={petInfo}
        currentPetId={currentPetId}
      /> */}
      <Quirks
        isOpen={isQuirksOpen}
        isClose={handleCloseQuirks}
        petId={petInfo}
        currentPetId={currentPetId}
      />
      <PetFamily
        isOpen={isPetFamilyOpen}
        onClose={handleOpenPetFamily}
        pet={petInfo}
      />
    </div>
  );
};

export default Overview;
