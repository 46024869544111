import React from "react";
import Failedgif from "../../../Pages/Failed/Failedgif";

export default function BillDetails() {
  return (
    <div>
      <Failedgif message="No Bill Details" />
    </div>
  );
}
