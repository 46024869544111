import React, { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import { ChevronDown, Upload } from "lucide-react";
import DogPettingIcon from "../../../Pages/Homepage/DogPettingIcon";
import axios from "../../../axios";
import Cookies from "js-cookie";
import UploadModal from "../UploadModal/UploadModal";

export default function ReturnCancel({
  ReturnCancel,
  isClose,
  isOpen,
  Eachorder,
}) {
  const [isClosing, setIsClosing] = useState(false);
  const [isOpendropdown, setIsOpendropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [otherText, setOtherText] = useState("");
  const [fileUpload, setFileUpload] = useState(null);
  const loginToken = Cookies.get("loginToken");
  const [openUplaod, setOpenUplaod] = useState(false);

  const options = [
    "The product is no longer needed",
    "the product is purchased in error",
    "the estimated delivery date is no longer suitable",
    "I found the better deal",
    "Other",
  ];

  const returnOptions = [
    "the product is not as right size or fit",
    "the product is damaged or defective",
    "the product is not as described",
    "The product is no longer needed",
    "i have received the wrong product",
    "other",
  ];

  const handleCancel = async () => {
    try {
      const response = await axios.post(
        "/shop/cancelorder",
        {
          orderId: Eachorder.order_id,
          cancellationReason: displayValue,
        },
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );
      console.log(response.data, "cancel order");
      if (response.data.status === 200) {
        handleClose();
      }
    } catch (error) {
      console.error("Error cancelling order:", error);
    }
  };

  const handleReturn = async () => {
    // try {
    //   const response = await axios.post("/shop/returnorder", {
    //     headers: {
    //       Authorization: loginToken,
    //     },
    //   });
    //   console.log(response.data, "return order");
    //   if (response.data.success) {
    //     handleClose();
    //   }
    // } catch (error) {
    //   console.error("Error returning order:", error);
    // }
  };

  const handleSubmit = () => {
    if (ReturnCancel === "Cancel Order") {
      handleCancel();
    } else {
      handleReturn();
    }
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpendropdownOpen(false);
    if (option !== "Other") {
      setOtherText("");
    }
  };

  const handleOtherTextChange = (e) => {
    setOtherText(e.target.value);
  };

  const handleFileUpload = () => {
    setOpenUplaod((prev) => !prev);
  };

  const displayValue =
    selectedOption === "Other" && otherText
      ? otherText
      : selectedOption || `Select a reason for ${ReturnCancel}`;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000);
  };

  const getProductInfo = (Eachorder) => {
    if (!Eachorder?.productinfomodel?.length) {
      return {
        name: "Product Unavailable",
        image: "/placeholder-image.jpg",
        date: Eachorder?.CreatedAt
          ? Eachorder.CreatedAt.split("T")[0]
          : "Date unavailable",
        count: 0,
      };
    }

    return {
      name:
        Eachorder.productinfomodel[0]?.Product?.ProductName ||
        "Unnamed Product",
      image:
        Eachorder.productinfomodel[0]?.Product?.productImagemodel?.[0]?.url ||
        "/placeholder-image.jpg",
      count: Eachorder.productinfomodel.length,
    };
  };

  const productInfo = getProductInfo(Eachorder);

  return (
    <div>
      <Modal
        modaltitle={ReturnCancel}
        handleClose={handleClose}
        handleOverlayClick={handleOverlayClick}
        isClosing={isClosing}
        iconname={""}
      >
        <div className="h-[60vh] overflow-auto flex flex-col items-center gap-[2.5vh] no-scrollbar">
          <div
            key={Eachorder.order_id || Math.random()}
            className="flex items-center justify-between p-[1.2vw] bg-[#f5f5f5] w-[30vw] rounded-[1.5vw]"
          >
            <div className="flex items-center flex-row gap-[2vw]">
              <div className="flex items-center justify-center bg-[#FFF] p-[.5vw] rounded-[1vw] relative">
                <img
                  src={productInfo.image}
                  alt="track-product-image"
                  className="w-[4vw] h-[7vh] object-contain"
                />
                {productInfo.count > 1 && (
                  <p className="absolute -bottom-0 right-0 text-[#000] rounded-[50%] bg-[#FFF] w-[1.2vw] h-[1.2vw] flex items-center justify-center border-[1px] border-[#559F7A] text-[clamp(.5rem,.5vw,2rem)]">
                    {productInfo.count}
                  </p>
                )}
              </div>
              <div className="flex flex-col items-start">
                <h1 className="text-[clamp(1rem,1.2vw,4rem)] font-[600] w-[20vw] font-[Manrope] text-start text-ellipsis overflow-hidden whitespace-nowrap">
                  #{Eachorder.order_id}
                </h1>
                {productInfo.count > 1 && (
                  <p className="text-[clamp(.8rem,1vw,3rem)] text-[rgba(0,0,0,0.5)] font-[Manrope]">
                    and {productInfo.count} more items
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="p-[1.2vw] bg-[#f5f5f5] w-[30vw] rounded-[1.5vw]">
            <div className="relative">
              <button
                onClick={() => setIsOpendropdownOpen(!isOpendropdown)}
                className="w-full bg-white rounded-[.5vw] h-[7vh] px-[.8vw] py-[.8vw] text-left text-gray-700 flex justify-between items-center"
              >
                <span className="truncate">{displayValue}</span>
                <ChevronDown
                  className={`transition-transform duration-200 ${
                    isOpendropdown ? "transform rotate-180" : ""
                  }`}
                />
              </button>

              {isOpendropdown && (
                <div className="absolute w-full mt-[.5vh] bg-white rounded-lg shadow-lg overflow-hidden z-10">
                  {(ReturnCancel === "Return Order"
                    ? returnOptions
                    : options
                  ).map((option) => (
                    <button
                      key={option}
                      onClick={() => handleSelect(option)}
                      className="w-full px-[.8vw] py-[.8vw] text-left hover:bg-gray-50 text-gray-700 text-[clamp(.8rem,1vw,3rem)]"
                    >
                      {option}
                    </button>
                  ))}
                </div>
              )}
            </div>

            {selectedOption.toLowerCase() === "other" && (
              <div className="mt-[2vh]">
                <textarea
                  value={otherText}
                  onChange={handleOtherTextChange}
                  placeholder="Write In Detail*"
                  className="w-full px-[.8vw] py-[.8vw] h-[18vh] rounded-lg transition-all duration-200 resize-none outline-none"
                />
              </div>
            )}

            {ReturnCancel === "Return Order" && (
              <div className="mt-[2vh]">
                <div className="w-full bg-white rounded-lg p-[1vw] cursor-pointer">
                  <div className="flex flex-row items-center justify-between gap-[1vh] cursor-pointer">
                    <span className="text-[clamp(.8rem,1vw,3rem)] text-gray-600">
                      Attach File
                    </span>
                    <span onClick={handleFileUpload} className="cursor-pointer">
                      <DogPettingIcon name="trackorder-fileupload" />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center justify-start w-[30vw] gap-[1vw] rounded-[1.5vw]">
            <input type="checkbox" />
            <p className="text-[clamp(.8rem,.8vw,3rem)] text-[rgba(0,0,0,0.5)] font-[Manrope]">
              I accept the terms and conditions of submitting the{" "}
              {ReturnCancel.toLowerCase()}
            </p>
          </div>
          <div>
            <button
              onClick={handleSubmit}
              className="bg-black text-white px-[1.5vw] py-[.6vw] rounded-[1.5vw] flex items-center justify-center gap-[.5vw]"
            >
              <DogPettingIcon name="check" />
              Submit
            </button>
          </div>
        </div>
        {openUplaod && (
          <UploadModal isOpen={handleFileUpload} isClose={handleFileUpload} />
        )}
      </Modal>
    </div>
  );
}
