import React, { useState, useEffect } from "react";
import "../../../Profile/Petprofile/Settings/Settingsmodal.css";
import Success from "../../../Profile/Success/Success";
import "../../../Profile/Petprofile/Overview/PetFamily/InvitePetFamily.css";
import axios from "../../../../axios";
import Cookies from "js-cookie";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
export const ForgotChangePassword = ({
  isOpen,
  isClose,
  setOpenEnterOTP,
  setOpenChangePasswordSetting,
  userData,
}) => {
  const loginToken = Cookies.get("loginToken");
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });
  const [error, setError] = useState("");
  const [passwordRules, setPasswordRules] = useState({
    minLength: false,
    specialChar: false,
    number: false,
  });
  const [isModalClosing, setIsModalClosing] = useState(false);
  const [startedTyping, setStartedTyping] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    const { newPassword } = passwords;

    setPasswordRules({
      minLength: newPassword.length >= 8,
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword),
      number: /\d/.test(newPassword),
    });
  }, [passwords.newPassword]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
    setStartedTyping(true);
    setError(""); // Clear any existing errors when user starts typing
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      isClose();
      setIsModalClosing(true);
    }
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handlePasswordChange = async () => {
    const { newPassword, confirmNewPassword } = passwords;
    const allRulesPassed = Object.values(passwordRules).every(Boolean);

    // Error checks
    if (!newPassword || !confirmNewPassword) {
      setError("Please fill in all fields");
      return;
    }

    if (!allRulesPassed) {
      setError("Please follow all password rules");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        "/auth/forgetpassword",
        {
          Email:
            userData.contactType === "mobile" ? userData.email : userData.email,
          Password: newPassword,
          confirmpassword: confirmNewPassword,
        },
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );

      console.log("Response:", response.data);

      if (response.data.status === 200) {
        setSuccess(true);
        setTimeout(() => {
          setOpenChangePasswordSetting(false);
          setOpenEnterOTP(false);
          navigate("/home"); // Redirect to login page
        }, 3000);
      } else {
        setError(response.data.message || "Failed to change password");
      }
    } catch (error) {
      console.error("Error changing password:", error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setError(error.response.data.message || "Failed to change password");
      } else if (error.request) {
        // The request was made but no response was received
        setError("No response from server. Please try again.");
      } else {
        // Something happened in setting up the request that triggered an Error
        setError("An error occurred. Please try again.");
      }
    }
  };

  const handleClose = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setIsModalClosing(false);
      isClose();
    }, 1000);
  };

  return (
    <>
      <div
        className={`modal-overlay2 ${isModalClosing ? "fade-out" : ""}`}
        onClick={handleOverlayClick}
      >
        <div className={`modal-container2 ${isModalClosing ? "close" : ""}`}>
          <div className="modal-top-header">
            <div className="modal-title">
              <p>Change Password</p>
            </div>
            <div className="modal-close" onClick={handleClose}>
              <DogPettingIcon
                name={"closemodal"}
                className="modal-close-icon"
              />
            </div>
          </div>
          <div className="setting-modal-description">
            {success ? (
              <Success
                message={"Password Changed"}
                gif={
                  "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/success.gif"
                }
                onClose={handleClose}
              />
            ) : (
              <div className="change-password">
                <div className="get-code-about">
                  <p>
                    Enter your new password below to update your account
                    security.
                  </p>
                </div>
                <div className="modal-input-group">
                  <div className="password-input-container">
                    <input
                      type={showNewPassword ? "text" : "password"}
                      name="newPassword"
                      placeholder="Enter New Password"
                      value={passwords.newPassword}
                      onChange={handleChange}
                      className={
                        startedTyping &&
                        (!passwordRules.minLength ||
                          !passwordRules.specialChar ||
                          !passwordRules.number)
                          ? "input-invalid"
                          : ""
                      }
                    />
                    <span
                      onClick={toggleNewPasswordVisibility}
                      className="password-toggle-icon"
                    >
                      {showNewPassword ? (
                        <FaEye style={{ cursor: "pointer" }} />
                      ) : (
                        <FaEyeSlash style={{ cursor: "pointer" }} />
                      )}
                    </span>
                  </div>
                  <div className="password-input-container">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmNewPassword"
                      placeholder="Confirm New Password"
                      value={passwords.confirmNewPassword}
                      onChange={handleChange}
                      className={
                        startedTyping &&
                        passwords.newPassword !== passwords.confirmNewPassword
                          ? "input-invalid"
                          : ""
                      }
                    />
                    <span
                      onClick={toggleConfirmPasswordVisibility}
                      className="password-toggle-icon"
                    >
                      {showConfirmPassword ? (
                        <FaEye style={{ cursor: "pointer" }} />
                      ) : (
                        <FaEyeSlash style={{ cursor: "pointer" }} />
                      )}
                    </span>
                  </div>
                  <div className="password-rules">
                    <span
                      className={
                        startedTyping && !passwordRules.minLength
                          ? "rule-invalid"
                          : startedTyping
                          ? "rule-valid"
                          : ""
                      }
                    >
                      <DogPettingIcon name={"gray-tick"} /> 8 Characters Minimum
                    </span>
                    <span
                      className={
                        startedTyping && !passwordRules.specialChar
                          ? "rule-invalid"
                          : startedTyping
                          ? "rule-valid"
                          : ""
                      }
                    >
                      <DogPettingIcon name={"gray-tick"} /> 1 Special Character
                    </span>
                    <span
                      className={
                        startedTyping && !passwordRules.number
                          ? "rule-invalid"
                          : startedTyping
                          ? "rule-valid"
                          : ""
                      }
                    >
                      <DogPettingIcon name={"gray-tick"} /> 1 number
                    </span>
                  </div>
                </div>
                {error && (
                  <div className="error-message">
                    <p>{error}</p>
                  </div>
                )}
                <div className="modal-control">
                  <button onClick={handlePasswordChange}>
                    <DogPettingIcon name={"accept"} />
                    Confirm
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
