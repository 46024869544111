import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";

import "../Overview/PetFamily/InvitePetFamily.css";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";

export const PetLicensesModal = ({ isOpen, isClose }) => {
  const [isModalClosing, setIsModalClosing] = useState(false);
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
      // setIsModalClosing((prev) => !prev);
    }
  };

  const handleClose = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setIsModalClosing(false);
      isClose();
    }, 1000); // Match the duration of the closing animation
  };

  return (
    <div
      className={`modal-overlay ${isModalClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isModalClosing ? "close" : ""}`}>
        <div className="modal-close" onClick={handleClose}>
          <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
        </div>
        <div className="m-auto">
          <img
            className="w-[20vw] h-[20vw] object-contain"
            src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/Frame+1597886061.webp"
          />
          <p className="text-[clamp(1.2rem,1.5vw,4rem)] font-[700] font-[Open Sans] text-center">
            Coming Soon
          </p>
        </div>
      </div>
    </div>
  );
};
