import React, { useState } from "react";
import "../Login/Login.css";
import ConfirmVerification from "../ConfirmVerification/ConfirmVerification";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import AlertBox from "../../../../Components/Alert/Alert";
import axios from "../../../../axios";

import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import Axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import PetOwnerDetails from "../Registration/PetOwnerDetails/PetOwnerDetails";

function Signup({ setShowTogglebtn }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setshowConPassword] = useState(false);
  const [openConfirmVF, setopenConfirmVF] = useState(false);
  const [googleAuthStatus, setgoogleAuthStatus] = useState(false);
  const [systemError, setSystemError] = useState("");
  const [errors, setErrors] = useState({
    firstName: "",
    email: "",
    mobile: "",
    dateOfBirth: "",
    password: "",
    confirmPassword: "",
  });

  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + 6 * 60 * 60 * 1000);

  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    mobile: "",
    password: "",
    confirmPassword: "",
    dateOfBirth: "",
  });

  const [systemErrors, setSystemErrors] = useState("");
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_APP_ID,
        cookie: true,
        xfbml: true,
        version: "v12.0",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  const handleFacebookLogin = () => {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          window.FB.api(
            "/me",
            { fields: "id,name,email,picture,short_name,last_name" },
            async (fbResponse) => {
              dispatch({
                type: "socialData",
                payload: { googleData: fbResponse },
              });
              try {
                const resp = await axios.post("/auth/socialregister", {
                  email: fbResponse.email,
                  username: fbResponse.name,
                  password: fbResponse.id,
                  Login_os: null,
                  profileImage: fbResponse.picture.data.url,
                  loginType: "Facebook",
                });
                if (resp.data.status === "200") {
                  Cookies.set("signupToken", resp.data.message.userInfo.token, {
                    expires: expirationDate,
                  });
                  if (resp.data.message.userExist) {
                    Cookies.set(
                      "loginToken",
                      resp.data.message.userInfo.token,
                      {
                        expires: expirationDate,
                      }
                    );
                    dispatch({
                      type: "login",
                      login: { ...resp.data.message },
                    });
                    dispatch({
                      type: "cart_details",
                      cart_details: resp.data.message.cartdetails,
                    });
                    dispatch({ type: "refresh" });
                    window.location.reload();
                  } else {
                    dispatch({
                      type: "user",
                      payload: { signupData: resp.data.message.userInfo },
                    });
                    setgoogleAuthStatus(true);
                    setShowTogglebtn(false);
                  }
                }
              } catch (error) {
                console.error(error);
              }
            }
          );
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      { scope: "email,public_profile" }
    );
  };

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const res = await Axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );
        if (res.status === 200) {
          dispatch({ type: "socialData", payload: { googleData: res.data } });

          axios
            .post("/auth/socialregister", {
              email: res.data.email,
              username: res.data.name,
              password: res.data.sub,
              Login_os: null,
              profileImage: res.data.picture,
              loginType: "Google",
            })
            .then((resp) => {
              if (resp.data.status === 200) {
                Cookies.set("signupToken", resp.data.message.userInfo.token, {
                  expires: expirationDate,
                });
                if (resp.data.message.userExist === true) {
                  Cookies.set("loginToken", resp.data.message.userInfo.token, {
                    expires: expirationDate,
                  });
                  dispatch({ type: "login", login: { ...resp.data.message } });
                  dispatch({
                    type: "cart_details",
                    cart_details: resp.data.message.cartdetails,
                  });
                  dispatch({ type: "refresh" });
                  window.location.reload();
                } else {
                  dispatch({
                    type: "user",
                    payload: { signupData: resp.data.message.userInfo },
                  });
                  setgoogleAuthStatus(true);
                  setShowTogglebtn(false);
                }
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "firstName":
        if (!value.trim()) {
          error = "Name is required";
        } else if (!/^[a-zA-Z\s]*$/.test(value)) {
          error = "First name can only contain alphabets";
        } else if (value.length > 25) {
          error = "First name cannot exceed 25 characters";
        }
        break;

      case "email":
        if (value.trim() !== "") {
          if (!value.includes("@") || !value.includes(".")) {
            error = "Invalid email format";
          } else {
            const atSymbolCount = value.split("@").length - 1;
            if (atSymbolCount !== 1) {
              error = 'Email must contain exactly one "@"';
            } else {
              const atIndex = value.indexOf("@");
              const dotIndex = value.lastIndexOf(".");
              if (dotIndex < atIndex) {
                error = 'Email must have a "." after the "@"';
              }
            }
          }
        }
        break;

      case "mobile":
        if (value.trim() !== "") {
          if (!/^\d{10}$/.test(value)) {
            error = "Mobile number must be 10 digits";
          }
        }
        break;

      case "dateOfBirth":
        if (!value) {
          error = "Date of birth is required";
        } else {
          const birthDate = new Date(value);
          const today = new Date();

          // Ensure valid date
          if (isNaN(birthDate.getTime())) {
            error = "Invalid date format";
            return error;
          }

          // Calculate age
          let age = today.getFullYear() - birthDate.getFullYear();
          const monthDiff = today.getMonth() - birthDate.getMonth();

          if (
            monthDiff < 0 ||
            (monthDiff === 0 && today.getDate() < birthDate.getDate())
          ) {
            age--;
          }

          if (birthDate > today) {
            error = "Date cannot be in the future";
          } else if (age < 18) {
            error = "You must be at least 18 years old";
          }
        }
        break;

      case "password":
        if (!value.trim()) {
          error = "Password is required";
        } else if (
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
            value
          )
        ) {
          error = "Password must have 8+ characters";
        }
        break;

      case "confirmPassword":
        if (!value.trim()) {
          error = "Confirm password is required";
        } else if (value !== formData.password) {
          error = "Passwords do not match";
        }
        break;

      default:
        break;
    }

    return error;
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickshowConPassword = () => setshowConPassword(!showConPassword);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "mobile") {
      newValue = value.replace(/[^0-9]/g, "").slice(0, 10);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));

    const error = validateField(name, newValue);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate all fields
    const newErrors = {};
    Object.keys(formData).forEach((field) => {
      newErrors[field] = validateField(field, formData[field]);
    });

    // Check if email or mobile is provided
    if (formData.email.trim() === "" && formData.mobile.trim() === "") {
      newErrors.email = "Please provide either email or mobile number";
      newErrors.mobile = "Please provide either email or mobile number";
    }

    setErrors(newErrors);

    // Check if there are any errors
    if (Object.values(newErrors).some((error) => error !== "")) {
      return;
    }

    // Proceed with form submission
    axios
      .post("/auth/register", { ...formData })
      .then((response) => {
        console.log(response.data);
        if (response.data.status === 402) {
          // setErrors((prev) => ({
          //   ...prev,
          //   [response.data.field]: response.data.message,
          // }));
          setSystemError(response.data.message);
          return;
        }

        if (response.data.status === 403) {
          setSystemError(response.data.message);
          return;
        }

        if (response.data.status === 200) {
          Cookies.set("signupToken", response.data.message.userInfo.token, {
            expires: expirationDate,
          });
          dispatch({
            type: "login",
            payload: { login: null, details: response.data.message },
          });
          setopenConfirmVF(true);
          setShowTogglebtn(false);
        }
      })
      .catch((err) => {
        setErrors((prev) => ({
          ...prev,
          general: "Internal Server Error Occurred",
        }));
      });
  };
  return (
    <>
      {openConfirmVF ? (
        <ConfirmVerification />
      ) : googleAuthStatus ? (
        <PetOwnerDetails />
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <div className="new-user-form">
              <div
                className={`relative inline-block ${
                  errors.firstName && "h-[9vh]"
                }`}
              >
                <input
                  className={`textfield-size pr-[2vw] ${
                    errors.firstName ? "border border-[#FF0000]" : ""
                  }`}
                  id=""
                  type="text"
                  placeholder="Full Name"
                  variant="outlined"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
                <span className="absolute right-[.5vw] top-[25%] translateY[-50%] pointer-events-none ">
                  <DogPettingIcon name="login-nameicon" />
                </span>
                {errors.firstName && (
                  <p className="text-[#FF0000] text-[clamp(0.5rem,.9vw,3rem)] mt-1 mb-[1vh]  leading-none">
                    {errors.firstName}
                  </p>
                )}
              </div>
              <div
                className={`relative inline-block ${errors.email && "h-[9vh]"}`}
              >
                <input
                  className={`textfield-size pr-[2vw] ${
                    errors.email ? "border border-[#FF0000]" : ""
                  }`}
                  id=""
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <span className="absolute right-[.5vw] top-[38%] translateY[-50%] pointer-events-none ">
                  <DogPettingIcon name="login-mail" />
                </span>
                {errors.email && (
                  <p className="text-[#FF0000] text-[clamp(0.5rem,.9vw,3rem)] mt-1 mb-[1vh]  leading-none">
                    {errors.email}
                  </p>
                )}
              </div>

              <div
                className={`relative inline-block ${
                  errors.mobile && "h-[9vh]"
                }`}
              >
                <input
                  className={`textfield-size pr-[2vw] ${
                    errors.mobile ? "border border-[#FF0000]" : ""
                  }`}
                  id=""
                  placeholder="Phone Number"
                  variant="outlined"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleInputChange}
                />
                <span className="absolute right-[.5vw] top-[38%] translateY[-50%] pointer-events-none ">
                  <DogPettingIcon name="login-numbericon" />
                </span>
                {errors.mobile && (
                  <p className="text-[#FF0000] text-[clamp(0.5rem,.9vw,3rem)] mt-1 mb-[1vh]  leading-none">
                    {errors.mobile}
                  </p>
                )}
              </div>
              <div
                className={`inline-block  ${errors.dateOfBirth && "h-[9vh]"}`}
              >
                <input
                  className={`textfield-size pr-[2vw] placeholder ${
                    errors.dateOfBirth ? "border border-[#FF0000]" : ""
                  }`}
                  type="date"
                  id=""
                  placeholder="Enter your date of birth"
                  name="dateOfBirth"
                  value={formData.dateOfBirth}
                  onChange={handleInputChange}
                />
                {errors.dateOfBirth && (
                  <p className="text-[#FF0000] text-[clamp(0.5rem,.9vw,3rem)] mt-1 mb-[1vh]  leading-none">
                    {errors.dateOfBirth}
                  </p>
                )}
              </div>

              <div
                className={`relative inline-block ${
                  errors.password && "h-[9vh]"
                }`}
              >
                <div className="textfield-inp-wrapper">
                  <input
                    className={`textfield-size pr-[2vw] ${
                      errors.password ? "border border-[#FF0000]" : ""
                    }`}
                    id="password"
                    placeholder="Password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                  <span
                    className="password-icon"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? (
                      <FaEye style={{ cursor: "pointer" }} />
                    ) : (
                      <FaEyeSlash style={{ cursor: "pointer" }} />
                    )}
                  </span>
                  {errors.password && (
                    <p className="text-[#FF0000] text-[clamp(0.5rem,.9vw,3rem)] mt-1 mb-[1vh]  leading-none">
                      {errors.password}
                    </p>
                  )}
                </div>
              </div>

              <div
                className={`relative inline-block ${
                  errors.confirmPassword && "h-[9vh]"
                }`}
              >
                <div className="textfield-inp-wrapper">
                  <input
                    className={`textfield-size pr-[2vw] ${
                      errors.confirmPassword ? "border border-[#FF0000]" : ""
                    }`}
                    id="confirmPassword"
                    placeholder="Confirm password"
                    variant="outlined"
                    type={showConPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                  />
                  <span
                    className="password-icon"
                    onClick={handleClickshowConPassword}
                  >
                    {showPassword ? (
                      <FaEye style={{ cursor: "pointer" }} />
                    ) : (
                      <FaEyeSlash style={{ cursor: "pointer" }} />
                    )}
                  </span>
                  {errors.confirmPassword && (
                    <p className="text-[#FF0000] text-[clamp(0.5rem,.9vw,3rem)] mt-1 mb-[1vh]  leading-none">
                      {errors.confirmPassword}
                    </p>
                  )}
                </div>
              </div>

              <button type="submit">Register</button>
              {systemError && (
                <p className="text-[#FF0000] text-[clamp(0.5rem,.9vw,3rem)] leading-none">
                  {systemError}
                </p>
              )}
            </div>
          </form>
          <div className="loginsocial" style={{ marginTop: "1px" }}>
            <hr className="line" />
            <p>continue with</p>
            <hr className="line" />
          </div>
          <div className="socialinks2">
            <p
              onClick={() => login()}
              className=" flex items-center justify-center shadow-[0px_0px_.5vw_0px_rgba(0,0,0,0.25)] rounded-[.5vw] p-[.3vw]"
            >
              <DogPettingIcon name={"google"} />
            </p>
            <p className="cursor-not-allowed flex items-center justify-center shadow-[0px_0px_.5vw_0px_rgba(0,0,0,0.25)] rounded-[.5vw] p-[.3vw]">
              <DogPettingIcon name={"apple"} />
            </p>
            <p
              className="cursor-not-allowed flex items-center justify-center shadow-[0px_0px_.5vw_0px_rgba(0,0,0,0.25)] rounded-[.5vw] p-[.3vw]"
              title="Passkeys Comming Soon"
            >
              <DogPettingIcon name={"passkey"} />
            </p>
          </div>
        </>
      )}
    </>
  );
}

export default Signup;
