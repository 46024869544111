import React, { useState } from "react";
import Success from "../../../Pages/Profile/Success/Success";
import DogPettingIcon from "../../../Pages/Homepage/DogPettingIcon";
import { LuUpload } from "react-icons/lu";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { AiFillFilePdf } from "react-icons/ai";
import { IoDocumentText } from "react-icons/io5";
import axios from "../../../axios";

export default function UploadModal({ isOpen, isClose }) {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isClosing1, setIsClosing1] = useState(false);
  const loginToken = Cookies.get("loginToken");
  const { login } = useSelector((state) => state);

  const uploadFileToS3 = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post("/file/uploadFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: loginToken,
        },
      });
      const url = response.data.Message ? response.data.Message.getPeteye : "";
      return url;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      return null;
    }
  };
  if (!isOpen) return null;

  const handleUploadModalClose = () => {
    setIsClosing1(true);
    setTimeout(() => {
      setIsClosing1(false);
      handleClose();
      setIsUploadModalOpen(false);
      setUploadStatus("");
      setSelectedFiles([]);
    }, 1000);
    // Clear selectedFiles here to ensure it resets
  };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };

  const handleFileUpload = () => {
    const file = selectedFiles[0];
    const url = uploadFileToS3(file);
  };
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
      handleUploadModalClose();
    }
  };
  const handleClose = () => {
    setIsClosing1(true);
    setTimeout(() => {
      setIsClosing1(false);
      isClose();
    }, 1000); // Match the duration of the closing animation
  };
  return (
    <div>
      <div
        className={`modal-overlay ${isClosing1 ? "fade-out" : ""}`}
        onClick={handleOverlayClick}
      >
        <div className={`modal-container ${isClosing1 ? "close" : ""}`}>
          <div className="add-new-documents">
            <div className="modal-close" onClick={handleClose}>
              <DogPettingIcon
                name={"closemodal"}
                className="modal-close-icon"
              />
            </div>

            {uploadStatus === "success" ? (
              <Success
                message="Upload successfully!"
                header="Success"
                gif="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/success.gif"
              />
            ) : uploadStatus === "failed" ? (
              <Success
                message="Oops! No files found."
                gif="https://peteye-drive.s3.ap-south-1.amazonaws.com/Supportwebp/DrowningDog.webp"
              />
            ) : (
              <div className="add-new-documents-content">
                <div className="records-Upload-title">
                  <p>
                    <IoDocumentText
                      style={{ fontSize: "clamp(1.3rem, 1.5vw ,4rem)" }}
                    />
                  </p>
                  <h2>Upload Documents</h2>
                </div>
                <div className="modal-close" onClick={handleUploadModalClose}>
                  <DogPettingIcon
                    name={"closemodal"}
                    className="modal-close-icon"
                  />
                </div>

                <div className="upload-doc-files">
                  <label htmlFor="fileInputUpload">
                    <p>
                      <DogPettingIcon name="upload-doc" />
                    </p>
                    <div className="upload-doc-files-title">
                      <h5>
                        <LuUpload /> <h6>Upload</h6>
                      </h5>
                      <p>Maximum Size: 3MB</p>
                    </div>
                  </label>
                  <input
                    type="file"
                    accept="application/pdf"
                    id="fileInputUpload"
                    style={{ display: "none" }}
                    onChange={handleFileSelect}
                  />
                </div>
                {selectedFiles.length > 0 && (
                  <div className="uploaded-preview-list">
                    {selectedFiles.map((file, index) => (
                      <div key={index} className="uploaded-file-preview">
                        <div className="uploaded-file-preview-title">
                          <h4>
                            <AiFillFilePdf
                              style={{
                                fontSize: "clamp(1.5rem, 1.5vw ,5rem)",
                              }}
                            />
                          </h4>
                          <div className="uploaded-file-preview-name">
                            <h5
                              style={{
                                cursor: "pointer",
                                textDecoration: "none",
                              }}
                            >
                              {file.name}
                            </h5>
                            <p>{(file.size / 1024 / 1024).toFixed(2)} MB</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {selectedFiles.length > 0 && (
                  <div className="upload-file-btn" onClick={handleFileUpload}>
                    <p>
                      <LuUpload
                        style={{ fontSize: "clamp(1.3rem, 1.5vw ,3.5rem)" }}
                      />
                    </p>
                    <p>Upload</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
