import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "./Notification.css";
import Modal from "../../../shop/components/Modal/Modal";
import Cookies from "js-cookie";
import axios from "../../../axios";
import DogPettingIcon from "../../../Pages/Homepage/DogPettingIcon";
import FailedGif from "../../../Pages/Failed/Failedgif";

export default function Notification({ isOpen, isClose }) {
  const [isClosing, setIsClosing] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const loginToken = Cookies.get("loginToken");

  const categoriesIcon = [
    {
      id: 1,
      category: "General",
      icon: "notification",
    },
    {
      id: 2,
      category: "Product",
      icon: "notification-product",
    },
    {
      id: 3,
      category: "orders",
      icon: "notification3",
    },
    {
      id: 4,
      category: "Reminders",
      icon: "notification-vaccination",
    },
    {
      id: 5,
      category: "Updates",
      icon: "notification-injection",
    },
    {
      id: 6,
      category: "Pet Supplements",
      icon: "notification-supplements",
    },
    {
      id: 7,
      category: "Pet Food",
      icon: "notification-food",
    },
    {
      id: 8,
      category: "Pet Health",
      icon: "notification-health",
    },
  ];

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get("/user/allnotifications", {
        headers: {
          Authorization: loginToken,
        },
      });
      setNotifications(response.data.message);
      console.log("Notifications:", response.data.message);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      isClose();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000);
  };

  const getIconName = (notification) => {
    const matchedCategory = categoriesIcon.find(
      (category) => category.category === notification.category
    );
    return matchedCategory ? matchedCategory.icon : "default-icon";
  };

  const groupNotificationsByDate = (notifications) => {
    const grouped = notifications.reduce((acc, notification) => {
      const date = new Date(notification.createdAt);
      const dateStr = date.toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      if (!acc[dateStr]) {
        acc[dateStr] = [];
      }
      acc[dateStr].push(notification);
      return acc;
    }, {});

    // Sort dates in descending order
    return Object.entries(grouped).sort(
      (a, b) => new Date(b[0]) - new Date(a[0])
    );
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
    return `${formattedHours}:${minutes} ${period}`;
  };

  if (!isOpen) return null;

  const groupedNotifications = groupNotificationsByDate(notifications);

  return (
    <div>
      <Modal
        modaltitle={"Notifications"}
        handleClose={handleClose}
        handleOverlayClick={handleOverlayClick}
        isClosing={isClosing}
        iconname={"bell"}
      >
        <div className="flex flex-col  px-[.2vw] w-full overflow-auto no-scrollbar h-[60vh]">
          {notifications.length > 0 ? (
            groupedNotifications.map(([date, dateNotifications]) => (
              <div key={date} className="flex flex-col gap-[2vh]">
                <h2 className="text-[clamp(.8rem,1vw,3rem)] text-left font-[550] ">
                  {date}
                </h2>
                {dateNotifications.map((notification, index) => (
                  <div
                    key={index}
                    className="flex flex-row items-center justify-between shadow-[0_.2vw_.7vw_0_rgba(0,0,0,0.15)] p-[.5vw] rounded-[.5vw]"
                  >
                    <div className="flex flex-row items-center gap-[1vw]">
                      <div className="w-[3vw]">
                        <DogPettingIcon name={getIconName(notification)} />
                      </div>
                      <div className="flex flex-col items-start px-[.2vw] w-[21vw]">
                        <h1 className="text-[clamp(.8rem,1vw,3rem)]">
                          {notification.title}
                        </h1>
                        <p className="text-[clamp(.6rem,.8vw,2rem)] text-[rgba(0,0,0,0.6)] text-start">
                          {notification.content}
                        </p>
                      </div>
                    </div>
                    <p className="text-[clamp(.6rem,.8vw,2rem)] flex flex-row items-center gap-[.2vw]">
                      {formatTime(notification.createdAt)}
                      <DogPettingIcon name={"notification-clock"} />
                    </p>
                  </div>
                ))}
              </div>
            ))
          ) : (
            <p>
              <FailedGif message={"No Notifications"} />
            </p>
          )}
        </div>
      </Modal>
    </div>
  );
}
