import React, { useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { AlignJustify, X } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import Menu from "./menu";
import HeaderMenu from "./HeaderMenu";
import Icons from "../../context/Icons";
import Notification from "./Notification/Notification";

export default function NewHeader({ handleDropdownVisibility }) {
  const logo =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-footerwebp/image16407.webp";
  const [menuOpen, setMenuOpen] = useState(false);
  const [HeaderMenuOpen, setHeaderMenuOpen] = useState(false);
  const [CartOpen, setCartOpen] = useState(false);
  const [NotificationOpen, setNotificationOpen] = useState(false);

  const { login } = useSelector((state) => state);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = login?.details?.userInfo;

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const toggleNotification = () => {
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }
    setNotificationOpen((prev) => !prev);
  };

  const handleHeaderMenuClick = () => {
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }
    setHeaderMenuOpen((prev) => !prev);
  };

  const navItems = [
    { path: "/", label: "Home" },
    { path: "/home", label: "My Pet" },
    { path: "/nfc", label: "Tag link" },
    { path: "/article", label: "Pet'o Pedia" },
    { path: "/store", label: "Shop" },
  ];

  const isLoggedIn = login?.login !== null && login?.login !== false;

  const shouldBeFixed =
    location.pathname === "/" ||
    !navItems.some(
      (item) =>
        location.pathname === item.path ||
        location.pathname.startsWith(item.path)
    );

  // Determine z-index based on HeaderMenuOpen state
  const zIndexClass = HeaderMenuOpen ? "" : "z-[9999]";

  return (
    <div>
      <div
        className={`flex justify-between px-[3vw] py-[1.5vh] md:justify-around items-center md:py-[1.2vh] w-full 
          transition-all duration-300 
          ${shouldBeFixed ? `fixed top-0 ${zIndexClass}` : "relative"} 
          ${
            menuOpen
              ? "bg-white/100 backdrop-blur-lg"
              : "bg-white/85 backdrop-blur-sm"
          }`}
      >
        <NavLink to="/#hero">
          <img
            className="h-[4.5vh] md:h-[3.5vh] xl:h-[5.5vh] w-auto header-logoimg object-contain"
            src={logo}
            alt="peteye logo"
            title="peteye logo"
          />
        </NavLink>

        <div className="hidden md:flex justify-around items-center w-[45vw] xl:w-[35vw] ml-[5vw] xl:ml-[3vw]">
          {navItems.map((item) => (
            <NavLink
              key={item.path}
              to={item.path}
              className={({ isActive }) =>
                isActive
                  ? "active text-black text-[clamp(1rem,1.2vw,6rem)] capitalize"
                  : "text-black text-opacity-50 text-[clamp(1rem,1.2vw,6rem)] capitalize"
              }
            >
              {item.label}
            </NavLink>
          ))}
        </div>

        <div className="hidden md:flex justify-around items-center gap-3 relative">
          {/* <Link to="/search/">
            <Icons name="header-search" />
          </Link> */}
          <span onClick={toggleNotification} className="cursor-pointer">
            <Icons name="notificationbell-icon" />
          </span>
          <span onClick={handleHeaderMenuClick} className="cursor-pointer">
            <Icons name="header-user" />
          </span>
          {isLoggedIn && HeaderMenuOpen && (
            <HeaderMenu
              isMenuOpen={handleHeaderMenuClick}
              isClose={handleHeaderMenuClick}
            />
          )}
        </div>

        <div className="md:hidden">
          <button onClick={toggleMenu}>
            {menuOpen ? <X /> : <AlignJustify />}
          </button>
        </div>
      </div>

      {NotificationOpen && (
        <Notification
          isOpen={true}
          isClose={() => setNotificationOpen(false)}
        />
      )}

      {menuOpen && <Menu className="nav-btn" />}
    </div>
  );
}
