import React from "react";
import "./Comingsoon.css";

function Comingsoon() {
  return (
    <div>
      <div className="coming-soon">
        <h1 style={{ textAlign: "center" }}>Coming Soon</h1>
        <img
          src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/Home/comingsoon.png"
          alt=""
        />
        <p>
          Exciting news ahead! While our web app gears up for launch, why not
          seize the moment and get our mobile app from the Play Store or Apple
          Store? Stay tuned for more updates. Happy downloading!
        </p>
        <div className="appstore">
          <img
            className="cursor-pointer"
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=com.peteye.app&pli=1",
                "_blank"
              );
            }}
            src="https://peteye-drive.s3.ap-south-1.amazonaws.com/landing+page/google-play-badge+1.png"
            alt="Google Play Store"
          />
          <img
            className="cursor-pointer"
            onClick={() => {
              window.open(
                "https://apps.apple.com/in/app/peteye/id6450916668",
                "_blank"
              );
            }}
            src="https://peteye-drive.s3.ap-south-1.amazonaws.com/landing+page/app-store-badge+1.png"
            alt="Apple App Store"
          />
        </div>
      </div>
    </div>
  );
}

export default Comingsoon;
