import React, { useEffect, useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import AOS from "aos";

const Android =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landing+page/google-play-badge+1.png",
  apple =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landing+page/app-store-badge+1.png";

const Menu = () => {
  const [homeDropdownOpen, setHomeDropdownOpen] = useState(false);
  const [heartDropdownOpen, setHeartDropdownOpen] = useState(false);
  const [aboutDropdownOpen, setAboutDropdownOpen] = useState(false);
  const [guideDropdownOpen, setGuideDropdownOpen] = useState(false);
  const [supportDropdownOpen, setSupportDropdownOpen] = useState(false);
  const [shopDropdownOpen, setShopDropdownOpen] = useState(false);
  const [careerDropdownOpen, setCareerDropdownOpen] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 200 });
  }, []);
  // for mobile

  const [isHomeSubMenuVisible, setHomeSubMenuVisibility] = useState(false);
  const [isAboutUsSubMenuVisible, setAboutUsSubMenuVisibility] =
    useState(false);
  const [isMainMenuVisible, setMainMenuVisibility] = useState(true);
  const [isServiceSubMenuVisible, setServiceSubMenuVisibility] =
    useState(false);

  const handleHomeClick = () => {
    setHomeSubMenuVisibility(!isHomeSubMenuVisible);
    setAboutUsSubMenuVisibility(false);
    setServiceSubMenuVisibility(false);
    setMainMenuVisibility(false);
  };

  const handleAboutUsClick = () => {
    setAboutUsSubMenuVisibility(!isAboutUsSubMenuVisible);
    setHomeSubMenuVisibility(false);
    setServiceSubMenuVisibility(false);
    setMainMenuVisibility(false);
  };

  const handleServiceClick = () => {
    setServiceSubMenuVisibility(!isServiceSubMenuVisible);
    setHomeSubMenuVisibility(false);
    setAboutUsSubMenuVisibility(false);
    setMainMenuVisibility(false);
  };

  const handleSubmenuClick = (option) => {
    console.log(`Clicked on ${option}`);
    setHomeSubMenuVisibility(false);
    setAboutUsSubMenuVisibility(false);
    setServiceSubMenuVisibility(false);
    setMainMenuVisibility(true);
  };

  //end of mobile

  const toggleHomeDropdown = () => {
    closeDropdowns();
    setHomeDropdownOpen(!homeDropdownOpen);
  };
  const toggleHeartDropdown = () => {
    closeDropdowns();
    setHeartDropdownOpen(!heartDropdownOpen);
  };
  const toggleAboutDropdown = () => {
    closeDropdowns();
    setAboutDropdownOpen(!aboutDropdownOpen);
  };
  const toggleGuideDropdown = () => {
    closeDropdowns();
    setGuideDropdownOpen(!guideDropdownOpen);
  };
  const toggleSupportDropdown = () => {
    closeDropdowns();
    setSupportDropdownOpen(!supportDropdownOpen);
  };
  const toggleShopDropdown = () => {
    closeDropdowns();
    setShopDropdownOpen(!shopDropdownOpen);
    // window.location.href = "https://dashboard.peteye.pet/store";
  };
  const toggleCareerDropdown = () => {
    closeDropdowns();
    setCareerDropdownOpen(!shopDropdownOpen);
  };
  const closeDropdowns = () => {
    setHomeDropdownOpen(false);
    setHeartDropdownOpen(false);
    setAboutDropdownOpen(false);
    setGuideDropdownOpen(false);
    setSupportDropdownOpen(false);
    setShopDropdownOpen(false);
    setCareerDropdownOpen(false);
  };

  return (
    <div>
      {/* <div
        className="menuOptions"
        data-aos="fade-down"
        onMouseLeave={closeDropdowns}
      >
        <div className="menuImageContainer">
          <Link to="/">
            <div>
              <TiHome className="rIcon" onMouseEnter={toggleHomeDropdown} />
              <p>&nbsp;Home</p>
            </div>
          </Link>
          {homeDropdownOpen && (
            <div className="homeDropdown">
              <MDBContainer>
                <div className="menu-drop-down-container">
                  <section>
                    <div className="menu-drop-down-sec-2">
                      <div>
                        <img
                          className="menu-dropdown-img"
                          src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-Footer/4.svg"
                          alt="peteye app"
                          title=" "
                        />
                      </div>
                      <div>
                        <img
                          className="menu-dropdown-img"
                          src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-Footer/3.svg"
                          alt="peteye gps"
                          title=" "
                        />
                      </div>
                      <div>
                        <img
                          className="menu-dropdown-img"
                          src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-Footer/5.svg"
                          alt="peteye tag"
                          title=" "
                        />
                      </div>
                      <div>
                        <img
                          className="menu-dropdown-img"
                          src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-Footer/2.svg"
                          alt="peteye store"
                          title=" "
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </MDBContainer>
            </div>
          )}
        </div>

        <div className="menuImageContainer">
          <Link to="/Service/#service1">
            <div>
              <RiHeart2Fill
                className="rIcon"
                onMouseEnter={toggleHeartDropdown}
              />
              <p>Services</p>
            </div>
          </Link>

          {heartDropdownOpen && (
            <div className="homeDropdown">
              <MDBContainer>
                <div className="menu-drop-down-container">
                  <section>
                    <div className="menu-drop-down-sec-1">
                      <Link to="/service/dms/#services" className="submenu">
                        <span>DMS</span>{" "}
                        <RiArrowRightSLine className="riIcon" />
                      </Link>
                      <Link to="/service/hms/#services" className="submenu">
                        <span>HMS</span>{" "}
                        <RiArrowRightSLine className="riIcon" />
                      </Link>
                      <Link to="/service/dss/#services" className="submenu">
                        <span>DSS</span>{" "}
                        <RiArrowRightSLine className="riIcon" />
                      </Link>
                    </div>
                    <div className="menu-drop-down-sec-1">
                      <Link to="/service/#tag-echo" className="submenu">
                        <span>Tag Echo</span>{" "}
                        <RiArrowRightSLine className="riIcon" />
                      </Link>
                    </div>
                  </section>
                  <div className="menu-divider"></div>
                  <section>
                    <div className="menu-drop-down-sec-2">
                      <div>
                        <img
                          className="menu-dropdown-img"
                          src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-Footer/5.svg"
                          alt="peteye tag"
                          title=" "
                        />
                      </div>
                      <div>
                        <img
                          className="menu-dropdown-img"
                          src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-Footer/3.svg"
                          alt="peteye gps"
                          title=" "
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </MDBContainer>
            </div>
          )}
        </div>
        <div className="menuImageContainer">
          <Link to="/About/#home">
            <div>
              <HiBuildingOffice2
                className="rIcon"
                onMouseEnter={toggleAboutDropdown}
              />
              <p>About</p>
            </div>
          </Link>

          {aboutDropdownOpen && (
            <div className="homeDropdown">
              <MDBContainer>
                <div className="menu-drop-down-container">
                  <section>
                    <div className="menu-drop-down-sec-1">
                      <Link to="/about/" className="submenu">
                        <span>Home</span>{" "}
                        <RiArrowRightSLine className="riIcon" />
                      </Link>
                      <Link to="/about/#mission" className="submenu">
                        <span>Mission</span>{" "}
                        <RiArrowRightSLine className="riIcon" />
                      </Link>
                      <a href="/about/#vision" className="submenu">
                        <span>Vision</span>{" "}
                        <RiArrowRightSLine className="riIcon" />
                      </a>
                    </div>
                    <div className="menu-drop-down-sec-1">
                      <Link to="/service/#etch-eco-system" className="submenu">
                        <span>Our Activity</span>{" "}
                        <RiArrowRightSLine className="riIcon" />
                      </Link>
                      <Link to="/about/#fetch-eco-system" className="submenu">
                        <span>Fetch Eco-system</span>{" "}
                        <RiArrowRightSLine className="riIcon" />
                      </Link>
                    </div>
                  </section>
                  <div className="menu-divider"></div>
                  <section>
                    <div className="menu-drop-down-sec-2">
                      <div>
                        <img
                          className="menu-dropdown-img"
                          src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-Footer/4.svg"
                          alt="peteye app"
                          title=" "
                        />
                      </div>
                      <div>
                        <img
                          className="menu-dropdown-img"
                          src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-Footer/2.svg"
                          alt="peteye store"
                          title=" "
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </MDBContainer>
            </div>
          )}
        </div>
        <div className="menuImageContainer">
          <Link to="/Guide/">
            <div>
              <HiBookOpen
                className="rIcon"
                onMouseEnter={toggleGuideDropdown}
              />
              <p>Guide</p>
            </div>
          </Link>

          {guideDropdownOpen && (
            <div className="homeDropdown">
              <MDBContainer>
                <div className="menu-drop-down-container">
                  <section>
                    <div className="menu-drop-down-sec-1">
                      <Link to="/guide/all" className="submenu">
                        <span>All</span>{" "}
                        <RiArrowRightSLine className="riIcon" />
                      </Link>
                      <Link to="/guide/pet_care" className="submenu">
                        <span>Pet Care</span>{" "}
                        <RiArrowRightSLine className="riIcon" />
                      </Link>
                      <Link
                        to="/guide/training_and_behaviour"
                        className="submenu"
                      >
                        <span>Training & Behaviour</span>{" "}
                        <RiArrowRightSLine className="riIcon" />
                      </Link>
                    </div>
                    <div className="menu-drop-down-sec-1">
                      <Link to="/guide/health_and_wellness" className="submenu">
                        <span>Health & Wellness</span>{" "}
                        <RiArrowRightSLine className="riIcon" />
                      </Link>
                    </div>
                  </section>
                  <div className="menu-divider"></div>
                  <section>
                    <div className="menu-drop-down-sec-2">
                      <div>
                        <img
                          className="menu-dropdown-img"
                          src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-Footer/6.svg"
                          alt="peteye services"
                          title=" "
                        />
                      </div>
                      <div>
                        <img
                          className="menu-dropdown-img"
                          src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-Footer/1.svg"
                          alt="peteye services"
                          title=" "
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </MDBContainer>
            </div>
          )}
        </div>
        <div className="menuImageContainer">
          <Link to="#">
            <div>
              <AiFillShopping
                className="rIcon"
                onMouseEnter={toggleShopDropdown}
              />
              <p>Shop</p>
            </div>
          </Link>

          {shopDropdownOpen && (
            <div className="homeDropdown">
              <MDBContainer>
                <div className="menu-drop-down-container">
                  <section>
                    <div className="menu-drop-down-sec-1">
                      <Link to="/support" className="submenu">
                        <span>Manage Accounts</span>{" "}
                        <RiArrowRightSLine className="riIcon" />
                      </Link>
                      <Link to="/support" className="submenu">
                        <span>Payment History</span>{" "}
                        <RiArrowRightSLine className="riIcon" />
                      </Link>
                      <Link to="/support" className="submenu">
                        <span>Delivery Status</span>{" "}
                        <RiArrowRightSLine className="riIcon" />
                      </Link>
                    </div>
                    <div className="menu-drop-down-sec-1">
                      <Link to="/support" className="submenu">
                        <span>Contact Us</span>{" "}
                        <RiArrowRightSLine className="riIcon" />
                      </Link>
                    </div>
                  </section>
                  <div className="menu-divider"></div>
                  <section>
                    <div className="menu-drop-down-sec-2">
                      <div>
                        <img
                          className="menu-dropdown-img"
                          src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-Footer/6.svg"
                          alt="peteye services"
                          title=" "
                        />
                      </div>
                      <div>
                        <img
                          className="menu-dropdown-img"
                          src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-Footer/1.svg"
                          alt="peteye services"
                          title=""
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </MDBContainer>
            </div>
          )}
        </div>

        <span className="vertical-line"></span>

        <div className="menuImageContainer">
          <Link to="/career/">
            <div>
              <FaBriefcase
                size={36}
                className="rIcon"
                onMouseEnter={toggleCareerDropdown}
              />
              <p>Careers</p>
            </div>
          </Link>

           {careerDropdownOpen && (
            <div className="homeDropdown">
              <MDBContainer>
                <div className="menu-drop-down-container">
                  <section>
                    <div className="menu-drop-down-sec-1">
                      <Link to="/career" className='submenu'><span>Manage Accounts</span>  <RiArrowRightSLine className='riIcon' /></Link>
                      <Link to="/career" className='submenu'><span>Our Team</span>  <RiArrowRightSLine className='riIcon' /></Link>
                      <Link to="/career" className='submenu'><span>Vacancy</span>  <RiArrowRightSLine className='riIcon' /></Link>
                    </div>
                    <div className="menu-drop-down-sec-1">
                      <Link to="/career" className='submenu'><span>Contact Us</span>  <RiArrowRightSLine className='riIcon' /></Link>
                    </div>
                  </section>
                  <div className="menu-divider"></div>
                  <section>
                    <div className="menu-drop-down-sec-2">
                    <div>
                        <img className="menu-dropdown-img" src='https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-Footer/6.svg' alt="peteye services" title=' ' />
                      </div>
                      <div>
                        <img className="menu-dropdown-img" src='https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-Footer/1.svg' alt="peteye services" title='' />
                      </div>
                    </div>

                  </section>
                </div>
              </MDBContainer>
            </div>
          )} 
        </div>
        <div className="menuImageContainer">
          <Link to="/search/">
            <div>
              <RiSettings5Fill
                className="rIcon"
                onMouseEnter={toggleSupportDropdown}
              />
              <p>Search</p>
            </div>
          </Link>

           {supportDropdownOpen && (
            <div className="homeDropdown">
              <MDBContainer>
                <div className="menu-drop-down-container">
                  <section>
                    <div className="menu-drop-down-sec-1">
                      <Link to="/support" className='submenu'><span>Manage Accounts</span>  <RiArrowRightSLine className='riIcon' /></Link>
                      <Link to="/support" className='submenu'><span>Payment History</span>  <RiArrowRightSLine className='riIcon' /></Link>
                      <Link to="/support" className='submenu'><span>Delivery Status</span>  <RiArrowRightSLine className='riIcon' /></Link>
                    </div>
                    <div className="menu-drop-down-sec-1">
                      <Link to="/support" className='submenu'><span>Contact Us</span>  <RiArrowRightSLine className='riIcon' /></Link>
                      <Link to="/support" className='submenu'><span>FAQ's</span>  <RiArrowRightSLine className='riIcon' /></Link>

                    </div>
                  </section>
                  <div className="menu-divider"></div>
                  <section>
                    <div className="menu-drop-down-sec-2">
                    <div>
                        <img className="menu-dropdown-img" src='https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-Footer/6.svg' alt="peteye services" title=' ' />
                      </div>
                      <div>
                        <img className="menu-dropdown-img" src='https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-Footer/1.svg' alt="peteye services" title='' />
                      </div>
                    </div>

                  </section>
                </div>
              </MDBContainer>
            </div>
          )} 
        </div>
      </div> */}
      {/* for mobile */}
      <div className="mobile-menu d-lg-none" onMouseLeave={closeDropdowns}>
        {isMainMenuVisible && (
          <>
            <Link to="/">Home</Link>
            <Link to="/home">My Pet</Link>
            <Link to="/nfc">PetLink</Link>

            {/* <MDBAccordion flush className="mobile-menu-accordion">
              <MDBAccordionItem
                className="about-mobile-menu"
                collapseId={1}
                headerTitle={<Link to="/about#home">About Us</Link>}
              >
                <div className="accordion-items">
                  <Link to="/about/#mission">Mission</Link>
                  <Link to="/about/#vision">Vision</Link>
                  <Link to="/about/#our-activity">Our Activity</Link>
                  <Link to="/about/#fetch-eco-system">Fetch Eco-system</Link>
                </div>
              </MDBAccordionItem>
              <MDBAccordionItem
                className="about-mobile-menu"
                collapseId={2}
                headerTitle={<Link to="/service/#service1">Service</Link>}
              >
                <div className="accordion-items">
                  <Link to="/service/dms/#services">DMS</Link>
                  <Link to="/service/hms/#services">HMS</Link>
                  <Link to="/service/dss/#services">DSS</Link>
                  <Link to="/service/#tag-echo">Tag Echo</Link>
                </div>
              </MDBAccordionItem>
            </MDBAccordion> */}
            {/* <Link to="/guide/">Guide</Link> */}
            <Link to="/store">Shop</Link>
            {/* <Link to="/career/">Careers</Link> */}
            <Link to="/article">Pet’o Pedia</Link>

            <div className="download-icons">
              <a href="https://apps.apple.com/in/app/peteye/id6450916668">
                <img src={apple} alt="peteye appstore app" title="" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.peteye.app&pli=1 ">
                <img src={Android} alt="peteye playstore app" title="" />
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Menu;
