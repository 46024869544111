import React, { useCallback, useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import "./PetOwnerDetails.css";
import axios from "../../../../../axios";
import Axios from "axios";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import SimpleBackdrop from "../../../../../Components/Backdrop/Backdrop";
import { useDispatch, useSelector } from "react-redux";
import DogPettingIcon from "../../../../Homepage/DogPettingIcon";
import Terms from "../Term&Con/Terms";
import debounce from "lodash/debounce";
const validateField = (name, value, formData) => {
  let error = "";

  switch (name) {
    case "firstName":
      if (!value.trim()) error = "First name is required";
      else if (!/^[a-zA-Z\s]*$/.test(value)) error = "Only alphabets allowed";
      break;

    case "lastName":
      if (!value.trim()) error = "Nick name is required";
      else if (!/^[a-zA-Z\s]*$/.test(value)) error = "Only alphabets allowed";
      else if (value.length > 15) error = "Maximum 15 characters allowed";
      break;

    case "email":
      if (!value.trim()) error = "Email is required";
      else if (!/\S+@\S+\.\S+/.test(value)) error = "Invalid email format";
      break;

    case "Mobilenumber":
      if (!value.trim()) error = "Mobile number is required";
      else if (!/^\d{10}$/.test(value)) error = "Must be exactly 10 digits";
      break;

    case "SecondaryMobilenumber":
      // Only validate if value is present (optional field)
      if (value) {
        if (value === formData.Mobilenumber)
          error = "Cannot be same as primary number";
        else if (!/^\d{10}$/.test(value)) error = "Must be exactly 10 digits";
      }
      break;

    case "Pincode":
      if (!value.trim()) error = "Pincode is required";
      else if (!/^\d{6}$/.test(value)) error = "Must be exactly 6 digits";
      break;

    case "State":
      if (!value.trim()) error = "State is required";
      break;

    case "District":
      if (!value.trim()) error = "District is required";
      break;

    case "City":
      if (!value.trim()) error = "City is required";
      break;

    case "Area":
      if (!value.trim()) error = "Area is required";
      break;

    case "streetname":
      if (!value.trim()) error = "Street name is required";
      break;

    default:
      break;
  }

  return error;
};

// 2. Add this hook right after useDebouncedValidation
const useFormState = (initialState, validateField) => {
  const [formData, setFormData] = useState(initialState);
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});

  const debouncedFetchPostalCode = useCallback(
    debounce((code) => {
      Axios.get(`https://api.postalpincode.in/pincode/${code}`)
        .then((response) => {
          console.log(response.data[0]?.PostOffice[0]);
          const postalData = response?.data[0]?.PostOffice[0];
          setFormData((prevData) => ({
            ...prevData,
            City: postalData?.District || "",
            State: postalData?.State || "",
            District: postalData?.District || "",
            Area: postalData?.Name || "",
            Pincode: code,
          }));
          // setopenBackdrop(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 200), // 500ms debounce
    []
  );

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;

      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (touched[name]) {
        const error = validateField(name, value, formData);
        setErrors((prev) => ({
          ...prev,
          [name]: error,
        }));
      }

      // Trigger fetchPostalCode when Pincode is updated
      if (name === "Pincode" && value.length === 6) {
        // setopenBackdrop(true); // Optional: Show a loading indicator
        debouncedFetchPostalCode(value);
      }
    },
    [formData, touched, debouncedFetchPostalCode]
  );

  const handleBlur = useCallback(
    (e) => {
      const { name } = e.target;

      setTouched((prev) => ({
        ...prev,
        [name]: true, // Ensure the field is marked as touched
      }));

      const error = validateField(name, formData[name], formData);
      setErrors((prev) => ({
        ...prev,
        [name]: error,
      }));
    },
    [formData]
  );

  return {
    formData,
    setFormData,
    touched,
    setTouched,
    errors,
    setErrors,
    handleChange,
    handleBlur,
  };
};

const FormInput = React.memo(
  ({
    name,
    placeholder,
    type = "text",
    value,
    readOnly = false,
    onChange,
    onBlur,
    errors = {},
    touched = {},
  }) => {
    console.log(errors);

    return (
      <div className="">
        <input
          className={`textfield-size-reg w-full p-2 ${
            touched[name] && errors[name] ? "border border-red-500" : ""
          }`}
          type={type}
          placeholder={placeholder}
          name={name}
          value={value || ""}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={readOnly}
          autoComplete="off"
        />
        {touched[name] && errors[name] && (
          <p className="text-red-500 text-xs mt-1">{errors[name]}</p>
        )}
      </div>
    );
  }
);
function PetOwnerDetails({ setopenPetInfo, setinfoCompleted }) {
  const { socialData, login } = useSelector((state) => state);
  const googleData = socialData?.googleData || "";
  const sigupData = login?.details || "";
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [nationality, setNationality] = useState({ Name: "" });
  const [openBackdrop, setopenBackdrop] = useState(false);
  const [openAlert, setopenAlert] = useState(false);
  const [open, setOpen] = useState(true);
  const [errMessage, setErrMessage] = useState("");
  const [ownerComplete, setownerComplete] = useState(false);
  const [openOwner, setopenOwner] = useState(true);
  const [openTerms, setopenTerms] = useState(false);
  const [termsComplete, settermsComplete] = useState(false);

  console.log("signupData", sigupData);

  const {
    formData,
    setFormData,
    touched,
    setTouched,
    errors,
    setErrors,
    handleChange,
    handleBlur,
  } = useFormState(
    {
      firstName:
        googleData?.given_name ||
        sigupData?.userInfo?.firstName ||
        googleData?.short_name ||
        "",
      Mobilenumber: sigupData?.userInfo?.mobile || "",
      lastName: googleData?.family_name || googleData?.last_name || "",
      SecondaryMobilenumber: "",
      email: sigupData?.userInfo?.email || googleData?.email || "",
      State: "",
      City: "",
      streetname: "",
      Pincode: "",
      District: "",
      Area: "",
    },
    validateField
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    // Mark all fields as touched
    const allTouched = Object.keys(formData).reduce(
      (acc, key) => ({
        ...acc,
        [key]: true,
      }),
      {}
    );
    setTouched(allTouched);

    // Validate all fields
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key], formData);
      if (error) {
        newErrors[key] = error;
      }
    });

    if (!nationality?.Name) {
      newErrors.nationality = "Country is required";
    }

    setErrors(newErrors);

    // Proceed if no errors
    if (Object.keys(newErrors).length === 0) {
      const userInfo = { ...formData, Nationality: nationality?.Name };
      dispatch({ type: "userInfo", payload: { userInfo } });
      setownerComplete(true);
      setopenOwner(false);
      setopenTerms(true);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = () => {
    axios
      .get("/user/country")
      .then((response) => {
        const countriesData = response.data.message;
        const indiaIndex = countriesData.findIndex(
          (country) => country.Name === "India"
        );
        if (indiaIndex !== -1) {
          const updatedCountries = [
            countriesData[indiaIndex],
            ...countriesData.slice(0, indiaIndex),
            ...countriesData.slice(indiaIndex + 1),
          ];
          setCountries(updatedCountries);
        } else {
          setCountries(countriesData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(errors, "errors"); // Check the error state
  return (
    <div className="flex  flex-col justify-center  relative overflow-hidden ">
      {!openTerms && (
        <>
          <div className=" h-[12vh] mb-[1vh] shadow-[0px_4.171px_12.618px_0px_rgba(0,0,0,0.25)] flex items-center justify-center w-full ">
            <div className="flex items-center justify-start gap-[1.5vw]  w-[80%] ">
              <div
                className={`${
                  ownerComplete
                    ? "bg-[#F5F5F5] px-[.8vw] py-[1vw] rounded-[20%]"
                    : openOwner
                    ? "bg-[#F5F5F5] px-[.8vw] py-[1vw] rounded-[20%] "
                    : "bg-[#F5F5F5] px-[.8vw] py-[1vw] rounded-[20%]"
                }`}
              >
                <DogPettingIcon name={"petownerdetailsicon"} />
              </div>
              <p className="text-[clamp(1rem,1.2vw,6rem)] text-[Prata] font-[500]">
                Pet Owner Details
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="reg-body petowner">
              <FormInput
                name="firstName"
                placeholder="First name"
                value={formData.firstName}
                onChange={handleChange} // Use handleChange instead of handleInputChange
                onBlur={handleBlur}
                errors={errors}
                touched={touched}
                readOnly
              />

              <FormInput
                name="lastName"
                placeholder="Nick Name"
                value={formData.lastName}
                onChange={handleChange} // Use handleChange instead of handleInputChange
                onBlur={handleBlur}
                errors={errors}
                touched={touched}
              />

              <FormInput
                name="email"
                type="email"
                placeholder="Email address"
                value={formData.email}
                onChange={handleChange} // Use handleChange instead of handleInputChange
                onBlur={handleBlur}
                errors={errors}
                touched={touched}
              />

              <FormInput
                name="Mobilenumber"
                placeholder="Mobile number"
                value={formData.Mobilenumber}
                readOnly={sigupData?.userInfo?.mobile ? true : false}
                onChange={handleChange} // Use handleChange instead of handleInputChange
                onBlur={handleBlur}
                errors={errors}
                touched={touched}
              />

              <FormInput
                name="SecondaryMobilenumber"
                placeholder="Secondary Mobile number (Optional)"
                value={formData.SecondaryMobilenumber}
                onChange={handleChange} // Use handleChange instead of handleInputChange
                onBlur={handleBlur}
                errors={errors}
                touched={touched}
              />

              {/* Autocomplete with error handling */}
              <div className="input-container">
                <Autocomplete
                  id="country-select-demo"
                  sx={{ width: "25vw" }}
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) => option.Name}
                  renderOption={(props, option) => (
                    <Box
                      sx={{ fontSize: "clamp(0.8rem, 0.9vw, 2.2rem)" }}
                      component="li"
                      {...props}
                    >
                      {option.Name}
                    </Box>
                  )}
                  value={nationality}
                  onChange={(e, newValue) => {
                    setNationality(newValue);
                    setErrors((prev) => ({ ...prev, nationality: "" }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="nationality"
                      placeholder="Choose a country"
                      error={!!errors.nationality}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          fontSize: "14px",
                          border: "none",
                          outline: "none",
                          padding: "0px",
                          margin: "0px",
                          display: "flex",
                          alignItems: "center",
                        },
                        disableUnderline: true,
                      }}
                      sx={{
                        ".MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: errors.nationality
                              ? "1px solid #ef4444"
                              : "none",
                          },
                        },
                      }}
                    />
                  )}
                />
                {errors.nationality && (
                  <p className="text-red-500 text-xs mt-1 ml-2">
                    {errors.nationality}
                  </p>
                )}
              </div>

              <FormInput
                name="Pincode"
                placeholder="Pincode"
                value={formData.Pincode}
                onChange={handleChange} // Use handleChange instead of handleInputChange
                onBlur={handleBlur}
                errors={errors}
                touched={touched}
              />

              <FormInput
                name="State"
                placeholder="State"
                value={formData.State}
                onChange={handleChange} // Use handleChange instead of handleInputChange
                onBlur={handleBlur}
                errors={errors}
                touched={touched}
              />

              <FormInput
                name="District"
                placeholder="District"
                value={formData.District}
                onChange={handleChange} // Use handleChange instead of handleInputChange
                onBlur={handleBlur}
                errors={errors}
                touched={touched}
              />

              <FormInput
                name="City"
                placeholder="City"
                value={formData.City}
                onChange={handleChange} // Use handleChange instead of handleInputChange
                onBlur={handleBlur}
                errors={errors}
                touched={touched}
              />

              <FormInput
                name="Area"
                placeholder="Area"
                value={formData.Area}
                onChange={handleChange} // Use handleChange instead of handleInputChange
                onBlur={handleBlur}
                errors={errors}
                touched={touched}
              />

              <FormInput
                name="streetname"
                placeholder="Address"
                value={formData.streetname}
                onChange={handleChange} // Use handleChange instead of handleInputChange
                onBlur={handleBlur}
                errors={errors}
                touched={touched}
              />
            </div>
          </form>
          <div className="absolute top-[60vh] h-[12vh] bg-[linear-gradient(181deg,_rgba(255,255,255,0)_-1.77%,_rgba(255,255,255,0.6)_49.92%)] backdrop-blur-[3.8793px] flex items-center justify-center w-full ">
            <button
              onClick={handleSubmit}
              className="bg-black text-white px-[1.8vw] py-[.7vw] rounded-[1.5vw] flex items-center justify-center gap-[.5vw] font-[500] text-[clamp(1rem,1.2vw,6rem)]"
            >
              <DogPettingIcon name="petowner-nexticon" />
              Next
            </button>
          </div>
        </>
      )}
      {openBackdrop && (
        <SimpleBackdrop open={openBackdrop} setopenBackdrop={setopenBackdrop} />
      )}
      {openTerms && <Terms settermsComplete={settermsComplete} />}
    </div>
  );
}

export default PetOwnerDetails;
