import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import DogPettingIcon from "../../../../Homepage/DogPettingIcon";
import { IoDocumentText } from "react-icons/io5";
import { LuUpload } from "react-icons/lu";
import { AiFillFilePdf } from "react-icons/ai";
import "./MedicalRecords.css";
import Failedgif from "../../../../Failed/Failedgif";
import axios from "../../../../../axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { RxCrossCircled } from "react-icons/rx";
import { IoIosCheckmarkCircle } from "react-icons/io";
import Delete from "../../../../../Components/Delete/Delete";
import { RiDeleteBin5Line } from "react-icons/ri";
import Success from "../../../Success/Success";
import { RabiesVaccination } from "../../../Petprofile/Settings/RabiesVaccination";

export default function MedicalRecords({ isOpen, onClose, petId }) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [addingNew, setAddingNew] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const [petDocuments, setPetDocuments] = useState([]);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isRabiesVaccinationOpen, setIsRabiesVaccinationOpen] = useState(false);
  const [isRabiesVaccinated, setIsRabiesVaccinated] = useState(null);
  const [vaccinationData, setVaccinationData] = useState([]);
  const [vaccinationRecordFile, setVaccinationRecordFile] = useState([]);

  const [isEditing, setIsEditing] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const loginToken = Cookies.get("loginToken");
  const { login } = useSelector((state) => state);
  const petInfo = login.details.petInfo;
  const petType =
    petId?.petType?.trim().toLowerCase() === "dog"
      ? "Dog or Canids"
      : petId?.petType?.trim().toLowerCase() === "cat"
      ? "Cat or Feline"
      : petId?.petType || "Unknown";

  console.log(petType, "petInfo");
  console.log(vaccinationRecordFile?.length, "vaccinationRecordFile");

  const dispatch = useDispatch();

  const docTypeMapping = {
    BloodandFecalTestFile: 4,
    currentMedicationFile: 7,
    DietandExerciseFile: 5,
    PastSurgeriesFile: 3,
    PrescriptionFile: 1,
    VeterinaryVisitHistoryFile: 2,
    VaccinationRecordsFile: 6,
  };

  useEffect(() => {
    const fetchPetDocuments = async () => {
      try {
        const response = await axios.get(
          `/petInfo/getpetdocuments/${petId.id}`,
          {
            headers: {
              Authorization: loginToken,
            },
          }
        );
        const documents = response.data.petDocuments;
        console.log("Pet Documents12:", documents);
        const updatedFiles = {};

        documents.forEach((doc) => {
          const docType = doc.docType || "unknown";
          if (!updatedFiles[doc.docType]) {
            updatedFiles[doc.docType] = [];
          }
          updatedFiles[doc.docType].push({
            id: doc.id,
            name: doc.fileName,
            url: doc.url,
          });
        });
        setUploadedFiles(updatedFiles);
        setPetDocuments(response.data.petDocuments);

        console.log("Pet Documents:", response.data.petDocuments);

        console.log("Uploaded Files:", updatedFiles);
        const firstDocType = Object.keys(updatedFiles)[0];
        setSelectedItem(docTypeMapping[firstDocType]);
      } catch (error) {
        console.error("Error fetching pet documents:", error);
      }
    };

    if (isOpen && petId) {
      fetchPetDocuments();
      hanldeVaccinationRecord();
    }
  }, [petId, loginToken, isOpen]);

  const hanldeVaccinationRecord = async () => {
    try {
      const response = await axios.get(`/petinfo/getvaccination/${petId.id}`, {
        headers: {
          Authorization: loginToken,
        },
      });

      const records = Array.isArray(response.data.message)
        ? response.data.message
        : [];
      console.log("Vaccination record fetched successfully:", records);
      setVaccinationRecordFile(records);
    } catch (error) {
      console.error("Error adding vaccination record:", error);
    }
  };

  const handleVaccinationFileSelection = (record) => {
    setSelectedFiles((prevSelected) => {
      const isSelected = prevSelected.some((f) => f.id === record.id);
      if (isSelected) {
        return prevSelected.filter((f) => f.id !== record.id);
      } else {
        return [...prevSelected, record];
      }
    });
  };

  useEffect(() => {
    if (uploadStatus) {
      const timer = setTimeout(() => {
        setUploadStatus(null);
        setAddingNew(false);
      }, 2000); // Reset the status after 2 seconds

      return () => clearTimeout(timer);
    }
  }, [uploadStatus]);

  if (!isOpen) return null;

  const handleVaccinationDataChange = (data) => {
    setVaccinationData(data);
    setIsRabiesVaccinationOpen(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
      setShowFormModal(false);
    }
  };
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 1000); // Match the duration of the closing animation
  };

  const handleItemClick = (itemId) => {
    setSelectedItem(itemId);
    setAddingNew(false);
  };

  const handleAddNewClick = () => {
    console.log("Selected Item:", selectedItem);
    if (selectedItem === 6) {
      console.log("Opening form modal for Vaccination Records");
      handleRabiesVaccinationChange(true);
      setIsRabiesVaccinated(true);
      setShowFormModal(true);
    } else {
      setAddingNew(true);
      setUploadedFile(null);
      setUploadStatus(null);
    }
  };

  const handleRabiesVaccinationChange = (status) => {
    setIsRabiesVaccinated(status);

    setIsRabiesVaccinationOpen(true);
  };
  const handleDeleteFiles = () => {
    if (isEditing && selectedFiles.length > 0) {
      setShowDeleteModal(true);
    } else {
      handleAddNewClick();
    }
  };

  const handleBookAppointment = () => {
    handleRabiesVaccinationChange(false);
    setShowAppointmentModal(true);
    setIsRabiesVaccinated(false);
  };

  const handleBackToDetails = () => {
    setAddingNew(false);
  };
  const handleButtonClick = () => {
    if (isEditing && selectedFiles.length > 0) {
      handleDeleteFiles();
    } else {
      handleAddNewClick();
    }
  };
  const handleVaccinationDelete = async () => {
    try {
      const selectedIds = selectedFiles.map((file) => file.id);
      console.log("selectedIds:", selectedIds[0]);
      const response = await axios.delete(
        `/petinfo/deletevaccination/${selectedIds[0]}`,
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );

      // Update the local state to remove deleted records
      setVaccinationRecordFile((prevRecords) =>
        prevRecords.filter((record) => !selectedIds.includes(record.id))
      );

      setSelectedFiles([]);
      setIsEditing(false);
      setShowDeleteModal(false);

      console.log(
        "Vaccination records deleted successfully",
        selectedIds,
        response.data.message
      );
    } catch (error) {
      console.error("Error deleting vaccination records:", error);
    }
  };
  const handleConfirmDelete = async () => {
    if (selectedItem === 6) {
      await handleVaccinationDelete();
    } else {
      try {
        const selectedIds = selectedFiles.map((file) => file.id);
        await axios.post(
          "/petinfo/deletePetDocument",
          { selectedIds },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        );
        setUploadedFiles((prevFiles) => {
          const newFiles = { ...prevFiles };
          Object.keys(newFiles).forEach((docType) => {
            newFiles[docType] = newFiles[docType].filter(
              (f) => !selectedFiles.includes(f)
            );
          });
          return newFiles;
        });

        setSelectedFiles([]);
        setIsEditing(false);
        setShowDeleteModal(false);

        console.log("Files deleted successfully");
        // Optionally, show a success message
      } catch (error) {
        console.error("Error deleting files:", error);
        // Show an error message to the user
      }
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 3 * 1024 * 1024) {
      setUploadedFile(file);
      setUploadStatus(null);
    } else {
      alert("File size exceeds 3MB");
    }
  };
  const hasFiles = (itemId) => {
    if (itemId === 6) {
      return vaccinationRecordFile && vaccinationRecordFile.length > 0;
    }
    const item = settingsData.find((item) => item.id === itemId);
    return (
      item &&
      uploadedFiles[item.docType] &&
      uploadedFiles[item.docType].length > 0
    );
  };

  const handleEditClick = () => {
    if (hasFiles(selectedItem)) {
      setIsEditing((prevState) => !prevState); // Toggle the isEditing state
      setSelectedFiles([]); // Clear selected files when toggling edit mode
    }
  };

  const handleFileSelection = (file) => {
    setSelectedFiles((prevSelected) => {
      const isSelected = prevSelected.some((f) => f.id === file.id);
      if (isSelected) {
        return prevSelected.filter((f) => f.id !== file.id);
      } else {
        return [...prevSelected, file];
      }
    });
  };

  const uploadFileToS3 = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post("/file/uploadFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: Cookies.get("loginToken"),
        },
      });
      const url = response.data.Message ? response.data.Message.getPeteye : "";
      console.log("File URL:", url);
      return url;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      return null;
    }
  };

  const handleUpload = async () => {
    if (uploadedFile && selectedItem) {
      const url = await uploadFileToS3(uploadedFile); // Upload the file to S3
      const docType = Object.keys(docTypeMapping).find(
        (key) => docTypeMapping[key] === selectedItem
      );

      if (url && docType) {
        const documentList = [
          {
            petId: petId.id,
            docType: docType,
            fileType: "pdf",
            url: url,
            fileName: uploadedFile.name,
          },
        ];

        try {
          await axios.post(
            "/petinfo/addpetmedicalhistory",
            { documentList },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          );

          setUploadStatus("success");
          setUploadedFile(null);
          setTimeout(() => {
            dispatch({ type: "refresh" });
          }, 2000);

          console.log("Document list uploaded successfully");
        } catch (error) {
          console.error("Error uploading document list:", error);
          setUploadStatus("failed");
        }
      } else {
        setUploadStatus("failed");
      }
    } else {
      setUploadStatus("failed");
    }
  };

  const handleFileClick = (url) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      console.error("No valid URL provided");
    }
  };

  const handleFileEdit = () => {
    setUploadedFile(null);
    document.getElementById("file-input").click();
  };

  const settingsData = [
    {
      id: 1,
      img: <DogPettingIcon name={"Prescriptions"} />,
      data: "Prescriptions",
      docType: "PrescriptionFile",
    },
    {
      id: 2,
      img: <DogPettingIcon name={"vet-history"} />,
      data: "Veterinary Visit History",
      docType: "VeterinaryVisitHistoryFile",
    },
    {
      id: 3,
      img: <DogPettingIcon name={"past-surgeries"} />,
      data: "Past Surgeries",
      docType: "PastSurgeriesFile",
    },
    {
      id: 4,
      img: <DogPettingIcon name={"blood-test"} />,
      data: "Blood Test & Fecal Test",
      docType: "BloodandFecalTestFile",
    },
    {
      id: 5,
      img: <DogPettingIcon name={"diet-icon"} />,
      data: "Details on Diet & Exercise",
      docType: "DietandExerciseFile",
    },
    {
      id: 6,
      img: <DogPettingIcon name={"Vaccination Records"} />,
      data: "Vaccination Records",
      docType: "VaccinationRecordsFile",
    },
    // {
    //   id: 7,
    //   img: <DogPettingIcon name={"medication"} />,
    //   data: "Details on Current Medication",
    //   docType: "currentMedicationFile",
    // },
  ];

  return (
    <div
      className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        <div>
          {!selectedItem ? (
            <div className="records-picker">
              <div className="records-picker-title">
                <DogPettingIcon name="Medicalrecords" />
                <h2>Medical Records</h2>
              </div>
              <div className="modal-close" onClick={handleClose}>
                <DogPettingIcon
                  name={"closemodal"}
                  className="modal-close-icon"
                />
              </div>

              <div className="medical-grid-container">
                {settingsData.map((item) => (
                  <div
                    key={item.id}
                    className={`medical-grid-item ${
                      selectedItem === item.id ? "active" : ""
                    }`}
                    onClick={() => handleItemClick(item.id)}
                  >
                    <div className="medical-svgicon">{item.img}</div>
                    <h3>{item.data}</h3>
                  </div>
                ))}
              </div>
            </div>
          ) : addingNew ? (
            uploadStatus ? (
              <div className="add-new-documents">
                <div className="modal-close" onClick={handleBackToDetails}>
                  <DogPettingIcon
                    name={"closemodal"}
                    className="modal-close-icon"
                  />
                </div>

                {uploadStatus === "success" ? (
                  <Success
                    message="Upload successfully!"
                    header="Success"
                    gif="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/success.gif"
                  />
                ) : (
                  <Success
                    message="Oops! No files found."
                    gif="https://peteye-drive.s3.ap-south-1.amazonaws.com/Supportwebp/DrowningDog.webp"
                  />
                )}
              </div>
            ) : (
              <div className="add-new-documents-content">
                <div className="records-Upload-title">
                  <p>
                    <IoDocumentText
                      style={{ fontSize: "clamp(1.3rem, 1.3vw, 4rem)" }}
                    />
                  </p>
                  <h2>Upload Documents</h2>
                </div>
                <div className="modal-close" onClick={handleBackToDetails}>
                  <DogPettingIcon
                    name={"closemodal"}
                    className="modal-close-icon"
                  />
                </div>

                <div className="upload-doc-files">
                  <input
                    type="file"
                    accept="application/pdf"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="file-input"
                  />
                  <label htmlFor="file-input">
                    <p>
                      <DogPettingIcon name="upload-doc" />
                    </p>
                    <div className="upload-doc-files-title">
                      <h5>
                        <LuUpload
                          style={{ fontSize: "clamp(1rem, 1.2vw, 3rem)" }}
                        />{" "}
                        <h6>Upload</h6>
                      </h5>
                      <p>Maximum Size: 3MB</p>
                    </div>
                  </label>
                </div>
                {uploadedFile && (
                  <div className="uploaded-preview-list">
                    <div className="uploaded-file-preview">
                      <div className="uploaded-file-preview-title">
                        <h4>
                          <AiFillFilePdf
                            style={{ fontSize: "clamp(2rem , 1.5vw, 4rem" }}
                          />
                        </h4>
                        <div className="uploaded-file-preview-name">
                          <h5
                            onClick={() => handleFileClick(uploadedFile)}
                            style={{
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                          >
                            {uploadedFile.name}
                          </h5>
                          <p>
                            {(uploadedFile.size / 1024 / 1024).toFixed(2)} MB
                          </p>
                        </div>
                      </div>
                      <div
                        className="uploaded-file-preview-edit"
                        onClick={() => handleFileEdit()}
                        style={{ cursor: "pointer" }}
                      >
                        <DogPettingIcon name="edit" />
                      </div>
                    </div>
                  </div>
                )}
                {uploadedFile && (
                  <div className="upload-file-btn" onClick={handleUpload}>
                    <p>
                      <LuUpload
                        style={{ fontSize: "clamp(1.3rem, 1.3vw, 3rem)" }}
                      />
                    </p>
                    <p>Upload</p>
                  </div>
                )}
              </div>
            )
          ) : (
            <div className="selected-item-details">
              <div
                className="modal-close"
                onClick={() => setSelectedItem(null)}
              >
                <DogPettingIcon
                  name={"closemodal"}
                  className="modal-close-icon"
                />
              </div>
              {(hasFiles(selectedItem) ||
                (selectedItem === 6 && vaccinationRecordFile?.length > 0)) && (
                <div
                  className={`medical-edit ${
                    hasFiles(selectedItem) ||
                    (selectedItem === 6 && vaccinationRecordFile?.length > 0)
                      ? "clickable"
                      : "disabled"
                  }`}
                  onClick={handleEditClick}
                >
                  {isEditing ? (
                    <RxCrossCircled
                      fontSize="clamp(1.2rem, 1.5vw, 5rem)"
                      className="edit-icon"
                    />
                  ) : (
                    <DogPettingIcon name="doc-edit" className="edit-icon" />
                  )}
                </div>
              )}

              <div className="records-picker-detail-title">
                <h5>
                  {settingsData.find((item) => item.id === selectedItem).img}
                </h5>
                <h2>
                  {settingsData.find((item) => item.id === selectedItem).data}
                </h2>
              </div>
              <div style={{ padding: "1vw", height: "55vh" }}>
                {settingsData.map((item) => {
                  if (selectedItem === item.id) {
                    // Special handling for vaccination records (id: 6)
                    if (item.id === 6) {
                      return (
                        <div key={item.id} className="records-uploaded-files">
                          {vaccinationRecordFile &&
                          vaccinationRecordFile.length > 0 ? (
                            vaccinationRecordFile?.map((record, index) => (
                              <div
                                key={index}
                                className={`uploaded-file ${
                                  isEditing ? "selectable" : ""
                                } ${
                                  selectedFiles.some((f) => f.id === record.id)
                                    ? "selected"
                                    : ""
                                }`}
                                onClick={() =>
                                  isEditing &&
                                  handleVaccinationFileSelection(record)
                                }
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                              >
                                <p
                                  onClick={() =>
                                    handleFileClick(
                                      record.petVaccinationdocument.map(
                                        (doc) => doc.url
                                      )
                                    )
                                  }
                                >
                                  <AiFillFilePdf fontSize="clamp(1.2rem, 1.5vw, 5rem)" />
                                </p>
                                <h5
                                  onClick={() =>
                                    handleFileClick(
                                      record.petVaccinationdocument.map(
                                        (doc) => doc.url
                                      )
                                    )
                                  }
                                >
                                  {record.vaccinationName}
                                </h5>
                                {selectedFiles.some(
                                  (f) => f.id === record.id
                                ) && (
                                  <div className="selected-indicator">
                                    <IoIosCheckmarkCircle fontSize="clamp(1.2rem, 1.5vw, 5rem)" />
                                  </div>
                                )}
                              </div>
                            ))
                          ) : (
                            <Failedgif message="No vaccination records found." />
                          )}
                        </div>
                      );
                    }

                    // Original handling for other document types
                    const files = uploadedFiles[item.docType] || [];
                    return (
                      <div key={item.id} className="records-uploaded-files">
                        {files.length > 0 ? (
                          files.map((file, index) => (
                            <div
                              key={index}
                              className={`uploaded-file ${
                                isEditing ? "selectable" : ""
                              } ${
                                selectedFiles.some((f) => f.id === file.id)
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() =>
                                isEditing && handleFileSelection(file)
                              }
                              style={{
                                cursor: "pointer",
                                textDecoration: "none",
                              }}
                            >
                              <p onClick={() => handleFileClick(file.url)}>
                                <AiFillFilePdf fontSize="clamp(1.2rem, 1.5vw, 5rem)" />
                              </p>
                              <h5 onClick={() => handleFileClick(file.url)}>
                                {file.name}
                              </h5>
                              {selectedFiles.some((f) => f.id === file.id) && (
                                <div className="selected-indicator">
                                  <IoIosCheckmarkCircle fontSize="clamp(1.2rem, 1.5vw, 5rem)" />
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <Failedgif message="Oops! No files found." />
                        )}
                      </div>
                    );
                  }
                  return null;
                })}
              </div>

              {selectedItem === 6 && (
                <div className="vaccination-buttons">
                  {isEditing ? (
                    <button
                      className="records-upload-button-vaacination delete"
                      onClick={handleDeleteFiles}
                    >
                      <RiDeleteBin5Line /> Delete Selected Records
                    </button>
                  ) : (
                    // Regular vaccination buttons when not in edit mode
                    <>
                      <button
                        className="records-upload-button-vaacination"
                        onClick={handleAddNewClick}
                      >
                        <DogPettingIcon name="save" /> Add New Documents
                      </button>
                      <button
                        className="records-upload-button-vaacination"
                        onClick={handleBookAppointment}
                      >
                        <DogPettingIcon name="book-vaccine" /> Book Appointment
                      </button>
                    </>
                  )}
                </div>
              )}

              {selectedItem !== 6 && (
                <button
                  className={`records-upload-button ${
                    isEditing && selectedFiles.length > 0 ? "delete" : ""
                  }`}
                  onClick={
                    isEditing && selectedFiles.length > 0
                      ? handleDeleteFiles
                      : handleAddNewClick
                  }
                >
                  {isEditing && selectedFiles.length > 0 ? (
                    <>
                      <RiDeleteBin5Line />
                      Delete
                    </>
                  ) : (
                    <>
                      <DogPettingIcon name="save" />
                      Add New Documents
                    </>
                  )}
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      {isRabiesVaccinationOpen && (
        <RabiesVaccination
          isOpen={isRabiesVaccinationOpen}
          isClose={() => setIsRabiesVaccinationOpen(false)}
          vaccinated={isRabiesVaccinated}
          petType={petType}
          onVaccinationDataChange={handleVaccinationDataChange}
          isFromMedicalRecord={true}
          petId={petId}
        />
      )}

      {showDeleteModal && (
        <Delete
          handleDelete={handleConfirmDelete}
          rejectDelete={handleCancelDelete}
          message="Do you really want to remove this file?"
        />
      )}
    </div>
  );
}
