import React, { useState, useEffect } from "react";
import Modal from "../Modal/Modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "../../../axios";
import OrderDetails from "./OrderDetails";

export default function TrackOrder({ isOpen, isClose }) {
  const { login } = useSelector((state) => state);
  const navigate = useNavigate();
  const loginToken = Cookies.get("loginToken");
  const [order, setOrder] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [Trackdetails, setTrackdetails] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  const fetchOrder = async () => {
    try {
      const response = await axios.get("/shop/getpurchase", {
        headers: { Authorization: loginToken },
      });
      setOrder(response.data.message || []);
    } catch (error) {
      console.error("Error fetching order:", error);
      setOrder([]);
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000);
  };

  const handleSelectedOrder = (order) => {
    setSelectedOrder((prev) => !prev);
    setTrackdetails(order);
  };

  // Helper function to safely get product information
  const getProductInfo = (item) => {
    if (!item?.productinfomodel?.length) {
      return {
        name: "Product Unavailable",
        image: "/placeholder-image.jpg",
        date: item?.CreatedAt
          ? item.CreatedAt.split("T")[0]
          : "Date unavailable",
        count: 0,
      };
    }

    return {
      name: item.productinfomodel[0]?.Product?.ProductName || "Unnamed Product",
      image:
        item.productinfomodel[0]?.Product?.productImagemodel?.[0]?.url ||
        "/placeholder-image.jpg",
      date: item.CreatedAt ? item.CreatedAt.split("T")[0] : "Date unavailable",
      count: item.productinfomodel.length,
    };
  };

  return (
    <div>
      <Modal
        modaltitle={"Track Order"}
        handleClose={handleClose}
        handleOverlayClick={handleOverlayClick}
        isClosing={isClosing}
        iconname={""}
      >
        <div className="h-[60vh] overflow-auto flex flex-col gap-[1.5vh] no-scrollbar">
          {order.map((item) => {
            const productInfo = getProductInfo(item);

            return (
              <div
                key={item.order_id || Math.random()}
                onClick={() => handleSelectedOrder(item)}
                className="flex items-center justify-between p-[1.2vw] bg-[#f5f5f5] w-[30vw] rounded-[1.5vw]"
              >
                <div className="flex items-center flex-row gap-[2vw]">
                  <div className="flex items-center justify-center bg-[#FFF] p-[.5vw] rounded-[1vw] relative">
                    <img
                      src={productInfo.image}
                      alt="track-product-image"
                      className="w-[4vw] h-[7vh] object-contain"
                    />
                    {productInfo.count > 1 && (
                      <p className="absolute -bottom-0 right-0 text-[#000] rounded-[50%] bg-[#FFF] w-[1.2vw] h-[1.2vw] flex items-center justify-center border-[1px] border-[#559F7A] text-[clamp(.5rem,.5vw,2rem)]">
                        {productInfo.count}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col items-start">
                    <h1 className="text-[clamp(1rem,1.2vw,4rem)] font-[600] w-[10vw] font-[Manrope] text-start text-ellipsis overflow-hidden whitespace-nowrap">
                      {productInfo.name}
                    </h1>
                    <p className="text-[clamp(.8rem,1vw,3rem)] text-[rgba(0,0,0,0.5)] font-[Manrope]">
                      {productInfo.date}
                    </p>
                  </div>
                </div>
                <div>
                  <p className="text-[#559F7A] text-[clamp(.8rem,.8vw,2.6rem)] text-start w-[6vw]">
                    {item.purchase_status === "Vaccination Scheduled" &&
                      "Scheduled"}
                    {item.purchase_status === "Para-Vet is on the Way" &&
                      "On the Way"}
                    {item.purchase_status === "Para-Vet has Arrived" &&
                      "Arrived"}
                    {item.purchase_status === "Vaccination Completed" &&
                      "Completed"}
                    {item.purchase_status === "Cancelled" && "Cancelled"}
                    {item.purchase_status === "Returned" && "Returned"}
                    {item.purchase_status === "Delivered" && "Delivered"}
                    {item.purchase_status === "Order Packed" && "Packed"}
                    {item.purchase_status === "Order Received" && "Received"}
                    {item.purchase_status === "In-Transit" && "In-Transit"}
                    {item.purchase_status === "Out-For-Delivery" &&
                      "Out-For-Delivery"}

                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
      {selectedOrder && (
        <OrderDetails
          isClose={handleSelectedOrder}
          isOpen={handleSelectedOrder}
          Eachorder={Trackdetails}
        />
      )}
    </div>
  );
}
