import React, { useEffect, useState } from "react";
import DogPettingIcon from "../../../../Homepage/DogPettingIcon";
import axios from "../../../../../axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../../shop/components/Modal/Modal";
import dayjs from "dayjs";
import { BsGenderAmbiguous } from "react-icons/bs";
import { FaBirthdayCake } from "react-icons/fa";
import RemovePetSubModal from "./RemovePetSubModal";
import { useNavigate } from "react-router-dom";

export const RemovePet = ({ isOpen, isClose, petId, pet }) => {
  const loginToken = Cookies.get("loginToken");
  const [isClosing, setIsClosing] = useState(false);
  const [openRemovePet, setOpenRemovePet] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [selected, setSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openOtp, setOpenOtp] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { login } = useSelector((state) => state);
  useEffect(() => {
    if (!pet || !petId) {
      return null; // Or you could show an error message here
    }
  }, [pet, petId]);
  const handleOpenOtp = () => {
    setOpenOtp((prev) => !prev);
  };
  const reasons = [
    "The profile was created accidentally",
    "No need of profile anymore",
    "Pet registered under another account",
    "Prefer not to say",
    "Other",
  ];

  useEffect(() => {
    if (selected) {
      setError(null);
    }
  }, [selected]);
  const handleRemovePet = async () => {
    if (!selected) {
      setError("Please select a reason for removing your pet");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `/petinfo/deletepet/${petId}`,
        { reason: selected },
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );
      console.log("Pet removed successfully:", response);
      handleClose();
      dispatch({ type: "refresh" });
      navigate("/");
      // Handle success or error
    } catch (error) {
      console.error("Error removing pet:", error);
      if (error.name === "AbortError") {
        setError("Request timed out. Please try again.");
      } else if (error.response?.status === 504) {
        setError(
          "Server is taking too long to respond. Please try again later."
        );
      } else {
        setError(
          error.response?.data?.message ||
            "Failed to remove pet. Please try again."
        );
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000);
  };
  const trimBreed = (breed) => {
    return breed.length > 20 ? breed.slice(0, 20) + " .." : breed;
  };
  const trimName = (name) => {
    return name.length > 15 ? name.slice(0, 15) + " .." : name;
  };
  const getPetAge = (birthDate) => {
    const birth = dayjs(birthDate);
    const now = dayjs();
    const years = now.diff(birth, "year");
    const months = now.diff(birth.add(years, "year"), "month");
    return { years, months };
  };

  console.log(error);

  return (
    <Modal
      modaltitle={"Remove Pet"}
      handleClose={handleClose}
      handleOverlayClick={handleOverlayClick}
      isClosing={isClosing}
      iconname={"remove-pet"}
    >
      <div className="h-[60vh] overflow-auto no-scrollbar flex flex-col items-center py-[2vh]">
        <div className="flex flex-col items-start gap-[1vh] mb-[2vh]">
          <h1 className="text-[#262427] font-[Poppins] font-[700] text-[clamp(1rem,1.2vw,3rem)]">
            Remove Pet
          </h1>
          <p className="text-[#262427] font-[Open Sans] font-[400] text-[clamp(0.8rem,0.8vw,2.5rem)] text-start">
            Deleting your pet is an irreversible process. When you delete your
            pet profile, pet's details, photos and all other data will be
            permanently removed.
          </p>
        </div>
        <div className="flex w-[28vw] items-center justify-between px-[1vw] py-[.75vw] shadow-[0px_0.2vw_0.5vw_0px_rgba(0,0,0,0.15)] rounded-[1.5vw]">
          <div className="flex items-center justify-center flex-row gap-[1vw]">
            <img
              className="w-[4vw] rounded-[50%] h-[4vw] object-cover"
              src={pet?.petProfileImage}
              alt=""
            />
            <div className="flex flex-col items-start justify-start">
              <h1 className="text-[#262427] font-[Poppins] font-[400] text-[clamp(1rem,1.2vw,3rem)]">
                {trimName(pet.petName)}
              </h1>
              <p className="text-[rgba(0,0,0,0.5)] font-[Open Sans] font-[400] text-[clamp(0.8rem,0.8vw,2.5rem)]">
                {trimBreed(pet.petBreed)}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center flex-row gap-[2vw]">
            <div className="flex flex-col items-center gap-[.2vw]">
              <span>
                <FaBirthdayCake className="cake-icon" />
              </span>
              <p className="text-[clamp(0.8rem,0.8vw,2.5rem)]">
                {(() => {
                  const { years, months } = getPetAge(pet.birthDate);
                  return `${years} ${years === 1 ? "yr" : "yrs"} & ${months} ${
                    months === 1 ? "mon" : "mon"
                  }`;
                })()}
              </p>
            </div>
            <div className="flex flex-col items-center gap-[.2vw]">
              <span>
                <BsGenderAmbiguous className="gender-icon" />
              </span>
              <p className="text-[clamp(0.8rem,0.8vw,2.5rem)]">{pet.sex}</p>
            </div>
          </div>
        </div>

        <div className="w-[28vw] mt-[3vh]">
          <div className="relative">
            <button
              onClick={() => setIsOpenDropdown(!isOpenDropdown)}
              className={`w-full h-[7vh] px-[1vw] py-[.5vw] text-left text-gray-600 bg-[#F5F5F5] rounded-[1vw] focus:outline-none transition-all duration-300 hover:bg-gray-100 ${
                error && "border border-red-500"
              }`}
              disabled={isLoading}
            >
              <div className={`flex items-center justify-between `}>
                <span className="text-gray-500">
                  {selected || "Select a reason for deleting your pet"}
                </span>
                <svg
                  className={`w-[1.5vw] h-[3vh] transition-transform duration-300 ${
                    isOpenDropdown ? "rotate-180" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </button>

            {isOpenDropdown && (
              <div
                className={`w-full bg-[#F5F5F5] rounded-[1vw] shadow-lg mt-[0.5vh] max-h-[32vh] overflow-auto no-scrollbar `}
              >
                {reasons.map((reason) => (
                  <label
                    key={reason}
                    className="flex items-center justify-between px-[1vw] py-[.5vw] cursor-pointer hover:bg-gray-50 transition-colors duration-200"
                  >
                    <span className="ml-2 text-gray-700">{reason}</span>
                    <input
                      type="radio"
                      name="deleteReason"
                      value={reason}
                      checked={selected === reason}
                      onChange={() => {
                        setSelected(reason);
                        setIsOpenDropdown(false);
                      }}
                      disabled={isLoading}
                      className="w-[1vw] h-[1vw] text-blue-600 border-gray-300 focus:ring-blue-500"
                    />
                  </label>
                ))}
              </div>
            )}
          </div>
          {error && (
            <p className="text-red-500 text-[clamp(0.8rem,0.8vw,2.5rem)] mt-[.5vh] text-left">
              {error}
            </p>
          )}
        </div>

        <div
          className={`flex items-center justify-center gap-[1.5vw] ${
            isOpenDropdown ? "mt-[3vh]" : "mt-[3vh]"
          } transition-all duration-300`}
        >
          <button
            onClick={handleRemovePet}
            disabled={isLoading}
            className={`flex flex-row items-center text-[clamp(.8rem,1vw,3rem)] font-[600] font-[Poppins] ${
              isLoading ? "bg-gray-300" : "bg-white"
            } text-black px-[1.5vw] py-[.5vw] rounded-[1vw] shadow-[0px_.4vw_1vw_0px_rgba(0,0,0,0.25)]`}
          >
            <DogPettingIcon name={"removedelete-pet"} />
            {isLoading ? "Deleting..." : "Delete Pet"}
          </button>
          <button
            className="flex flex-row items-center text-[clamp(.8rem,1vw,3rem)] font-[600] font-[Poppins] bg-black text-white px-[1.5vw] py-[.5vw] rounded-[1vw] shadow-[0px_.4vw_1vw_0px_rgba(0,0,0,0.25)]"
            onClick={handleClose}
            disabled={isLoading}
          >
            <DogPettingIcon name="removecancel-pet" />
            Cancel
          </button>
          {/* <button
            onClick={handleOpenOtp}
            className="bg-black text-white flex flex-row items-center justify-center font-[Poppins] font-[600] text-[clamp(1rem,1.2vw,3.5rem)] px-[1.5vw] py-[.75vw] rounded-[1.5vw] outline-none border-none"
          >
            <DogPettingIcon name="removecontinue-pet" /> Continue
          </button> */}
        </div>
        {openOtp && (
          <RemovePetSubModal
            pet={pet}
            isOpen={openOtp}
            handleRemovePet={handleRemovePet}
            isClose={handleOpenOtp}
          />
        )}
      </div>
    </Modal>
  );
};
