import React, { useState, useEffect } from "react";
import axios from "../axios";
import DogPettingIcon from "../Pages/Homepage/DogPettingIcon";
import "./Shop.css";
import { CiSearch } from "react-icons/ci";
import { ProductCard } from "./ProductCard";
import Cookies from "js-cookie";
import FilterModal from "./FilterModal"; // Import the modal component
import Product from "./components/Product";
import ProductActions from "./components/CartWishlist/ProductActions";
import NewHeader from "../Components/Header/NewHeader";
import WishlistItems from "./components/CartWishlist/WishlistItems";
import { useDispatch, useSelector } from "react-redux";
import ShopGif from "./components/ShopGif/ShopGifs";
import TrackOrder from "./components/TrackOrder/TrackOrder";
import DownloadAppScreen from "../Website/DownloadAppScreen/DownloadAppScreen";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Skeleton from "./components/Skeleton/Skeleton";
import Comingsoon from "../Components/Comingsoon/Comingsoon";
import { useShop } from "../shop/components/ShopContext";

function Shop() {
  const loginToken = Cookies.get("loginToken");
  const [activeLink, setActiveLink] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false); // State for modal visibility
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [isWishlistModalOpen, setIsWishlistModalOpen] = useState(false);
  const [trackOrderModalOpen, setTrackOrderModalOpen] = useState(false);
  const [CartOpen, setCartOpen] = useState(false);

  const { login } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { cartData, cartCount, pharmacyCartData, pharmacyCount } = useShop();
  // console.log(
  //   cartCount,
  //   cartData,
  //   pharmacyCartData,
  //   pharmacyCount,
  //   "shopopopoipipi"
  // );
  const getCombinedItems = () => {
    const allItems = [];

    // Add items from regular cart
    Object.values(cartData).forEach((categoryItems) => {
      allItems.push(...categoryItems);
    });

    // Add items from pharmacy cart
    Object.values(pharmacyCartData).forEach((categoryItems) => {
      allItems.push(...categoryItems);
    });

    return { combined: allItems.slice(-3).reverse() };
  };
  const combinedCartData = getCombinedItems();
  const totalItemCount = cartCount.totalCount + pharmacyCount.totalCount;

  const [isOpen, setIsOpen] = useState(false);
  const shopImages = [
    {
      id: 1,
      img: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/remove-bookmark.png",
      name: "remove-bookmar",
    },
    {
      id: 2,
      img: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/remove-cart.png",
      name: "remove-cart.",
    },
    {
      id: 3,
      img: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/cart-already.png",
      name: "cart-already",
    },
    {
      id: 4,
      img: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/remove-wishlist.png",
      name: "remove-wishlist",
    },
    {
      id: 5,
      img: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/add-wishlist.png",
      name: "add-wishlist",
    },
    {
      id: 6,
      img: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/add-cart.png",
      name: "add-cart",
    },
    {
      id: 7,
      img: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/add-Bookmark.png",
      name: "add-Bookmark",
    },
  ];
  const [wishMarkList, setWishMarkList] = useState(false);
  const dispatch = useDispatch();
  const [showSuccessGif, setShowSuccessGif] = useState(false);
  const [gifDetails, setGifDetails] = useState({
    gif: "",
    message: "",
  });

  const handleViewDetails = (product) => {
    console.log(product, "product");
    setSelectedProduct(product);
    setIsProductModalOpen(true);
  };
  const handleTrackOrder = () => {
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }
    setTrackOrderModalOpen((prevState) => !prevState);
  };
  const toggleCartWishlist = () => {
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }
    setCartOpen((prev) => !prev);
  };

  const closeProductModal = () => {
    setIsProductModalOpen(false);
    setSelectedProduct(null);
  };

  const handleWishlist = () => {
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }
    setIsWishlistModalOpen((prev) => !prev);
  };

  const handleNavClick = (linkName) => {
    if (linkName === "Filter") {
      // Toggle the filter modal when "Filter" is clicked
      setIsFilterModalOpen(true);
    } else {
      setActiveLink(linkName);
      setCurrentPage(1);
      console.log(linkName, "activeLink");
    }
  };
  const isLoggedIn = login?.login !== null && login?.login !== false;

  const closeModal = () => {
    setIsFilterModalOpen(false);
  };

  const categoryMapping = {
    Vaccine: "Vaccine",
    Deworm: "Deworm",
    Food: "Food",
    Medicine: "Medicine",
    Devices: "Devices",
    Accessories: "Accessories",
  };

  const navLinks = [
    { name: "All", icon: "wishlist-star" },
    { name: "Food", icon: "wishlist-food" },
    { name: "Vaccine", icon: "wishlist-deworm" },
    { name: "Deworm", icon: "wishlist-deworm" },
    { name: "Medicine", icon: "wishlist-medicine" },
    { name: "Devices", icon: "wishlist-device" },
    { name: "Accessories", icon: "wishlist-accessory" },
    { name: "Pet Care", icon: "wishlist-pet-care" },
    { name: "Filter", icon: "wishlist-filter" },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const ITEMS_PER_PAGE = 20;
  const [filterParams, setFilterParams] = useState();
  const handleApplyFilters = (filterData) => {
    setCurrentPage(1);
    setFilterParams(filterData);
    handleSearch(null, 1, filterData);
  };
  console.log(filterParams, "filterParams");
  const fetchProducts = async (page) => {
    setIsLoading(true);
    try {
      const params = {
        category: activeLink === "All" ? "" : activeLink,
      };
      console.log("Params:", params);
      const response = await axios.get(
        `/shop/searchproduct?page=${page}&limit=${ITEMS_PER_PAGE}`,
        {
          params,
          headers: {
            Authorization: loginToken,
          },
        }
      );
      setAllProducts(response.data.message);
      setFilteredProducts(response.data.message);
      console.log("Products:", response.data.message);
      const total = response.data.pagination.totalItems;
      setTotalPages(Math.ceil(total / ITEMS_PER_PAGE));
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage, activeLink]); // Fetch products whenever page changes

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    if (activeLink === "All") {
      setFilteredProducts(allProducts);
    } else {
      const backendCategory = categoryMapping[activeLink] || activeLink;
      const filtered = allProducts.filter((product) => {
        if (Array.isArray(backendCategory)) {
          return backendCategory.includes(product.Category);
        }
        return product.Category === backendCategory;
      });
      setFilteredProducts(filtered);
    }
  }, [activeLink, allProducts]);

  const handleSearch = async (e, page, filters = filterParams) => {
    if (e) setSearchQuery(e.target.value);
    setIsLoading(true);
    try {
      const params = {
        ProductName: e ? e.target.value : searchQuery,
        category: activeLink !== "All" ? activeLink : "",
        ...filters,
      };
      console.log("Params:", params);
      const response = await axios.get(
        `/shop/searchproduct?page=${page}&limit=${ITEMS_PER_PAGE}`,
        {
          params,
          headers: {
            Authorization: loginToken,
          },
        }
      );
      setFilteredProducts(response.data.message);
      console.log("Filtered products:", response.data.message);
    } catch (error) {
      console.error("Error searching products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const updateProductWishlistStatus = (productId, isInWishlist) => {
    setFilteredProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, isInWishlist } : product
      )
    );
    // console.log("Updated product wishlist status:", isInWishlist);
  };

  const fetchWishlistStatus = async (productId) => {
    try {
      const response = await axios.get("/shop/wishlistproducts", {
        headers: { Authorization: loginToken },
      });

      const wishlist = response.data.message || [];
      setWishMarkList(response.data.message);

      // Log the entire wishlist for debugging
      // console.log("Wishlist items:", wishlist);

      const isProductInWishlist = wishlist.some((item) => {
        // Log each item while checking the condition
        console.log("Checking item:", item);
        return item.Products.id === productId;
      });

      // console.log(productId, "productId");
      // console.log(isProductInWishlist, "isProductInWishlist");
      dispatch({ type: "refresh" });
      updateProductWishlistStatus(productId, isProductInWishlist);
      return isProductInWishlist;
    } catch (error) {
      console.error("Error fetching wishlist status:", error);
      return false;
    }
  };

  const addToWishlist = async (productId, category) => {
    try {
      const response = await axios.post(
        "/shop/addtowishlist",
        {
          ProductId: productId,
          ProductCategory: category,
        },
        {
          headers: { Authorization: loginToken },
        }
      );

      if (response.data.status) {
        updateProductWishlistStatus(productId, true);
        setGifDetails({
          gif: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/add-wishlist.png",
          message: "Added to wishlist Successfully!",
        });
        setShowSuccessGif(true);
        dispatch({ type: "refresh" });
      }
    } catch (error) {
      console.error("Error adding to wishlist:", error);
    }
  };

  const removeFromWishlist = async (productId) => {
    try {
      const response = await axios.delete(
        `/shop/removewishlistpdt/${productId}`,
        {
          headers: { Authorization: loginToken },
        }
      );

      if (response.data.status) {
        updateProductWishlistStatus(productId, false);
        setGifDetails({
          gif: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/remove-wishlist.png",
          message: "Removed from wishlist Successfully!",
        });
        setShowSuccessGif(true);
        dispatch({ type: "refresh" });
        console.log(response.data);
        console.log("Product removed from wishlist:", productId);
      }
    } catch (error) {
      console.error("Error removing from wishlist:", error);
    }
  };

  const handleGifClose = () => {
    setShowSuccessGif(false);
  };

  return (
    <>
      <NewHeader />
      {/* <div className="hidden md:block">
        <section className="shop-section">
          <div className="shop-top">
            <div className="shop-header">
              <div className="search-bar">
                <CiSearch className="search-bar-icon" />
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>
              <div className="track-order" onClick={handleTrackOrder}>
                <DogPettingIcon name={"track-order"} />
              </div>
              <div className="wishlist" onClick={handleWishlist}>
                <DogPettingIcon name={"wishlist-icon"} />
              </div>
              {combinedCartData.combined.length > 0 && (
                <div
                  className="w-[15vw] rounded-[.5vw] shadow-[0px_.4vw_1vw_0px_rgba(0,0,0,0.08)] h-[6vh] cursor-pointer"
                  onClick={toggleCartWishlist}
                >
                  <div className="flex flex-row items-center justify-around h-[6vh]">
                    <div className="flex flex-row items-center justify-start relative">
                      {combinedCartData.combined.map((item, index) => (
                        <div
                          key={item.id}
                          className="flex items-center justify-start  rounded-[.5vw] shadow-[0px_.3vw_.6vw_0px_rgba(0,0,0,0.15)] overlap "
                        >
                          <img
                            src={item.Products.productImages[0]?.url}
                            alt={item.Products.ProductName}
                            className="w-[2vw] h-[2vw] object-cover  p-[.2vw]"
                          />
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-col gap-[.5vh]">
                      <h1 className="text-[clamp(.6rem,.9vw,3rem)] leading-none">
                        View Cart
                      </h1>
                      <p className="text-[clamp(.6rem,.6vw,2rem)] leading-none">
                        {totalItemCount} + Items
                      </p>
                    </div>
                    <div className="bg-black w-[2vw] h-[2vw] flex items-center justify-center rounded-[.5vw]">
                      <DogPettingIcon name={"shop-viewcart-icon"} />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="shop-type">
              <div className="shop-nav-links">
                {navLinks.map((link) => (
                  <p
                    key={link.name}
                    className={`shop-nav-link ${
                      activeLink === link.name ? "shop-active" : ""
                    }`}
                    onClick={() => handleNavClick(link.name)}
                  >
                    {link.name}
                    <span className="icon-wrapper">
                      <DogPettingIcon
                        name={link.icon}
                        active={activeLink === link.name}
                      />
                    </span>
                  </p>
                ))}
              </div>
            </div>
          </div>
          <div className="shop-bottom flex flex-col gap-[3vh]">
            <div className="shop-container">
              <div>
                <p className="product-title">Best Seller</p>
              </div>

              {filteredProducts.length === 0 && !isLoading ? (
                <div className="no-products">
                  <p>No products found for this category or search.</p>
                  <p>Try changing the filter or search criteria.</p>
                </div>
              ) : (
                <div className="product-container">
                  {isLoading
                    ? // Show 8 skeleton cards while loading
                      Array(4)
                        .fill(null)
                        .map((_, index) => (
                          <Skeleton key={`skeleton-${index}`} />
                        ))
                    : filteredProducts.map((product) => (
                        <ProductCard
                          key={product.id}
                          product={product}
                          onViewDetails={handleViewDetails}
                          addToWishlist={addToWishlist}
                          removeFromWishlist={removeFromWishlist}
                          fetchWishlistStatus={fetchWishlistStatus}
                          isInWishlist={product.isInWishlist}
                          wishMarkList={wishMarkList}
                        />
                      ))}
                </div>
              )}
            </div>
            <div className="flex items-center justify-center mt-4">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  sx={{
                    "& .MuiPaginationItem-root": {
                      backgroundColor: "white",
                      color: "black",
                      "&:hover": {
                        backgroundColor: "gray", // Optional hover effect
                      },
                      "&.Mui-selected": {
                        backgroundColor: "black",
                        color: "white",
                      },
                    },
                  }}
                />
              </Stack>
            </div>
          </div>
        </section>
      </div>
      <div className="block md:hidden">
        <DownloadAppScreen />
      </div> */}

      <Comingsoon />

      {/* Filter Modal */}
      {isFilterModalOpen && (
        <FilterModal
          isClose={closeModal}
          isOpen={isFilterModalOpen}
          onApplyFilters={handleApplyFilters}
        />
      )}
      {isProductModalOpen && (
        <Product
          isOpen={isProductModalOpen}
          isClose={closeProductModal}
          product={selectedProduct}
        />
      )}
      {isLoggedIn && isWishlistModalOpen && (
        <WishlistItems
          isOpen={handleWishlist}
          isClose={handleWishlist}
          product={selectedProduct}
        />
      )}
      {isLoggedIn && trackOrderModalOpen && (
        <TrackOrder isOpen={handleTrackOrder} isClose={handleTrackOrder} />
      )}
      {showSuccessGif && (
        <ShopGif
          onClose={handleGifClose}
          message={gifDetails.message}
          gif={gifDetails.gif}
        />
      )}
      {isLoggedIn && CartOpen && (
        <ProductActions
          isOpen={toggleCartWishlist}
          isClose={toggleCartWishlist}
        />
      )}
    </>
  );
}

export default Shop;
