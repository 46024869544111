import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import "./Settingsmodal.css";
import "../Overview/PetFamily/InvitePetFamily.css";
import petEyeProfile from "../../../../assets/Frame.svg";
import axios from "../../../../axios";
import Cookies from "js-cookie";
import { ChevronDown } from "lucide-react";

import { useNavigate } from "react-router-dom";

export const DeleteConfirmation = ({ isOpen, isClose }) => {
  const loginToken = Cookies.get("loginToken");
  const navigate = useNavigate();
  const [isClosing, setIsClosing] = useState(false);
  const [showConfirmationData, setShowConfirmationData] = useState(false);
  const [deletingReason, setDeletingReason] = useState("");
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [error, setError] = useState("");

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000); // Match the duration of the closing animation
  };

  const toggleConfirmationData = () => {
    setShowConfirmationData((prev) => !prev);
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get("/user/userInfo", {
          headers: {
            Authorization: loginToken,
          },
        });
        const { message } = response.data;
        setUserData({
          firstName: message.firstName || "",
          lastName: message.lastName || "",
          email: message.email || "",
        });
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  const handleLogout = () => {
    const token = Cookies.get("loginToken");

    if (token) {
      Cookies.remove("loginToken");
      const removedToken = Cookies.get("loginToken");

      if (!removedToken) {
        console.log("Token removed successfully. Refreshing page...");
        window.location.reload();
        setTimeout(() => {
          navigate("/login"); // Redirect to the login page using useNavigate
        }, 100); // Small delay to ensure the page reloads before navigating
      } else {
        console.error("Failed to remove the token.");
      }
    } else {
      console.error("No token found in cookies.");
      window.location.reload();
      setTimeout(() => {
        navigate("/login"); // Redirect to the login page even if no token is found
      }, 100); // Small delay to ensure the page reloads before navigating
    }
  };
  console.log(userData.mobile, userData.email, "userData");
  const handleDeleteAccount = async () => {
    try {
      if (!deletingReason) {
        return setError("Please select a reason");
      }
      await axios.post(
        "/user/deleteaccount",
        { reason: deletingReason },
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );
      console.log("Account deleted successfully.");
      handleLogout();
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  if (!isOpen) return null;

  const reasons = [
    "No longer own a pet",
    "Account no longer needed",
    "Duplicate account",
    "Created account by mistake",
    "Prefer not to say",
    "Other",
  ];

  console.log(deletingReason, "deletingReason");

  return (
    <>
      <div
        className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
        onClick={handleOverlayClick}
        aria-modal="true"
        role="dialog"
      >
        <div className={`modal-container ${isClosing ? "close" : ""}`}>
          <div className="modal-top-header">
            <div className="modal-title" aria-label="Delete Your Account">
              <p>
                <DogPettingIcon name={"owner-profile"} /> Delete Your Account
              </p>
            </div>
            <div className="modal-close" onClick={handleClose}>
              <DogPettingIcon
                name={"closemodal"}
                className="modal-close-icon"
              />
            </div>
          </div>
          <div className="setting-modal-description">
            <div className="logout-modal">
              <div className="logout-container">
                <div className="delete-confirmation">
                  {!showConfirmationData && <h3>Delete Account</h3>}
                  <p>
                    {showConfirmationData
                      ? "Are you sure you want to delete your account? This will permanently remove all your data and settings."
                      : "Deleting your account is an irreversible process. When you delete your PetEYE account, your profile, pet's details, photos, and all other data will be permanently removed."}
                  </p>
                </div>

                {showConfirmationData ? (
                  <div className="confirm-delete">
                    <span>
                      <DogPettingIcon name={"delete-account"} />
                    </span>
                    <div className="add-pet-input-group confirm-delete-input">
                      <select
                        id="delete-reason"
                        value={deletingReason}
                        onChange={(e) => setDeletingReason(e.target.value)}
                        aria-label="Reason for Deleting Account"
                        className={
                          !deletingReason
                            ? "placeholder border border-[#FF0000]"
                            : ""
                        }
                      >
                        <option value="" disabled>
                          Select a reason for deleting your account
                        </option>
                        {reasons.map((reason, index) => (
                          <option key={index} value={reason}>
                            {reason}
                          </option>
                        ))}
                      </select>
                      {!deletingReason && error && (
                        <span className="text-[#FF0000] text-[clamp(0.8rem,0.8vw,1rem)]">
                          Select a reason for deleting your account
                        </span>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="account">
                    <img src={petEyeProfile} alt="PetEye Profile" />
                    <div className="account-about">
                      <h4>
                        {userData.firstName} {userData.lastName}
                      </h4>
                      <p>{userData.email}</p>
                    </div>
                  </div>
                )}
                {showConfirmationData ? (
                  <div className="confirm-delete-control">
                    <button className="logout-btn" onClick={handleLogout}>
                      <DogPettingIcon name={"setting-logout"} />
                      Logout Instead
                    </button>
                    <button
                      className="delete-btn"
                      onClick={handleDeleteAccount}
                    >
                      <DogPettingIcon name={"delete-btn"} />
                      Delete Account
                    </button>
                  </div>
                ) : (
                  <div className="logout-container-control">
                    <button
                      className="logout-btn"
                      onClick={toggleConfirmationData}
                      aria-label="Confirm account deletion"
                    >
                      <DogPettingIcon name={"accept"} />
                      Confirm
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
