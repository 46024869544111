import React, { useEffect, useState } from "react";
import DogPettingIcon from "../../../Pages/Homepage/DogPettingIcon"; // Assuming this is how you import the icon
import "./ProductActions.css";
import axios from "../../../axios";
import { LuUpload } from "react-icons/lu";
import { IoDocumentText } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import Success from "../../../Pages/Profile/Success/Success";
import { AiFillFilePdf } from "react-icons/ai";
import Prescription from "./Prescription";
import Checkout from "./Checkout/Checkout";
import { useShop } from "../ShopContext";
import Coupons from "./Coupons";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
// import { ChevronUp, ChevronDown } from "lucide-react";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import Failedgif from "../../../Pages/Failed/Failedgif";
import {
  ChevronUp,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import VaccinationBookingCart from "./Checkout/VaccinationBookingCart";
import { CheckSquare } from "lucide-react";
import Icons from "../../../context/Icons";

export default function PharmacyItems({ bookingVaccine = false, bookingData }) {
  const {
    pharmacyCartData,
    handleQuantityPharmacyCartChange,
    groupItemsByCategory,
    calculatePharmacyCartTotal,
    calculateTotalDiscount,
    calculateDiscountedPrice,
    fetchPharmacyCartItems,
    handleQuantityChange,
    totalDiscount,
    handlePrescriptionFile,
    selectedVaccineDate,
    setSelectedVaccineDate,
    selectedVaccineTimeSlot,
    setSelectedVaccineTimeSlot,
    handleParaVetChecked,
    setParaVetChecked,
    paraVetChecked,
  } = useShop();
  // console.log(bookingVaccine, bookingData, "2312booking vaccine");

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isClosing1, setIsClosing1] = useState(false);
  const [openCoupons, setOpenCoupons] = useState(false);
  const [prescriptionOpen, setPrescriptionOpen] = useState(false);
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [expandedCoupons, setExpandedCoupons] = useState({});
  const [isSlotOpen, setIsSlotOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const loginToken = Cookies.get("loginToken");

  const [isVaccineOpen, setIsVaccineOpen] = useState(() => {
    const saved = localStorage.getItem("isVaccineOpen");
    console.log(saved, "isVaccineOpen");
    return saved === "true"; // Convert string to boolean
  });
  const [selectedDate, setSelectedDate] = useState(() => {
    return localStorage.getItem("selectedVaccineDate") || "";
  });

  const [selectedSlot, setSelectedSlot] = useState(() => {
    return localStorage.getItem("selectedVaccineTimeSlot") || "";
  });

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [isSlotExpanded, setIsSlotExpanded] = useState(false);
  console.log(pharmacyCartData, "pharmacyCartDataasss");

  const categories = Object.keys(pharmacyCartData).filter(
    (category) => pharmacyCartData[category]?.length > 0
  );

  console.log(
    categories.map((category) => category === "Vaccine"),
    "categories"
  );
  const isCartEmpty = categories.length === 0 && !bookingVaccine;

  useEffect(() => {
    fetchPharmacyCartItems();
  }, []);


  if (isCartEmpty) {
    return <Failedgif message="No items in the cart" />;
  }
  const timeSlots = [
    "09:00 AM - 10:00 AM",
    "10:30 AM - 11:30 AM",
    "12:00 PM - 01:00 PM",
    "02:00 PM - 03:00 PM",
    "03:30 PM - 04:30 PM",
    "05:00 PM - 06:00 PM",
    "06:30 PM - 07:30 PM",
  ];

 

  

  const handleVaccineCheckbox = (checked) => {
    setIsVaccineOpen(checked);
    handleParaVetChecked?.(checked);
  };

  const getDaysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const handleDateSelect = (date) => {
    const formattedDate = formatDate(date);
    setSelectedDate(formattedDate);
    localStorage.setItem("selectedVaccineDate", formattedDate);
    setIsDateOpen(false);
  };

  // Handle time slot selection
  const convertTo24HourFormat = (timeSlot) => {
    const [startTime, endTime] = timeSlot.split(" - ");

    const convertTo24Hr = (time) => {
      let [hours, minutes, period] = time.split(/[:\s]+/); // Split time by colon and space
      hours = parseInt(hours, 10); // Convert hour to number

      // Convert based on AM/PM
      if (period === "PM" && hours !== 12) hours += 12;
      if (period === "AM" && hours === 12) hours = 0;

      return `${String(hours).padStart(2, "0")}:${minutes}`;
    };

    return `${convertTo24Hr(startTime)} - ${convertTo24Hr(endTime)}`;
  };

  const handleTimeSlotSelect = (slot) => {
    const formattedSlot = convertTo24HourFormat(slot);
    setSelectedSlot(formattedSlot);
    localStorage.setItem("selectedVaccineTimeSlot", formattedSlot); // Store in 24-hour format
    console.log(formattedSlot, "formatted slot");
    setIsSlotExpanded(false);
  };

  const renderCalendar = () => {
    const daysInMonth = getDaysInMonth(currentMonth);
    const firstDayOfMonth = getFirstDayOfMonth(currentMonth);
    const days = [];
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Add empty cells for days before the first day of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="h-8 w-8" />);
    }

    // Add cells for each day of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth(),
        day
      );
      const isDisabled = date < today;
      const isSelected = selectedDate === formatDate(date);

      days.push(
        <button
          key={day}
          disabled={isDisabled}
          onClick={() => !isDisabled && handleDateSelect(date)}
          className={`
          h-8 w-8 rounded-full flex items-center justify-center text-sm
          ${
            isDisabled
              ? "text-gray-300 cursor-not-allowed"
              : "hover:bg-blue-100 cursor-pointer"
          }
          ${isSelected ? "bg-blue-500 text-white hover:bg-blue-600" : ""}
        `}
        >
          {day}
        </button>
      );
    }

    return days;
  };

  const toggleDescription = (id) => {
    setExpandedCoupons((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsClosing(true);
      handleUploadModalClose();
    }
  };

  const uploadFileToS3 = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post("/file/uploadFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: loginToken,
        },
      });
      const url = response.data.Message ? response.data.Message.getPeteye : "";
      console.log("File uploaded successfully:", url);
      return url;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      return null;
    }
  };

  const handleFileUpload = async () => {
    if (selectedFiles.length > 0) {
      try {
        const file = selectedFiles[0];
        const url = await uploadFileToS3(file);
        if (url) {
          const response = await axios.post(
            "/petinfo/addpetdocuments",
            {
              documentList: [
                {
                  docType: "AdoptionPaperFile",
                  fileType: "pdf",
                  url: url,
                  fileName: file.name,
                },
              ],
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          );
          console.log(" addpetdocuments added:", response);
          setUploadStatus("success");
          setUploadedFile(selectedFiles[0]);
          dispatch({ type: "refresh" });
        } else {
          setUploadStatus("failed");
        }
      } catch (error) {
        console.error("Error posting data:", error);
        setUploadStatus("failed");
      }
    }
    setTimeout(() => {
      setUploadStatus("");
      setIsUploadModalOpen(false);
    }, 3000);
  };

  const handleUploadModalClose = () => {
    setIsClosing1(true);
    setTimeout(() => {
      setIsClosing1(false);
      setIsUploadModalOpen(false);
      setUploadStatus("");
      setSelectedFiles([]);
    }, 1000);
  };
  const handleCoupon = () => {
    setOpenCoupons((prev) => !prev);
  };

  const handlePrescription = () => {
    setPrescriptionOpen((prev) => !prev);
  };

  const handleCheckout = () => {
    setCheckoutOpen((prev) => !prev);
  };

  const handleFileRemove = (index, isUploaded = false) => {
    // Remove from selectedFiles state
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));

    // Remove from localStorage
    const storedFiles = getPrescriptionFileData() || [];
    if (storedFiles.length > 0) {
      // Remove the file at the specified index
      const updatedFiles = storedFiles.filter((_, i) => i !== index);

      // Update localStorage with the remaining files
      localStorage.setItem("PrescriptionFile", JSON.stringify(updatedFiles));
    }
  };

  // Updated handleFileSelect to work with the new removal system
  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles((prev) => [...prev, ...files]);
    setIsUploadModalOpen(false);

    // Get existing files from localStorage
    const existingFiles = getPrescriptionFileData() || [];

    // Add new files to the existing ones
    const filesData = files.map((file) => ({
      name: file.name,
      type: file.type,
      size: file.size,
      lastModified: file.lastModified,
    }));

    const updatedFiles = [...existingFiles, ...filesData];
    localStorage.setItem("PrescriptionFile", JSON.stringify(updatedFiles));
  };

  // Helper function to get prescription file data
  const getPrescriptionFileData = () => {
    const storedData = localStorage.getItem("PrescriptionFile");
    if (!storedData) return [];
    try {
      return JSON.parse(storedData);
    } catch (error) {
      console.error("Error parsing stored file data:", error);
      return null;
    }
  };

  // Updated renderFilePreview component
  const renderFilePreview = (file, index, isUploaded = false) => (
    <div
      key={index}
      className="flex items-center justify-between w-full mb-[.5vw]"
    >
      <h5 className="text-[clamp(1rem,1.2vw,3.5rem)] w-[70%] truncate">
        {file.fileName || file.name}
      </h5>
      <button
        onClick={() => handleFileRemove(index, isUploaded)}
        className="delete-file-btn"
      >
        <MdDeleteOutline />
      </button>
    </div>
  );

  // Updated way to combine files
  const getCombinedFiles = () => {
    const prescriptionFiles = getPrescriptionFileData() || [];
    return [
      ...selectedFiles,
      ...prescriptionFiles.map((item) => ({ name: item.name })),
    ];
  };
  const combinedFiles = getCombinedFiles();

  return (
    <>
      <>
        <div className="flex flex-col gap-[1vw] p-[1vw] mt-[.3vw]">
          <div className="shop-cart-items">
            {!bookingVaccine ? (
              <>
                {categories.map((category) => (
                  <div key={category} className="shop-cart-item-category">
                    <h1 className="category-title">{category}</h1>
                    {pharmacyCartData[category].map((item) => (
                      <div className="shop-cart-item" key={item.id}>
                        <div className="shop-cart-item-image">
                          <img
                            src={item.Products.productImages[0]?.url}
                            alt={item.Products.ProductName}
                          />
                        </div>
                        <div className="shop-cartitemdetails">
                          <div>
                            <h3>{item.Products.ProductName}</h3>
                            <p>{item.Products.Description}</p>
                          </div>
                          <div className="shop-cart-item-price-quantity">
                            <p className="shop-cart-item-weight">
                              {item.productvariant.unit}
                            </p>
                          </div>
                        </div>
                        <div className="shop-cart-item-details">
                          <div className="shop-cart-item-quantity">
                            <button
                              className="quantity-btn"
                              onClick={() =>
                                handleQuantityPharmacyCartChange(item.id, -1)
                              }
                            >
                              -
                            </button>
                            <span className="quantity-display">
                              {item.quantity}
                            </span>
                            <button
                              className="quantity-btn"
                              onClick={() =>
                                handleQuantityPharmacyCartChange(item.id, 1)
                              }
                            >
                              +
                            </button>
                          </div>
                          <div className="shop-cart-item-price">
                            <h2>₹</h2>
                            <h6>
                              {(
                                (item.productvariant.price /
                                  (1 -
                                    (item.productvariant.OfferPercentage || 0) /
                                      100)) *
                                item.quantity
                              ).toFixed(0)}
                            </h6>
                            <p>
                              {parseFloat(
                                item.productvariant.price * item.quantity
                              ).toFixed(2)}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </>
            ) : (
              <VaccinationBookingCart bookingData={bookingData} />
            )}
          </div>
          {!bookingVaccine && (
            <div
              className={`
                rounded-[1vw] cursor-pointer bg-[#f5f5f5] p-[1vw]
                transition-all duration-300 ease-in-out flex flex-col  items-center align-middle
                ${isOpen ? "h-[40vh]" : "h-[8vh]"}
              `}
            >
              <div
                className=" flex items-center justify-between transition-colors duration-300 w-full h-100"
                onClick={() => setIsOpen(!isOpen)}
              >
                <div className="flex items-center ">
                  {combinedFiles.length > 0 ? (
                    <p className="text-[clamp(1rem,1.2vw,3.5rem)]">
                      Prescription Uploaded
                    </p>
                  ) : (
                    <p className="text-[clamp(1rem,1.2vw,3.5rem)]">
                      Prescription
                    </p>
                  )}
                </div>

                <div className="transform transition-transform duration-300 ease-in-out">
                  {isOpen ? (
                    <ChevronUp className="h-[3vh] w-[2vw] text-gray-500" />
                  ) : (
                    <ChevronDown className="h-[3vh] w-[2vw] text-gray-500" />
                  )}
                </div>
              </div>

              <div
                className={`
                    transition-all duration-300 ease-in-out flex flex-col items-center justify-between w-full gap-[1vw] 
                     ${
                       isOpen
                         ? "opacity-100 translate-y-0"
                         : "opacity-0 -translate-y-2 h-0 overflow-hidden"
                     }
              `}
              >
                <hr className="w-full border-gray-300 mt-[1vw]" />
                <div className="flex items-center justify-between w-full">
                  <p className="text-[clamp(1rem,1.2vw,3.5rem)]">
                    Add Prescription
                  </p>
                  <span onClick={() => setIsUploadModalOpen(true)}>
                    <DogPettingIcon name="cart-upload-prescriptionicon" />
                  </span>
                </div>
                {combinedFiles.length > 0 && (
                  <div className="w-full">
                    {combinedFiles.map((file, index) =>
                      renderFilePreview(file, index)
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {/* <div
            className="bg-[#f5f5f5] p-[1vw] rounded-[1vw] flex items-center justify-between cursor-pointer "
            onClick={handleCoupon}
          >
            <p className="text-[clamp(1rem,1.2vw,3.5rem)] font-[Open Sans]">
              Apply Coupon
            </p>
            <p>
              <MdOutlineKeyboardArrowRight className="text-[clamp(1rem,1.2vw,3.5rem)]" />
            </p>
          </div> */}

          {(categories.includes("Vaccine") || bookingVaccine) && (
            <div className="bg-gray-100 p-[1vw] rounded-[1vw] cursor-pointer">
              <div className="flex items-center justify-start gap-[.5vw]">
                <input
                  type="checkbox"
                  className="h-[2.5vh] w-[2vw] cursor-pointer"
                  onChange={() => setParaVetChecked((prev) => !prev)}
                  checked={paraVetChecked}
                />
                <p className="text-[clamp(.8rem,1vw,3.5rem)] capitalize">
                  A professional vet will visit and vaccinate your pet
                </p>
              </div>

              {paraVetChecked && (
                <div className="mt-[2.5vh] flex flex-col gap-[.5vw]">
                  <div className="relative">
                    <div
                      className="bg-white p-[.8vw] rounded-[1vw] border flex justify-between items-center cursor-pointer"
                      onClick={() => setIsDateOpen(!isDateOpen)}
                    >
                      <span>
                        {selectedDate || "Select Date for Vaccination"}
                      </span>
                      {isDateOpen ? (
                        <ChevronUp className="h-[2.5vh] w-[2vw] text-gray-500" />
                      ) : (
                        <ChevronDown className="h-[2.5vh] w-[2vw] text-gray-500" />
                      )}
                    </div>

                    {isDateOpen && (
                      <div className="absolute w-64 mt-1 bg-white border rounded-lg shadow-lg z-10">
                        <div className="p-2">
                          <div className="flex justify-between items-center mb-4">
                            <ChevronLeft
                              className="h-4 w-4 cursor-pointer"
                              onClick={() =>
                                setCurrentMonth(
                                  new Date(
                                    currentMonth.getFullYear(),
                                    currentMonth.getMonth() - 1
                                  )
                                )
                              }
                            />
                            <span className="font-medium">
                              {currentMonth.toLocaleDateString("en-US", {
                                month: "long",
                                year: "numeric",
                              })}
                            </span>
                            <ChevronRight
                              className="h-4 w-4 cursor-pointer"
                              onClick={() =>
                                setCurrentMonth(
                                  new Date(
                                    currentMonth.getFullYear(),
                                    currentMonth.getMonth() + 1
                                  )
                                )
                              }
                            />
                          </div>
                          <div className="grid grid-cols-7 gap-1">
                            {renderCalendar()}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {selectedDate && (
                    <div className="bg-white p-[.7vw] rounded-[1vw] border">
                      <div
                        className="flex justify-between items-center cursor-pointer"
                        onClick={() => setIsSlotExpanded(!isSlotExpanded)}
                      >
                        <span>{selectedSlot || "Select Time Slot"}</span>
                        {isSlotExpanded ? (
                          <ChevronUp className="h-[2.5vh] w-[2vw] text-gray-500" />
                        ) : (
                          <ChevronDown className="h-[2.5vh] w-[2vw] text-gray-500" />
                        )}
                      </div>

                      <div
                        className={`transition-all duration-300 ease-in-out ${
                          isSlotExpanded ? "max-h-[60vh]" : "max-h-0"
                        } overflow-hidden`}
                      >
                        <div className="border-t mt-3">
                          {timeSlots.map((slot) => (
                            <label
                              key={slot}
                              className="flex items-center justify-between gap-3 py-2 hover:bg-gray-50 cursor-pointer"
                            >
                              <span>{slot}</span>
                              <input
                                type="radio"
                                name="timeSlot"
                                value={slot}
                                checked={selectedSlot === slot}
                                onChange={() => handleTimeSlotSelect(slot)}
                                className="h-[2.5vh] w-[2vw]"
                              />
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          
        </div>
      </>

      {isUploadModalOpen && (
        <div
          className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
          onClick={handleOverlayClick}
        >
          <div className={`modal-container ${isClosing ? "close" : ""}`}>
            <div className="add-new-documents">
              <div className="modal-close" onClick={handleUploadModalClose}>
                <DogPettingIcon
                  name={"closemodal"}
                  className="modal-close-icon"
                />
              </div>

              {uploadStatus === "success" ? (
                <Success
                  message="Upload successfully!"
                  header="Success"
                  gif="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/success.gif"
                />
              ) : uploadStatus === "failed" ? (
                <Success
                  message="Oops! No files found."
                  gif="https://peteye-drive.s3.ap-south-1.amazonaws.com/Supportwebp/DrowningDog.webp"
                />
              ) : (
                <div className="add-new-documents-content">
                  <div className="records-Upload-title">
                    <p>
                      <IoDocumentText
                        style={{ fontSize: "clamp(1.3rem, 1.5vw ,4rem)" }}
                      />
                    </p>
                    <h2>Upload Documents</h2>
                  </div>

                  <div className="upload-doc-files">
                    <label htmlFor="fileInputUpload">
                      <p>
                        <DogPettingIcon name="upload-doc" />
                      </p>
                      <div className="upload-doc-files-title">
                        <h5>
                          <LuUpload /> <h6>Upload</h6>
                        </h5>
                        <p>Maximum Size: 3MB</p>
                      </div>
                    </label>
                    <input
                      type="file"
                      multiple
                      accept="application/pdf"
                      id="fileInputUpload"
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {prescriptionOpen && (
        <Prescription
          isClose={handlePrescription}
          isOpen={handlePrescription}
        />
      )}
      {checkoutOpen && (
        <Checkout isClose={handleCheckout} isOpen={handleCheckout} />
      )}
      {openCoupons && <Coupons isClose={handleCoupon} isOpen={handleCoupon} />}
    </>
  );
}
