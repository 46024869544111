import React, { useState, useEffect } from "react";
import DogPettingIcon from "../../../../Pages/Homepage/DogPettingIcon";
import axios from "../../../../axios";
import Axios from "axios";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
const Address = ({
  isClosing,
  handleClose,
  isEditing,
  initialAddress,
  loginToken,
  setAddresses,
  addresses,
  fetchAddresses,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [errorTimeout, setErrorTimeout] = useState(null);
  const [nationality, setNationality] = useState({ Name: "India" });
  const [openBackdrop, setopenBackdrop] = useState(false);
  const dispatch = useDispatch();
  const [isLocating, setIsLocating] = useState(false);
  const [newAddress, setNewAddress] = useState({
    firstname: "",
    lastname: "",
    mobilenumber: "",
    email: "",
    // secondaryMobileNumber: "",
    pincode: "",
    state: "",
    district: "",
    city: "",
    HouseFloorNo: "",
    area: "",
    country: "India",
  });

  useEffect(() => {
    if (initialAddress) {
      setNewAddress({
        firstname: initialAddress.firstname || "",
        lastname: initialAddress.lastname || "",
        mobilenumber: initialAddress.mobilenumber || "",
        email: initialAddress.email || "",
        secondaryMobileNumber: initialAddress.secondaryMobileNumber || "",
        pincode: initialAddress.pincode || "",
        state: initialAddress.state || "",
        district: initialAddress.district || "",
        city: initialAddress.city || "",
        HouseFloorNo: initialAddress.HouseFloorNo || "",
        area: initialAddress.area || "",
        country: initialAddress.country || "",
      });
    }
  }, [initialAddress]);

  const showError = (message) => {
    if (errorTimeout) clearTimeout(errorTimeout);
    setErrorMessage(message);
    const timeout = setTimeout(() => {
      setErrorMessage("");
    }, 3000);
    setErrorTimeout(timeout);
  };

  const validateForm = () => {
    if (!newAddress.firstname || !newAddress.firstname.trim()) {
      showError("First name is required");
      return false;
    }
    if (!/^[a-zA-Z\s]+$/.test(newAddress.firstname)) {
      showError("First name can only contain alphabets");
      return false;
    }
    if (!newAddress.mobilenumber) {
      showError("Phone number is required");
      return false;
    }
    if (!/^\d{10}$/.test(newAddress.mobilenumber)) {
      showError("Phone number must be exactly 10 digits");
      return false;
    }
    // if (!newAddress.email) {
    //   showError("Email is required");
    //   return false;
    // }
    // if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newAddress.email)) {
    //   showError("Invalid email format");
    //   return false;
    // }
    // if (
    //   newAddress.secondaryMobileNumber &&
    //   !/^\d{10}$/.test(newAddress.secondaryMobileNumber)
    // ) {
    //   showError("Secondary phone number must be exactly 10 digits");
    //   return false;
    // }
    if (!newAddress.pincode) {
      showError("Pincode is required");
      return false;
    }
    if (!/^\d{6}$/.test(newAddress.pincode)) {
      showError("Pincode must be exactly 6 digits");
      return false;
    }

    const requiredFields = [
      "HouseFloorNo",
      "city",
      "district",
      "state",
      "area",
    ];
    for (const field of requiredFields) {
      if (!newAddress[field] || !newAddress[field].trim()) {
        showError(
          `${field.charAt(0).toUpperCase() + field.slice(1)} is required`
        );
        return false;
      }
    }
    return true;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setNewAddress((prev) => ({ ...prev, [name]: newValue }));

    if (name === "pincode" && value.length === 6) {
      fetchPostalCode(value);
      setopenBackdrop(true);
    }
  };

  const fetchPostalCode = async (code) => {
    try {
      const response = await Axios.get(
        `https://api.postalpincode.in/pincode/${code}`
      );
      const postalData = response?.data[0]?.PostOffice[0];
      if (postalData) {
        setNewAddress((prevData) => ({
          ...prevData,
          city: postalData.District || "",
          state: postalData.State || "",
          district: postalData.District || "",
          area: postalData.Name || "",
        }));
      } else {
        showError("Invalid pincode. Please enter a valid pincode.");
      }
    } catch (err) {
      console.error("Error fetching postal code:", err);
      showError("Error fetching address details. Please try again.");
    } finally {
      setopenBackdrop(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const addressData = {
        firstName: newAddress.firstname,
        lastName: newAddress.lastname,
        Mobilenumber: newAddress.mobilenumber,
        // Email: newAddress.email,
        // SecondaryMobilenumber: newAddress.secondaryMobileNumber || null,
        Pincode: newAddress.pincode,
        State: newAddress.state,
        District: newAddress.district,
        City: newAddress.city,
        HouseFloorNo: newAddress.HouseFloorNo,
        Area: newAddress.area,
        Country: newAddress.country || "India",
      };

      if (isEditing) {
        const addressId = initialAddress.id;
        if (!addressId) {
          throw new Error("Address ID is required for updating");
        }

        const response = await axios.patch(
          `/shop/updateaddress`,
          {
            Id: addressId,
            ...addressData,
          },
          {
            headers: { Authorization: loginToken },
          }
        );

        const updatedAddresses = addresses.map((addr) =>
          addr.id === addressId
            ? { ...addr, ...addressData, id: addressId }
            : addr
        );
        console.log("Address updated:", response.data);
        setAddresses(updatedAddresses);
        fetchAddresses();
      } else {
        const response = await axios.post("/shop/addnewaddress", addressData, {
          headers: { Authorization: loginToken },
        });
        console.log("Address saved:", response.data);
        const newAddressWithId = {
          id: response.data.id,
          ...addressData,
        };
        setAddresses((prev) => [...prev, newAddressWithId]);
      }
      fetchAddresses();
      dispatch({ type: "refresh" });
      handleClose();
    } catch (error) {
      console.error("Error saving address:", error.message);
      showError(error.message || "Failed to save address. Please try again.");
    }
  };

  const handleDelete = async () => {
    if (!isEditing) return;

    try {
      await axios.delete(`/shop/deleteaddress/${isEditing}`, {
        headers: { Authorization: loginToken },
      });
      setAddresses((prev) => prev.filter((addr) => addr.id !== isEditing));
      handleClose();
    } catch (error) {
      console.error("Error deleting address:", error);
      showError("Failed to delete address. Please try again.");
    }
  };
  const getCurrentLocation = async () => {
    if (!navigator.geolocation) {
      showError("Geolocation is not supported by your browser");
      return;
    }

    setIsLocating(true);
    setopenBackdrop(true);

    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        });
      });

      const { latitude, longitude } = position.coords;

      // Get address details from Nominatim
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1&zoom=18`,
        {
          headers: {
            "Accept-Language": "en-US,en;q=0.9",
          },
        }
      );

      const data = await response.json();
      console.log("Nominatim response:", data);

      const address = data.address;

      // If we have a valid pincode, use postal API
      if (address.postcode && address.postcode.length === 6) {
        try {
          const postalResponse = await Axios.get(
            `https://api.postalpincode.in/pincode/${address.postcode}`
          );

          const postalData = postalResponse?.data[0]?.PostOffice[0];
          console.log("Postal data:", postalData);
          if (postalData) {
            setNewAddress((prev) => ({
              ...prev,
              pincode: address.postcode,
              state: postalData.State || prev.state,
              district: postalData.District || prev.district,
              city: postalData.District || prev.city,
              area: postalData.Name || prev.area,
            }));
            setopenBackdrop(false);
            return;
          }
        } catch (err) {
          console.error("Error fetching postal data:", err);
        }
      }

      // Fallback to Nominatim data
      const areaName =
        address.neighbourhood ||
        address.suburb ||
        address.hamlet ||
        address.road ||
        address.locality ||
        data.display_name.split(",")[0];

      setNewAddress((prev) => ({
        ...prev,
        state: address.state || prev.state,
        district:
          address.state_district ||
          address.county ||
          address.city_district ||
          prev.district,
        city: address.city || address.town || address.village || prev.city,
        area: areaName || prev.area,
        pincode: address.postcode || prev.pincode,
      }));
    } catch (error) {
      console.error("Error getting location:", error);
      showError(
        "Unable to get your location. Please fill in the address manually."
      );
    } finally {
      setIsLocating(false);
      setopenBackdrop(false);
    }
  };

  return (
    <div className={`modal-overlay ${isClosing ? "fade-out" : ""}`}>
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        <form onSubmit={handleSubmit} className="checkout-container">
          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name="closemodal" className="modal-close-icon" />
          </div>
          <div className="shop-checkout-title">
            <h2>{isEditing ? "Edit Address" : "Add Address"}</h2>
          </div>

          <div className="addaddress-input-details">
            <button
              type="button"
              onClick={getCurrentLocation}
              disabled={isLocating}
              className=" focus:outline-none py-[.5vw] px-[1vw] rounded-[1vw] bg-black text-white text-[clamp(1rem,1vw,3rem)] flex items-center justify-center"
            >
              <FaMapMarkerAlt /> &ensp;
              {isLocating ? "Getting Location..." : "Use Current Location"}
            </button>
            <div className="addaddress-input">
              <p>Name</p>
              <input
                type="text"
                name="firstname"
                value={newAddress.firstname}
                onChange={handleInputChange}
                placeholder="First Name*"
                required
              />
              <input
                type="text"
                name="lastname"
                value={newAddress.lastname}
                onChange={handleInputChange}
                placeholder="Last Name"
              />
              <input
                type="tel"
                name="mobilenumber"
                value={newAddress.mobilenumber}
                onChange={handleInputChange}
                placeholder="Phone Number*"
                maxLength="10"
                required
              />
              {/* <input
                type="email"
                name="email"
                value={newAddress.email}
                onChange={handleInputChange}
                placeholder="Email*"
                required
              /> */}
            </div>

            <div className="addaddress-input pb-[4vw]">
              <p>Address</p>
              <input
                type="text"
                name="country"
                value={newAddress.country}
                onChange={handleInputChange}
                placeholder="Country"
                readOnly
              />

              <input
                type="text"
                name="pincode"
                value={newAddress.pincode}
                onChange={handleInputChange}
                placeholder="Pin code*"
                maxLength="6"
                required
              />

              <input
                type="text"
                name="state"
                value={newAddress.state}
                onChange={handleInputChange}
                placeholder="State*"
                // readOnly={newAddress.country === "India"}
                required
              />

              <input
                type="text"
                name="district"
                value={newAddress.district}
                onChange={handleInputChange}
                placeholder="District*"
                // readOnly={newAddress.country === "India"}
                required
              />
              <input
                type="text"
                name="city"
                value={newAddress.city}
                onChange={handleInputChange}
                placeholder="City*"
                // readOnly={newAddress.country === "India"}
                required
              />
              <input
                type="text"
                name="area"
                value={newAddress.area}
                onChange={handleInputChange}
                placeholder="Area/Locality*"
                required
              />
               <input
                type="text"
                name="HouseFloorNo"
                value={newAddress.HouseFloorNo}
                onChange={handleInputChange}
                placeholder="House/Floor No.*"
                required
              />

            </div>

            <div className="addnewaddress-checkoutbuttons">
              {isEditing && (
                <button
                  type="button"
                  onClick={handleDelete}
                  className="delete-btn"
                >
                  Remove
                </button>
              )}
              <button type="submit" className="save-btn">
                {isEditing ? "Update" : "Add"}
              </button>
              {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Address;
