import React, { useEffect, useState } from "react";
import "../PrivacyPolicy/PrivacyPolicy";
import NewHeader from "../../Components/Header/NewHeader";
import Footer from "../Footer/Footer";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";

export default function Refund() {
  const { section } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
  }, []);

  return (
    <div>
      <section>
        <NewHeader />
      </section>
      <div className="policy">
        <section>
          <div
            className="policysec1 bg-black text-white h-[70vh] flex  justify-center items-center"
            id="refund1"
          >
            <h1 className="text-[clamp(1.5rem,5vw,12rem)] font-[Prata]">
              Refund & Cancellation Policy
            </h1>
          </div>
        </section>
        <div className="description1 w-[80vw] py-[8vh] m-auto flex flex-col gap-[3vh] text-justify">
          <div>
            <h4>
              At K R PET EYE LLP, we are committed to delivering exceptional
              products and services to our valued customers. We understand that
              sometimes, circumstances may require a cancellation or a refund.
              Our policy is designed to be clear, fair and easy to understand.
            </h4>
          </div>

          <div>
            <h4>SECTION 1: ORDER CANCELLATIONS</h4>

            <p>1.1 Cancellation Window:</p>
            <p>
              If you need to cancel your order, you have a 24-hour window from
              the time of purchase to do so.
            </p>
            <p>
              Cancellations can be initiated by reaching out to our dedicated
              Customer Care team at{" "}
              <a href="mailto:care@peteye.pet">care@peteye.pet</a> or by calling
              911-591-1111.
            </p>

            <p>1.2 General Terms for Cancellations:</p>
            <p>
              If you wish to cancel an Order, you should use the mechanisms
              available through the Peteye Service to do so. For purposes of the
              Policies and Terms, the date of cancellation is the date that a
              user cancels through the Peteye Service, regardless of any
              separate communications between users outside of the Peteye
              Service.
            </p>

            <p>1.3 Cancellations Beyond 24 Hours:</p>
            <p>
              Orders that are not cancelled within 24 hours are subject to our
              standard return and refund policy, which is detailed below.
            </p>
          </div>

          <div>
            <h4>SECTION 2: Damages and issues</h4>

            <p>
              Please inspect your order upon reception and contact us
              immediately if the item is defective, or damaged or if you receive
              the wrong item so that we can evaluate the issue and make it
              right.
            </p>

            <p>2.1 Return Policy:</p>
            <p>
              If you receive a product that is damaged, defective or does not
              meet your expectations, please notify our Customer Care team
              within 7 days of receiving the item to initiate a return request.
            </p>
          </div>

          <div>
            <h4>SECTION 3: REFUNDS</h4>

            <p>3.1 Refund Process for Order Cancellations:</p>
            <p>
              When you cancel an order within the stipulated time frame, we will
              expedite a full refund to the original payment method used for
              your purchase. Expect to see the refund reflected in your account
              within 5 business days.
            </p>

            <p>3.2 Refund Timeline for Returns:</p>
            <p>
              Once your return request is approved, we will provide clear
              instructions on how to return the product to us.
            </p>
            <p>
              Upon receiving and inspecting the returned item, we will promptly
              process a refund to your original payment method within 10
              business days.
            </p>
          </div>

          <div>
            <h4>SECTION 4: Exchanges</h4>

            <p>
              The fastest way to ensure you get what you want is to return the
              item you have, and once the return is accepted, make a separate
              purchase for the new item.
            </p>
          </div>

          <div>
            <h4>SECTION 5: EXCEPTIONS</h4>

            <p>
              Please keep in mind that, certain situations are exceptions to our
              refund and cancellation policy:
            </p>

            <p>5.1 Customized or Personalized Products:</p>
            <p>
              Cancellations and returns are not accepted for customized or
              personalized items unless they are received in a damaged or
              defective condition.
            </p>

            <p>5.2 Perishable Goods:</p>
            <p>
              For hygiene and safety reasons, we cannot accept returns for
              perishable items, including pet food and treats, unless they are
              damaged or defective.
            </p>

            <p>5.3 Hygiene and Safety Products:</p>
            <p>
              Certain products, such as pet grooming tools, litter boxes, and
              pet carriers, cannot be returned once opened due to hygiene and
              safety considerations unless they are damaged or defective.
            </p>

            <p>5.4 Force Majeure:</p>
            <p>
              The cancellation policies described herein may not apply in the
              event of certain emergencies beyond the control of Peteye that
              make it impossible or impractical to perform agreed Bookings, such
              as evacuations resulting from wildfire, flood, earthquake,
              hurricane, war, riots or other similar disaster. In such cases,
              Peteye may, at its reasonable discretion, issue refunds.
            </p>
          </div>

          <div>
            <h4>CONTACT US</h4>

            <p>
              If you have any inquiries, require assistance with cancellations
              or returns or simply want to chat about your beloved pet, our
              dedicated Customer Care team is here to help. Reach out to us at{" "}
              <a href="mailto:care@peteye.pet">care@peteye.pet</a> or call us at
              +919704456520.
            </p>

            <p>
              At K R PET EYE LLP, we are committed to excellence and we reserve
              the right to modify or update this refund and cancellation policy
              as needed. We encourage you to revisit this policy periodically to
              stay informed about any changes.
            </p>

            <p>
              Thank you for choosing K R PET EYE LLP for your pet care needs.
              Your satisfaction and your pet's happiness are our top priorities.
            </p>
          </div>
        </div>
      </div>
      <section>
        <Footer />
      </section>
    </div>
  );
}
