import React, { useState, useEffect } from "react";
import axios from "../../../../axios";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import "./Settingsmodal.css";
import Cookies from "js-cookie";
import CustomDropdown from "../../../../Components/CustomDropdown/CustomDropdown";
import Checkout from "../../../../shop/components/CartWishlist/Checkout/Checkout";

export const RabiesVaccination = ({
  petId,
  isOpen,
  isClose,
  vaccinated,
  petType,
  onVaccinationDataChange,
  isFromMedicalRecord = false,
}) => {
  console.log(petType, "pet type");
  console.log(onVaccinationDataChange, "vaccinated");
  const loginToken = Cookies.get("loginToken");
  const signupToken = Cookies.get("signupToken");

  const [isClosing, setIsClosing] = useState(false);
  const [vaccinationName, setVaccinationName] = useState({
    Name: "Anti Rabies",
  });
  // console.log(petId.id, "petid", petId, "onemore");

  const [vaccinationDate, setVaccinationDate] = useState("");
  const [vaccinationDose, setVaccinationDose] = useState("");
  const [nextVacciDate, setNextVacciDate] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedVaccine, setSelectedVaccine] = useState(null);
  const [vaccineOptions, setVaccineOptions] = useState([]);
  const [vaccinationList, setVaccinationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [nextdoseType, setNextDoseType] = useState("");
  const [nextVaccinationName, setNextVaccinationName] = useState("");
  const [openCheckOut, setOpenCheckOut] = useState(false);
  const [bookingData, setBookingData] = useState({});
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [selected, setSelected] = useState("");
  console.log(selected, "vaccinationname");

  const [errors, setErrors] = useState({
    vaccinationName: "",
    vaccinationDate: "",
    vaccinationDose: "",
    nextVacciDate: "",
    uploadedFile: "",
    selectedVaccine: "",
    vaccinationList: "",
  });
  const getPetTypeForAPI = (type) => {
    if (type === "Dog or Canids") return "Dog";
    if (type === "Cat or Feline") return "Cat";
    return "Other";
  };
  console.log(vaccinated, "petid for vaccine ");

  useEffect(() => {
    if (
      (petType === "Dog or Canids" || petType === "Cat or Feline") &&
      !vaccinated
    ) {
      fetchVaccineOptions();
    } else if (petType !== "Dog or Canids" || petType !== "Cat or Feline") {
      fetchVaccinationList();
    }
  }, [petType, vaccinated, vaccinationDose, selected]);

  useEffect(() => {
    if (vaccinationName && vaccinationDose) {
      fetchNextVaccination();
    }
  }, [vaccinationName, vaccinationDose]);

  useEffect(() => {
    fetchVaccinationName();
    fetchVaccineOptions();
  }, [petType, selected]);

  // const fetchVaccineOptions = async () => {
  //   const vaccineName = isFromMedicalRecord ? selected : "Anti-Rabies";
  //   if (vaccineName) {
  //     setIsLoading(true);
  //     setError(null);
  //     console.log(vaccineName, "vaccinationname");
  //     try {
  //       const response = await axios.get(
  //         `/auth/rabiesvaccinelist?Subcategory=${vaccineName}&Pettype=${getPetTypeForAPI(
  //           petType
  //         )}`,
  //         {
  //           headers: {
  //             Authorization: signupToken || loginToken,
  //           },
  //         }
  //       );
  //       console.log("Vaccine options:", response.data.message);
  //       if (response.data.status === 200) {
  //         const message = response?.data?.message;
  //         if (Array.isArray(message) && message.length === 0) {
  //           throw new Error("No vaccine options available");
  //         }
  //         const formattedOptions = message.map((vaccine) => ({
  //           name: vaccine.ProductName,
  //           price: vaccine.price,
  //           productId: vaccine.id,
  //           variantId: vaccine.variantId,
  //         }));
  //         setVaccineOptions(formattedOptions);
  //       } else {
  //         throw new Error("Failed to fetch vaccine options");
  //       }
  //     } catch (err) {
  //       console.error("Error fetching vaccine options:", err);
  //       setError(
  //         err.message === "No vaccine options available"
  //           ? "No vaccine available ."
  //           : "Failed to fetch vaccine options. Please try again."
  //       );
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  // };

  const fetchVaccineOptions = async () => {
    // Only fetch vaccine options for dogs and cats
    if (petType === "Dog or Canids" || petType === "Cat or Feline") {
      const vaccineName = isFromMedicalRecord ? selected : "Anti-Rabies";
      if (vaccineName) {
        setIsLoading(true);
        setError(null);
        console.log(vaccineName, "vaccinationname");
        try {
          const response = await axios.get(
            `/auth/rabiesvaccinelist?Subcategory=${vaccineName}&Pettype=${getPetTypeForAPI(
              petType
            )}`,
            {
              headers: {
                Authorization: signupToken || loginToken,
              },
            }
          );
          console.log("Vaccine options:", response.data.message);
          if (response.data.status === 200) {
            const message = response?.data?.message;
            if (Array.isArray(message) && message.length === 0) {
              throw new Error("No vaccine options available");
            }
            const formattedOptions = message.map((vaccine) => ({
              name: vaccine.ProductName,
              price: vaccine.price,
              productId: vaccine.id,
              variantId: vaccine.variantId,
            }));
            setVaccineOptions(formattedOptions);
          }
        } catch (err) {
          console.error("Error fetching vaccine options:", err);
          setError(
            err.message === "No vaccine options available"
              ? "No vaccine available."
              : "Failed to fetch vaccine options. Please try again."
          );
        } finally {
          setIsLoading(false);
        }
      }
    }
  };
  const fetchVaccinationList = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const selectedPet = getPetTypeForAPI(petType);
      const response = await axios.get(
        `/petinfo/vaccinationname/${selectedPet}`,
        {
          headers: {
            Authorization: signupToken || loginToken,
          },
        }
      );
      setVaccinationList(response.data.message);
    } catch (err) {
      console.error("Error fetching vaccination list:", err);
      setError("Failed to fetch vaccination list. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchNextVaccination = async () => {
    if (vaccinationName) {
      const val = {
        currentvaccination: vaccinationName.Name,
        petType: getPetTypeForAPI(petType),
        currentdoseType: vaccinationDose,
      };
      try {
        const response = await axios.post("/admin/getmobilevaccination", val, {
          headers: {
            Authorization: signupToken || loginToken,
          },
        });
        if (response.data.message) {
          setNextVaccinationName(response.data.message.nextvaccination);
          setNextDoseType(response.data.message.nextdoseType);
          const nextDate = new Date(vaccinationDate);
          nextDate.setDate(
            nextDate.getDate() + parseInt(response.data.message.timeDuration)
          );
          setNextVacciDate(nextDate);
        }
      } catch (error) {
        console.error("Error fetching next vaccination:", error);
      }
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000);
  };

  const handleCheckoutOpen = () => {
    setOpenCheckOut((prev) => !prev);
  };

  const handleVaccinationDate = (e) => {
    setVaccinationDate(e.target.value);
  };

  const handleVaccinationDose = (e) => {
    setVaccinationDose(e.target.value);
    setSelectedVaccine(null);
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axios.post("/file/uploadFile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: signupToken || loginToken,
          },
        });

        if (!response.data.Message) {
          return setErrors({
            ...errors,
            uploadedFile: "Failed to upload file. Please try again.",
          });
        }

        setUploadedFile({
          url: response?.data?.Message?.getPeteye,
          fileType: file.type,
          docType: "Vaccination",
          docName: file.name,
        });
      } catch (error) {
        console.error("Error uploading file:", error);
        setError("Failed to upload file. Please try again.");
      }
    }
  };

  const handleVaccineChange = (option) => {
    setSelectedVaccine(option);
  };

  const handleSave = () => {
    const apiPetType = getPetTypeForAPI(petType);
    let vaccinationData = {};

    const formatDate = (date) => {
      if (date instanceof Date && !isNaN(date)) {
        return date.toISOString().split("T")[0];
      }
      console.warn("Invalid date:", date);
      return "";
    };

    try {
      if (apiPetType === "Dog" || apiPetType === "Cat") {
        if (vaccinated) {
          vaccinationData = {
            Vaccination: [
              {
                currentvaccination: "Anti-Rabies",
                LastVaccinationDate: vaccinationDate,
                nextvaccinationname: nextVaccinationName,
                nextvaccinationDate: formatDate(nextVacciDate),
                lastdoseType: vaccinationDose,
                nextdoseType: nextdoseType,
                documentList: uploadedFile ? [uploadedFile] : [],
              },
            ],
            nonvaccination: "",
          };
        } else {
          vaccinationData = {
            Vaccination: [],
            nonvaccination: {
              selectedVaccination: "Anti-Rabies",
              selectedDoseType: vaccinationDose,
              selectedDate: vaccinationDate,
              selectedVaccineId: selectedVaccine ? selectedVaccine.id : "",
            },
          };
        }
      } else {
        if (vaccinated) {
          vaccinationData = {
            Vaccination: [
              {
                currentvaccination: vaccinationName.Name,
                LastVaccinationDate: vaccinationDate,
                nextvaccinationname: nextVaccinationName,
                nextvaccinationDate: formatDate(nextVacciDate),
                lastdoseType: vaccinationDose,
                nextdoseType: nextdoseType,
                documentList: uploadedFile ? [uploadedFile] : [],
              },
            ],
            nonvaccination: "",
          };
        } else {
          vaccinationData = {
            Vaccination: [],
            nonvaccination: vaccinationDate,
          };
        }
      }

      console.log("vaccinationData from rabies vaccination", vaccinationData);
      onVaccinationDataChange(vaccinationData);
      handleClose();
    } catch (error) {
      console.error("Error in handleSave:", error);
      // You might want to show an error message to the user here
    }
  };

  const fetchVaccinationName = async () => {
    try {
      const response = await axios.get(
        `/petinfo/availablevaccines/${getPetTypeForAPI(petType)}`,
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );
      console.log("Vaccination Name:", response.data.message);
      if (response.data.message) {
        setVaccinationName(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching vaccination name:", error);
    }
  };
  console.log(selectedVaccine, "selected vaccine");

  const handleMedicalRecordSave = async () => {
    const apiPetType = getPetTypeForAPI(petType);
    let vaccinationData = {};
    let bookingData = {};

    const formatDate = (date) => {
      if (date instanceof Date && !isNaN(date)) {
        return date.toISOString().split("T")[0];
      }
      console.warn("Invalid date:", date);
      return "";
    };

    const formatDocuments = (documents) => {
      if (!documents) return [];
      if (Array.isArray(documents)) {
        return documents.map((doc) => ({
          petId: petId.id,
          docType: doc.docType || "Vaccination Certificate",
          fileType: doc.fileType || "PDF",
          url: doc.url,
        }));
      }
      // If single document, wrap in array
      return [
        {
          petId: petId.id,
          docType: documents.docType || "Vaccination Certificate",
          fileType: documents.fileType || "PDF",
          url: documents.url,
        },
      ];
    };

    try {
      if (vaccinated) {
        // Prepare vaccination data
        vaccinationData = {
          Vaccination: [
            {
              petId: petId.id,
              currentvaccination:
                apiPetType === "Dog" || apiPetType === "Cat"
                  ? "Anti-Rabies"
                  : vaccinationName.Name,
              LastVaccinationDate: vaccinationDate,
              NoOfDoses:
                vaccinationDose === "Initial Dose"
                  ? 1
                  : vaccinationDose === "Booster Dose"
                  ? 2
                  : 3,
              nextvaccinationname: nextVaccinationName,
              nextvaccinationDate: formatDate(nextVacciDate),
              lastdoseType: vaccinationDose,
              nextdoseType: nextdoseType,
              documentList: formatDocuments(uploadedFile),
            },
          ],
          nonvaccination: "",
        };

        // Call vaccination API
        const response = await axios.post(
          "/petinfo/vaccination/add",
          vaccinationData,
          {
            headers: {
              Authorization: signupToken || loginToken,
            },
          }
        );

        if (response.data.status === 200) {
          onVaccinationDataChange(vaccinationData);
          console.log("Medical record saved successfully", response.data);
          handleClose();
        } else {
          throw new Error("Failed to save medical record");
        }
      } else {
        // Prepare vaccination booking data
        bookingData = {
          petId: petId.id,
          nextDoseType: vaccinationDose,
          nextvaccinationname: selected,
          productId: selectedVaccine.productId, // Ensure `productId` is defined
          variantId: selectedVaccine.variantId, // Ensure `variantId` is defined
          selectedDate: vaccinationDate,
        };
        console.log("bookingData", bookingData);

        // Call vaccination booking API
        const response = await axios.post(
          "/petinfo/vaccinationbooking",
          bookingData,
          {
            headers: {
              Authorization: signupToken || loginToken,
            },
          }
        );

        console.log(response.data);

        if (response.data.status === 200) {
          const bookingId = response.data.data;
          console.log("Booking ID:", bookingId.id);
          console.log("Vaccination booking saved successfully", response.data);
          setBookingData(bookingId);
          setOpenCheckOut(true);
          // handleClose();
        }
        console.log(
          "Vaccination booking saved successfully",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error in handleMedicalRecordSave:", error);
      setError("Failed to save data. Please try again.");
    }
  };

  const handleSaveWrapper = () => {
    if (isFromMedicalRecord) {
      handleMedicalRecordSave();
      console.log("isFromMedicalRecord", isFromMedicalRecord);
    } else {
      handleSave();
    }
  };
  if (!isOpen) return null;
  

  console.log(isFromMedicalRecord, "isFromMedicalRecord");

  // Render the "Other" pet type form
  if (petType !== "Dog or Canids" && petType !== "Cat or Feline") {
    return (
      <div
        className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
        onClick={(e) => e.target === e.currentTarget && handleClose()}
      >
        <div className={`modal-container ${isClosing ? "close" : ""}`}>
          <div className="modal-top-header">
            <div className="modal-title">
              {isFromMedicalRecord && <p>Vaccination Details</p>}

              {!isFromMedicalRecord && <p>Rabbies Vaccination Details</p>}
            </div>
            <div className="modal-close" onClick={handleClose}>
              <DogPettingIcon
                name={"closemodal"}
                className="modal-close-icon"
              />
            </div>
          </div>
          <div className="setting-modal-description">
            <div className="rabbies-vaccination">
              <div className="form-group">
                <input
                  type="text"
                  value={vaccinationName}
                  onChange={(e) => setVaccinationName(e.target.value)}
                  placeholder="Vaccination Name"
                  className="form-group-select"
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  placeholder="Vaccination Date"
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) =>
                    (e.target.type = e.target.value ? "date" : "text")
                  }
                  value={vaccinationDate}
                  onChange={handleVaccinationDate}
                  aria-label="Vaccination Date"
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  value={vaccinationDose}
                  onChange={(e) => setVaccinationDose(e.target.value)}
                  placeholder="Vaccination Dose"
                  className="form-group-select"
                />
              </div>

              <div className="adddocument-pdf">
                {uploadedFile ? (
                  <>
                    <p>{uploadedFile.docType}</p>
                    <label htmlFor="file-input-form">
                      <span style={{ cursor: "pointer" }}>
                        <DogPettingIcon name="attachment" />
                      </span>
                    </label>
                  </>
                ) : (
                  <>
                    <p>Add Document</p>
                    <input
                      type="file"
                      accept="application/pdf"
                      onChange={handleFileUpload}
                      style={{ display: "none" }}
                      id="file-input-form"
                    />
                    <label htmlFor="file-input-form">
                      <span style={{ cursor: "pointer" }}>
                        <DogPettingIcon name="attachment" />
                      </span>
                    </label>
                  </>
                )}
              </div>

              {error && <div className="error-message">{error}</div>}

              <div className="logout-container-control">
                <button className="logout-btn" onClick={handleSave}>
                  <DogPettingIcon name={"accept"} />
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
      onClick={(e) => e.target === e.currentTarget && handleClose()}
    >
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        <div className="modal-top-header">
          <div className="modal-title">
            <p>
              {isFromMedicalRecord && <p>Vaccination Details</p>}

              {!isFromMedicalRecord && <p>Rabbies Vaccination Details</p>}
            </p>
          </div>
          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
        </div>
        <div className="setting-modal-description">
          <div className="rabbies-vaccination">
            {petType === "Dog or Canids" || petType === "Cat or Feline" ? (
              <>
                {isFromMedicalRecord ? (
                  <div className="w-[25vw] ">
                    <div className="relative">
                      <button
                        onClick={() => setIsOpenDropdown(!isOpenDropdown)}
                        className="w-full h-[9vh] px-[1vw] py-[.5vw] text-left text-gray-600 bg-[#F5F5F5] rounded-[1vw] focus:outline-none transition-all duration-300 hover:bg-gray-100"
                        disabled={isLoading}
                      >
                        <div className="flex items-center justify-between">
                          <span className="text-gray-500">
                            {selected || "Select vaccination"}
                          </span>
                          <svg
                            className={`w-[1.5vw] h-[3vh] transition-transform duration-300 ${
                              isOpenDropdown ? "rotate-180" : ""
                            }`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        </div>
                      </button>

                      {isOpenDropdown && vaccinationName.length > 0 && (
                        <div className="w-full bg-[#F5F5F5] rounded-[1vw] shadow-lg mt-[0.5vh] max-h-[32vh] overflow-auto no-scrollbar">
                          {vaccinationName.map((vaccination) => (
                            <label
                              key={vaccination.Name}
                              className="flex items-center justify-between px-[1vw] py-[.5vw] cursor-pointer hover:bg-gray-50 transition-colors duration-200"
                            >
                              <span className="ml-2 text-gray-700">
                                {vaccination.Name}
                              </span>
                              <input
                                type="radio"
                                name="vaccination"
                                value={vaccination.Name}
                                checked={selected === vaccination.Name}
                                onChange={() => {
                                  setSelected(vaccination.Name);
                                  setIsOpenDropdown(false);
                                }}
                                disabled={isLoading}
                                className="w-[1vw] h-[1vw] text-blue-600 border-gray-300 focus:ring-blue-500"
                              />
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="form-group">
                    <input
                      type="text"
                      value={vaccinationName.Name}
                      onChange={(e) =>
                        setVaccinationName({ Name: e.target.value })
                      }
                      aria-label="Anti Rabies Vaccination"
                      placeholder="Anti Rabies Vaccination"
                      className="form-group-select"
                      readOnly
                    />
                  </div>
                )}
                {vaccinated ? (
                  <>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Vaccination Date"
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) =>
                          (e.target.type = e.target.value ? "date" : "text")
                        }
                        value={vaccinationDate}
                        onChange={handleVaccinationDate}
                        aria-label="Vaccination Date"
                      />
                    </div>

                    <div className="form-group">
                      <select
                        value={vaccinationDose}
                        onChange={handleVaccinationDose}
                        aria-label="Vaccination Dose"
                        className="form-group-select"
                      >
                        <option value="">Vaccination Dose</option>
                        <option value="Initial Dose">Initial Dose</option>
                        <option value="Booster Dose">Booster Dose</option>
                        <option value="Annual-Re-Vaccination">
                          Annual Re-Vaccination
                        </option>
                      </select>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="form-group">
                      <select
                        value={vaccinationDose}
                        onChange={handleVaccinationDose}
                        aria-label="Vaccination Dose"
                        className="form-group-select"
                      >
                        <option value="">Vaccination Dose</option>
                        <option value="Initial">Initial</option>
                        <option value="Booster">Booster</option>
                        <option value="Annual">Annual</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Select Date for Vaccination"
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) =>
                          (e.target.type = e.target.value ? "date" : "text")
                        }
                        value={vaccinationDate}
                        onChange={handleVaccinationDate}
                        aria-label="Select Date for Vaccination"
                      />
                    </div>

                    {isLoading ? (
                      <div className="form-group">
                        <span className="loading-text">
                          Fetching vaccine options
                        </span>
                      </div>
                    ) : error ? (
                      <div className="form-group">
                        <p className="error-text">{error}</p>
                      </div>
                    ) : (
                      <CustomDropdown
                        options={vaccineOptions}
                        value={selectedVaccine ? selectedVaccine.name : ""}
                        onChange={handleVaccineChange}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {vaccinated ? (
                  <>
                    <div className="form-group">
                      <select
                        value={vaccinationName}
                        onChange={(e) => setVaccinationName(e.target.value)}
                        aria-label="Vaccination Name"
                        className="form-group-select"
                      >
                        <option value="">Last Vaccination Name</option>
                        {vaccinationList.map((vaccine) => (
                          <option key={vaccine.Id} value={vaccine.Name}>
                            {vaccine.Name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Vaccination Date"
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) =>
                          (e.target.type = e.target.value ? "date" : "text")
                        }
                        value={vaccinationDate}
                        onChange={handleVaccinationDate}
                        aria-label="Last Vaccination Date"
                      />
                    </div>

                    <div className="form-group">
                      <select
                        value={vaccinationDose}
                        onChange={handleVaccinationDose}
                        aria-label="Vaccination Dose"
                        className="form-group-select"
                      >
                        <option value="">Last Vaccination Dose</option>
                        <option value="Initial Dose">Initial Dose</option>
                        <option value="Booster Dose">Booster Dose</option>
                        <option value="Annual-Re-Vaccination">
                          Annual Re-Vaccination
                        </option>
                      </select>
                    </div>
                  </>
                ) : (
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Select Date for Vaccination"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) =>
                        (e.target.type = e.target.value ? "date" : "text")
                      }
                      value={vaccinationDate}
                      onChange={handleVaccinationDate}
                      aria-label="Select Date for Vaccination"
                    />
                  </div>
                )}
              </>
            )}
            {(petType === "Dog or Canids" || petType === "Cat or Feline") &&
              vaccinated && (
                <div className="adddocument-pdf">
                  {uploadedFile ? (
                    <>
                      <p>{uploadedFile.docType}</p>
                      {/* <p>{uploadedFile.docName}</p> */}

                      <label htmlFor="file-input-form">
                        <span style={{ cursor: "pointer" }}>
                          <DogPettingIcon name="attachment" />
                        </span>
                      </label>
                    </>
                  ) : (
                    <>
                      <p>Add Document</p>
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={handleFileUpload}
                        multiple
                        style={{ display: "none" }}
                        id="file-input-form"
                      />
                      <label htmlFor="file-input-form">
                        <span style={{ cursor: "pointer" }}>
                          <DogPettingIcon name="attachment" />
                        </span>
                      </label>
                    </>
                  )}
                </div>
              )}
            <div className="logout-container-control">
              <button
                className="logout-btn"
                onClick={handleSaveWrapper}
                disabled={isLoading}
              >
                <DogPettingIcon name={"accept"} />
                {(petType === "Dog or Canids" || petType === "Cat or Feline") &&
                !vaccinated
                  ? "Book"
                  : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {openCheckOut && (
        <Checkout
          isOpen={openCheckOut}
          isClose={handleCheckoutOpen}
          bookingData={bookingData}
          bookingVaccine={true}
          petId={petId}
        />
      )}
    </div>
  );
};
