import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "./IdentificationMarks.css";
import DogPettingIcon from "../../../../Homepage/DogPettingIcon";
import axios from "../../../../../axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";

export default function IdentificationMarks({ isOpen, onClose, petId }) {
  const [isEditing, setIsEditing] = useState(false);
  const [identity1, setIdentity1] = useState("");
  const [identity2, setIdentity2] = useState("");
  const [isClosing, setIsClosing] = useState(false);
  const [error, setError] = useState("");
  const loginToken = Cookies.get("loginToken");
  const { login } = useSelector((state) => state);
  const petInfo = login.details.petInfo;
  const [generalError, setGeneralError] = useState({
    identificationMark1: "",
    identificationMark2: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (petId) {
      setIdentity1(petId.identificationMark || "");
      setIdentity2(petId.identificationMark1 || "");
    }
  }, [petId]);

  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 1000); // Match the duration of the closing animation
  };

  const handleEditSaveClick = async () => {
    setIsEditing(!isEditing);
    if (!identity1) {
      setGeneralError({
        identificationMark1: "Identification mark is required.",
      });
      setTimeout(
        () => setGeneralError((prev) => ({ ...prev, identificationMark1: "" })),
        3000
      );
      return;
    }

    if (!identity2) {
      setGeneralError({
        identificationMark2: "Identification mark is required.",
      });
      setTimeout(
        () => setGeneralError((prev) => ({ ...prev, identificationMark2: "" })),
        3000
      );
      return;
    }

    if (isEditing) {
      try {
        const response = await axios.patch(
          `/petinfo/updateidentificationmarks/${petId.id}`,
          {
            identificationMark: identity1,
            identificationMark1: identity2,
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        );
        dispatch({ type: "refresh" });
        console.log("Successfully updated", response);
      } catch (error) {
        console.error("Error updating fields:", error);
      }
    }
  };
  const validateInput = (value) => {
    const regex = /^[a-zA-Z0-9\s]*$/; // Only allows alphanumeric characters and spaces
    return regex.test(value);
  };
  const handleIdentity1Change = (e) => {
    const value = e.target.value;
    if (value.length > 50) {
      setGeneralError({
        identificationMark1: "Maximum length is 50 characters.",
      });

      setTimeout(
        () => setGeneralError((prev) => ({ ...prev, identificationMark1: "" })),
        3000
      );
      return false; // Stop further processing if length exceeds 50
    }
    if (validateInput(value)) {
      setIdentity1(value);
      setTimeout(
        () => setGeneralError((prev) => ({ ...prev, identificationMark1: "" })),
        3000
      );
    } else {
      setGeneralError({
        identificationMark1: "Special characters are not allowed.",
      }); // Show error for invalid input
      setTimeout(
        () => setGeneralError((prev) => ({ ...prev, identificationMark1: "" })),
        3000
      );
    }
  };

  const handleIdentity2Change = (e) => {
    const value = e.target.value;
    if (value.length > 50) {
      setGeneralError({
        identificationMark2: "Maximum length is 50 characters.",
      });
      setTimeout(
        () => setGeneralError((prev) => ({ ...prev, identificationMark2: "" })),
        3000
      );
      return false; // Stop further processing if length exceeds 50
    }
    if (validateInput(value)) {
      setIdentity2(value);
      setError(""); // Clear error if input is valid
      setTimeout(
        () => setGeneralError((prev) => ({ ...prev, identificationMark2: "" })),
        3000
      );
    } else {
      setGeneralError({
        identificationMark2: "Special characters are not allowed.",
      });
      setTimeout(
        () => setGeneralError((prev) => ({ ...prev, identificationMark2: "" })),
        3000
      );
    }
  };

  return (
    <div
      className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        <div className="identification-container">
          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
          <div className="identification-title">
            <DogPettingIcon name={"dog-paw"} />
            <h2>Identification Marks</h2>
          </div>
          <div className="identification-input-group">
            <div>
              <input
                type="text"
                placeholder="Please add pet identity 1"
                value={identity1}
                onChange={handleIdentity1Change}
                disabled={!isEditing}
                className={`${
                  generalError["identificationMark1"]
                    ? "border border-[#ff0000]"
                    : ""
                }`}
              />
              {generalError["identificationMark1"] && (
                <p className="text-[#FF0000] text-[clamp(0.5rem,.9vw,3rem)] mt-[1vh] leading-none text-start">
                  {generalError["identificationMark1"]}
                </p>
              )}
            </div>
            <div>
              <input
                type="text"
                placeholder="Please add pet identity 2"
                value={identity2}
                onChange={handleIdentity2Change}
                disabled={!isEditing}
                className={`${
                  generalError["identificationMark2"]
                    ? "border border-[#ff0000]"
                    : ""
                }`}
              />
              {generalError["identificationMark2"] && (
                <p className="text-[#FF0000] text-[clamp(0.5rem,.9vw,3rem)] mt-[1vh] leading-none text-start">
                  {generalError["identificationMark2"]}
                </p>
              )}
            </div>
          </div>
          <button
            className="identification-button"
            onClick={handleEditSaveClick}
          >
            <DogPettingIcon
              name={isEditing ? "check" : "pencil-edit"}
              className="identification-edit-icon"
            />
            <p>{isEditing ? "Save" : "Edit"}</p>
          </button>
        </div>
      </div>
    </div>
  );
}
