import React, { useState, useEffect } from "react";
import "./ConfirmVerification.css";
import VerificationOTP from "../VerificationOTP/VerificationOTP";
import { useSelector } from "react-redux";
import AlertBox from "../../../../Components/Alert/Alert";
import axios from "../../../../axios";
import Cookies from "js-cookie";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import { useLocation } from "react-router-dom";

function ConfirmVerification({}) {
  const { login } = useSelector((state) => state);
  const [openVerifnOTP, setopenVerifnOTP] = useState(false);
  const [openAlert, setopenAlert] = useState(false);
  const [open, setOpen] = useState(true);
  const [errMessage, setErrMessage] = useState("");
  const [selectedMethod, setSelectedMethod] = useState("");
  const signupToken = Cookies.get("signupToken");
  const loginToken = Cookies.get("loginToken");

  const userInfo = login?.details?.userInfo || {};
  const { email, mobile } = userInfo;

  console.log(
    `email: ${email}, mobile: ${mobile}, selectedMethod: ${selectedMethod}`
  );

  const location = useLocation();

  const isLoginRoute = location.pathname === "/login";
  const paddingTopStyle = isLoginRoute ? "10vh" : "0";

  // Set default selection if only one method is available
  useEffect(() => {
    if (email && !mobile) setSelectedMethod("email");
    else if (mobile && !email) setSelectedMethod("mobile");
  }, [email, mobile]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!selectedMethod) {
      setopenAlert(!openAlert);
      setErrMessage("Please select a verification method.");
      return;
    }

    const apiEndpoint =
      selectedMethod === "email" ? "/auth/sendOtpEmail" : "/auth/sendOtpMobile";

    axios
      .get(apiEndpoint, {
        headers: {
          Authorization: signupToken || loginToken,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          setopenVerifnOTP(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {openVerifnOTP ? (
        <VerificationOTP
          status={"confirmVf"}
          value={selectedMethod === "email" ? email : mobile}
        />
      ) : (
        <>
          <div
            className="confm-vf-div "
            style={{ paddingTop: paddingTopStyle }}
          >
            {isLoginRoute && <h3>Verification Details</h3>}
            <p>
              To help keep your account safe, We want to make sure it's really
              you trying to sign in.
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="confm-vf-input-div flex items-center justify-center flex-col gap-[2vh]">
              {email && (
                <div
                  onClick={() => setSelectedMethod("email")}
                  style={{
                    borderRadius: "8px",
                    padding: "10px",
                    cursor: "pointer",
                    transition: "border 0.3s",
                  }}
                >
                  <div className="bg-[#EEEEEE] p-[.8vw] rounded-[.8vw] w-[4vw] m-auto relative">
                    <p className="">
                      <DogPettingIcon name="email" />
                    </p>
                    {selectedMethod === "email" && (
                      <div className="absolute top-0 right-0">
                        <DogPettingIcon name={"tick"} />
                      </div>
                    )}
                  </div>

                  <input
                    type="text"
                    className="confm-vf-input text-center"
                    value={email}
                    readOnly
                    style={{
                      fontSize: "clamp(1rem, 1.2vw, 4rem)",
                      fontWeight: "500",
                      width: "100%",
                    }}
                  />
                </div>
              )}
              {mobile && (
                <div
                  onClick={() => setSelectedMethod("mobile")}
                  style={{
                    borderRadius: "8px",
                    padding: "10px",
                    cursor: "pointer",
                    transition: "border 0.3s",
                  }}
                >
                  <div className="bg-[#EEEEEE] p-[.8vw] rounded-[.8vw] w-[4vw] m-auto relative">
                    <p className="">
                      <DogPettingIcon name="mobile" />
                    </p>
                    {selectedMethod === "mobile" && (
                      <div className="absolute top-0 right-0">
                        <DogPettingIcon name={"tick"} />
                      </div>
                    )}
                  </div>
                  <input
                    type="text"
                    className="confm-vf-input text-center"
                    value={`+91 ${mobile}`}
                    readOnly
                    style={{
                      fontSize: "clamp(1rem, 1.2vw, 4rem)",
                      fontWeight: "500",
                      width: "100%",
                    }}
                  />
                </div>
              )}
            </div>
            <div className="confm-code-btn">
              <button type="submit">Get Code</button>
            </div>
          </form>
        </>
      )}
      {openAlert ? (
        <AlertBox
          open={true}
          setOpen={setOpen}
          setopenAlert={setopenAlert}
          errMessage={errMessage}
        />
      ) : null}
    </>
  );
}

export default ConfirmVerification;
