import React, { useEffect, useState } from "react";
import axios from "../../../../axios";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";

const VaccinationBookingCart = ({ bookingData }) => {
  const loginToken = Cookies.get("loginToken");
  const [vaccinationCart, setVaccinationCart] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [vaccinationBookingDiscount, setVaccinationBookingDiscount] = useState(0);
  const dispatch = useDispatch();

  const calculateTotalDiscount = (cartData) => {
    if (!cartData || !cartData.Product || !cartData.Product.productvariants?.[0]) {
      return 0;
    }
    const variant = cartData.Product.productvariants[0];
    const offerPercentage = variant.OfferPercentage || 0;
    // Calculate original price before discount
    const originalPrice = variant.price / (1 - offerPercentage / 100);
    // Calculate total discount
    const discount = originalPrice - variant.price;
    setVaccinationBookingDiscount(discount);
    return discount;
  };

  const fetchVaccinationCart = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`/petinfo/vaccination/booking/${bookingData.id}`, {
        headers: {
          Authorization: loginToken,
        },
      });
      const vaccinationCartData = response.data.data;
      setVaccinationCart(vaccinationCartData);
      // Calculate discount as soon as we get the data
      calculateTotalDiscount(vaccinationCartData);
      dispatch({ type: "refresh" });
      setIsLoading(false);
    } catch (err) {
      console.error("Error fetching vaccination cart:", err);
      setError(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVaccinationCart();
  }, [bookingData.id]);

  if (isLoading) {
    return <div className="text-center p-4">Loading...</div>;
  }

  if (error) {
    return <div className="text-center p-4 text-red-500">Error loading vaccination data</div>;
  }

  if (!vaccinationCart || !vaccinationCart.Product) {
    return <div className="text-center p-4">No vaccination data available</div>;
  }

  // Define offerPercentage here
  const offerPercentage = vaccinationCart.Product.productvariants?.[0]?.OfferPercentage || 0;

  return (
    <div>
      <div className="shop-cart-items">
        <div className="shop-cart-item-category">
          <div className="shop-cart-item">
            <div className="shop-cart-item-image">
              <img
                src={vaccinationCart.Product.productImages?.[0]?.url}
                alt={vaccinationCart.Product.ProductName}
              />
            </div>
            <div className="shop-cartitemdetails">
              <div>
                <h3>{vaccinationCart.Product.ProductName}</h3>
                <p>{vaccinationCart.Product.Description}</p>
              </div>
              <div className="shop-cart-item-price-quantity">
                <p className="shop-cart-item-weight">
                  {vaccinationCart.Product.productvariants?.[0]?.unit}
                </p>
              </div>
            </div>
            <div className="shop-cart-item-price">
              <h2>₹</h2>
              {offerPercentage > 0 && (
                <h6>{vaccinationCart.Product.productvariants?.[0]?.MRP}</h6>
              )}
              <p>{vaccinationCart.Product.productvariants?.[0]?.price}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VaccinationBookingCart;