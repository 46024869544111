import React, { useState, useEffect } from "react";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "../../../../axios";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";

function Login({ setShowTogglebtn }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Basic form states
  const [openForgotPass, setopenForgotPass] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [password, setpassword] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [errors, setErrors] = useState({
    emailOrPhone: "",
    password: "",
  });

  // Login attempt tracking states
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isPermanentlyBlocked, setIsPermanentlyBlocked] = useState(false);
  const [blockEndTime, setBlockEndTime] = useState(null);

  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + 6 * 60 * 60 * 1000);
  const validateEmailOrPhone = (value) => {
    // Email regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Phone regex (assuming 10 digits, modify as needed)
    const phoneRegex = /^\d{10}$/;

    return emailRegex.test(value) || phoneRegex.test(value);
  };

  const [blockTimeRemaining, setBlockTimeRemaining] = useState(null);

  // Add useEffect to handle block timer
  useEffect(() => {
    let timer;
    const blockedAccounts = JSON.parse(
      localStorage.getItem("blockedAccounts") || "{}"
    );
    const accountData = blockedAccounts[emailOrPhone];

    if (accountData?.endTime) {
      const updateTimer = () => {
        const endTime = new Date(accountData.endTime);
        const now = new Date();
        const timeLeft = endTime - now;

        if (timeLeft <= 0) {
          // Clear block when time is up
          delete blockedAccounts[emailOrPhone];
          localStorage.setItem(
            "blockedAccounts",
            JSON.stringify(blockedAccounts)
          );
          setBlockTimeRemaining(null);
          setErrMessage("");
          clearInterval(timer);
        } else {
          const minutes = Math.floor(timeLeft / (1000 * 60));
          const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
          setBlockTimeRemaining({ minutes, seconds });
          setErrMessage(
            `Your account is temporarily blocked. Please try again in ${minutes} minutes and ${seconds} seconds.`
          );
        }
      };

      // Initial update
      updateTimer();
      // Set interval for updates
      timer = setInterval(updateTimer, 1000);
    }

    // Cleanup timer on unmount or when email changes
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [emailOrPhone]); // Dependency on emailOrPhone to restart timer if email changes

  // Facebook initialization
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_APP_ID,
        cookie: true,
        xfbml: true,
        version: "v12.0",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);
  const handleLoginResponse = async (response) => {
    const { status, loginAttempts, message } = response.data;
    console.log(response.data, "handleLoginResponse");

    if (status === 200) {
      // Successful login - clear any blocking status
      const blockedAccounts = JSON.parse(
        localStorage.getItem("blockedAccounts") || "{}"
      );
      if (blockedAccounts[emailOrPhone]) {
        delete blockedAccounts[emailOrPhone];
        localStorage.setItem(
          "blockedAccounts",
          JSON.stringify(blockedAccounts)
        );
      }

      // Handle successful login
      Cookies.set("loginToken", response.data.message.userInfo.token, {
        expires: expirationDate,
      });
      dispatch({ type: "login", login: { ...response.data.message } });
      dispatch({
        type: "cart_details",
        cart_details: response.data.message.cartdetails,
      });
      dispatch({ type: "refresh" });
      navigate("/home");
      return;
    }

    const blockedAccounts = JSON.parse(
      localStorage.getItem("blockedAccounts") || "{}"
    );

    if (status === 401) {
      if (loginAttempts < 3) {
        setErrMessage(
          `Invalid credentials. ${3 - loginAttempts} attempts remaining.`
        );
        blockedAccounts[emailOrPhone] = {
          attempts: loginAttempts,
          lastAttempt: new Date().toISOString(),
        };
      } else if (loginAttempts >= 3 && loginAttempts <= 5) {
        const remainingAttempts = 6 - loginAttempts;
        setErrMessage(
          `Invalid credentials. ${remainingAttempts} attempts remaining before permanent block.`
        );
        blockedAccounts[emailOrPhone] = {
          attempts: loginAttempts,
          lastAttempt: new Date().toISOString(),
        };

        if (loginAttempts === 3) {
          // Set 30-minute block
          const blockEndTime = new Date(new Date().getTime() + 30 * 60000);
          blockedAccounts[emailOrPhone].endTime = blockEndTime.toISOString();
        }
      }
      localStorage.setItem("blockedAccounts", JSON.stringify(blockedAccounts));
    } else if (status === 403) {
      setErrMessage(
        message || "Your account is blocked. Please contact support."
      );
      blockedAccounts[emailOrPhone] = {
        permanentlyBlocked: true,
        lastAttempt: new Date().toISOString(),
      };
      localStorage.setItem("blockedAccounts", JSON.stringify(blockedAccounts));
    }
  };

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const res = await Axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );

        if (res.status === 200) {
          let data = {
            email: res?.data?.email,
            password: res?.data?.sub,
          };

          const loginResponse = await axios.post("/auth/login", { ...data });
          if (loginResponse.data.status === 200) {
            Cookies.set(
              "loginToken",
              loginResponse.data.message.userInfo.token,
              { expires: expirationDate }
            );
            dispatch({
              type: "login",
              login: { ...loginResponse.data.message },
            });
            dispatch({
              type: "cart_details",
              cart_details: loginResponse.data.message.cartdetails,
            });
            dispatch({ type: "refresh" });
            navigate("/home");
          } else {
            setErrMessage(loginResponse.data.message);
          }
        }
      } catch (error) {
        console.error(error);
        // handleLoginFailure();
      }
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({ emailOrPhone: "", password: "" });
    setErrMessage("");

    // Validation checks remain the same
    let hasErrors = false;
    const newErrors = { emailOrPhone: "", password: "" };

    if (emailOrPhone.trim() === "") {
      newErrors.emailOrPhone = "Email or Phone Number is required";
      hasErrors = true;
    } else if (!validateEmailOrPhone(emailOrPhone)) {
      newErrors.emailOrPhone = "Please enter a valid email or phone number";
      hasErrors = true;
    }

    if (password.trim() === "") {
      newErrors.password = "Password is required";
      hasErrors = true;
    }

    if (hasErrors) {
      setErrors(newErrors);
      return;
    }

    try {
      // Always attempt the login with the backend first
      const response = await axios.post("/auth/login", {
        email: emailOrPhone,
        password: password,
      });

      // Handle the response
      handleLoginResponse(response);
    } catch (error) {
      console.error(error);
      if (error.response) {
        // If there's a specific error from the backend, use that
        setErrMessage(
          error.response.data.message || "An error occurred. Please try again."
        );
      } else {
        setErrMessage("An error occurred. Please try again.");
      }
    }
  };

  return (
    <>
      {openForgotPass ? (
        <ForgotPassword />
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <div className="login-form">
              <div
                className={`relative inline-block ${
                  errors.emailOrPhone && "h-[9vh]"
                }`}
              >
                <input
                  placeholder="Email / Phone Number"
                  type="text" // Changed from type="email" to type="text"
                  value={emailOrPhone}
                  onChange={(e) => setEmailOrPhone(e.target.value)}
                  className={`textfield-inp ${
                    errors.emailOrPhone ? "border border-[#FF0000]" : ""
                  }`}
                />
                <span className="absolute right-[.5vw] top-[30%] translateY[-50%] pointer-events-none">
                  <DogPettingIcon name="login-mail" />
                </span>
                {errors.emailOrPhone && (
                  <p className="text-[#FF0000] text-[clamp(0.5rem,.9vw,3rem)] mt-1 mb-[1vh] leading-none">
                    {errors.emailOrPhone}
                  </p>
                )}
              </div>

              <div
                className={`relative inline-block ${
                  errors.password && "h-[9vh]"
                }`}
              >
                <div className="textfield-inp-wrapper">
                  <input
                    id="password"
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                    className={`textfield-inp ${
                      errors.password ? "border border-[#FF0000]" : ""
                    }`}
                  />
                  <span
                    className="password-icon"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <FaEye style={{ cursor: "pointer" }} />
                    ) : (
                      <FaEyeSlash style={{ cursor: "pointer" }} />
                    )}
                  </span>
                </div>
                {errors.password && (
                  <p className="text-[#FF0000] text-[clamp(0.5rem,.9vw,3rem)] mt-1 leading-none">
                    {errors.password}
                  </p>
                )}
                <p
                  onClick={() => {
                    setopenForgotPass(!openForgotPass);
                    setShowTogglebtn(false);
                  }}
                  className={`text-gray-500 cursor-pointer float-right text-[clamp(.5rem,1vw,4rem)] ${
                    errors.email || errors.password ? "-mt-[1vh]" : "mt-[1vh]"
                  } mt-[1vh] leading-none`}
                >
                  Forgot Password?
                </p>
              </div>

              {errMessage && (
                <p className="text-red-500 text-xs text-center mt-2">
                  {errMessage}
                </p>
              )}

              <button type="submit" className="login-button">
                Login
              </button>
            </div>
          </form>

          <div className="loginsocial">
            <hr className="line" /> &nbsp;
            <p className="text-gray-500">continue with</p> &ensp;
            <hr className="line" />
          </div>

          <div className="flex items-center justify-center gap-[1vw]">
            <p
              onClick={() => login()}
              className=" flex items-center justify-center shadow-[0px_0px_.5vw_0px_rgba(0,0,0,0.25)] rounded-[.5vw] p-[.3vw]"
            >
              <DogPettingIcon name="google" />
            </p>
            <p className="cursor-not-allowed flex items-center justify-center shadow-[0px_0px_.5vw_0px_rgba(0,0,0,0.25)] rounded-[.5vw] p-[.3vw]">
              <DogPettingIcon name="apple" />
            </p>
            <p
              className="cursor-not-allowed flex items-center justify-center shadow-[0px_0px_.5vw_0px_rgba(0,0,0,0.25)] rounded-[.5vw] p-[.3vw]"
              title="Passkeys Coming Soon"
            >
              <DogPettingIcon name="passkey" />
            </p>
          </div>
        </>
      )}
    </>
  );
}

export default Login;
