import React from "react";

const SkeletonLoader = () => {
  return (
    <div className="w-full">
      <div className="flex items-center space-x-[1vw]">
        {/* Skeleton for image/avatar */}
        <div className="w-[5vw] h-[10vh] bg-gray-300 rounded-[1.2vw] animate-pulse" />

        {/* Skeleton for text content */}
        <div className="flex-1">
          {/* Title skeleton */}
          <div className="h-[2vh] bg-gray-300 rounded-[.4vw] w-2/3 mb-2 animate-pulse" />
          {/* Subtitle skeleton */}
          <p className="text-[#31C07E] text-[clamp(.6rem,1.1vw,2.5rem)]">
            Connect Device
          </p>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
