import React, { useState, useEffect } from "react";
import "./Nfc.css";
import Homenav from "../../Components/HomeNav/Homenav";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { GiStopSign } from "react-icons/gi";
import { BsLink } from "react-icons/bs";
import DogPettingIcon from "../Homepage/DogPettingIcon";
import { ChoosePetModal } from "./ChoosePetModal";
import { ConnectPetModal } from "./ConnectPetModal";
import axios from "../../axios";
import Cookies from "js-cookie";
import { Map } from "./Map";
import { useSelector } from "react-redux";
import { ConnectedPetModal } from "./ConnectedPetModal";
import { Sessions } from "./Sessions";
import NewHeader from "../../Components/Header/NewHeader";
import LostFound from "./LostFound";
import LostCompanion from "./LostCompanion";
import DownloadAppScreen from "../../Website/DownloadAppScreen/DownloadAppScreen";

export default function Nfc() {
  const loginToken = Cookies.get("loginToken");
  const [connectorModal, setConnectorModal] = useState(false);
  const [choosePetModal, setChoosePetModal] = useState(false);
  const [petModal, setPetModal] = useState(false);
  const [qrCode, setqrCode] = useState("");
  const { login } = useSelector((state) => state);
  const [connectedPet, setConnectedPet] = useState([]);
  const [connectedPetProfile, setConnectedPetProfile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [switchToFound, setSwitchToFound] = useState(false);
  const [LostCompanionOpen, setLostCompanionOpen] = useState(false);
  const [selectedPet, setSelectedPet] = useState(null);
  const [saveLatLong, setSaveLatLong] = useState([
    {
      latitude: "",
      longitude: "",
    },
  ]);
  console.log(saveLatLong, "nfclatlong");

  if (login?.login) {
    const petInfo = login.details.petInfo;
    console.log(petInfo);
  }

  const toggleConnectorModal = () => {
    setConnectorModal((prev) => !prev);
  };

  const selectPetModal = () => {
    setChoosePetModal((prev) => !prev);
  };

  const openPetModal = (pet) => {
    setSelectedPet(pet);
    setPetModal(true);
    // setPetModal((prev) => !prev);
  };
  const handlePetClick = (e, pet) => {
    e.stopPropagation(); // Prevent click from bubbling up to parent div
    const petProfile = connectedPetProfile.find(
      (profile) => profile.petinfo.id === pet.id
    );
    openPetModal(petProfile);
  };

  const fetchConnectedPets = async () => {
    try {
      const response = await axios.get("/nfc/connectedpets", {
        headers: {
          Authorization: loginToken,
        },
      });
      const connectedPets = response?.data?.message;
      console.log("Connected pets:", connectedPets);
      console.log(response?.data?.message, "connected");

      if (!Array.isArray(connectedPets)) {
        console.error(
          "Expected connectedPets to be an array, but got:",
          connectedPets
        );
        return;
      }
      setConnectedPetProfile(connectedPets);
      setConnectedPet(connectedPets.map((pet) => pet.petinfo));
    } catch (error) {
      console.error("Error fetching connected pets:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConnectedPets();
  }, []);

  const updateConnectedPets = async () => {
    try {
      // Fetch updated connected pets
      await fetchConnectedPets();
    } catch (error) {
      console.error("Error updating connected pets:", error);
    }
  };
  const handleuserclose = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const handleLostCompanion = () => {
    setLostCompanionOpen((prev) => !prev);
  };

  console.log("Connected pets:", connectedPet);
  console.log("Connected pet profile:", connectedPetProfile);

  return (
    <div onClick={handleuserclose}>
      <section>
        <NewHeader />
      </section>
      <div className="hidden md:block">
        <section className="nfc-page-section">
          <section className="pet-connection-section">
            {login?.login && (
              <div className="connected-pets">
                <div
                  className=" flex flex-col items-center justify-center cursor-pointer gap-[.4vw]"
                  onClick={handleLostCompanion}
                >
                  <div className="flex flex-col items-center justify-center shadow-[0px_0px_.4vw_rgba(0,0,0,0.4)] w-[6vw] h-[11vh] rounded-[50%]">
                    <DogPettingIcon
                      name="taglink-lostcompanion-icon"
                      className="connector-icon "
                    />
                  </div>
                  <p className="text-[#929292] text-[clamp(.8rem,.8vw,2.8rem)]">
                    Lost & Found
                  </p>
                </div>
                <div
                  className=" flex flex-col items-center justify-center cursor-pointer gap-[.4vw]"
                  onClick={toggleConnectorModal}
                >
                  <div className="flex flex-col items-center justify-center shadow-[0px_0px_.4vw_rgba(0,0,0,0.4)] w-[6vw] h-[11vh] rounded-[50%] p-[15px]">
                    <DogPettingIcon
                      name="taglink-connectpet-icon"
                      className="connector-icon "
                    />
                  </div>
                  <p className="text-[#929292] text-[clamp(.8rem,.8vw,2.8rem)]">
                    Connect Device
                  </p>
                </div>
                <div className="">
                  {loading ? (
                    <div className="skeleton">
                      <div className="skeleton-circle"></div>
                      <div className="skeleton-text"></div>
                    </div>
                  ) : connectedPet.length > 0 ? (
                    connectedPet.map((pet) => (
                      <div
                        key={pet.id}
                        className="flex flex-col items-center justify-center gap-[.4vw] cursor-pointer"
                      >
                        <img
                          src={
                            pet.petProfileImage ||
                            "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/Home/Rectangle+3(4).png"
                          }
                          alt={pet.petName}
                          // Login
                          onClick={(e) => handlePetClick(e, pet)}
                          className="cursor-pointer rounded-[50%] h-[11vh] object-fill"
                        />
                        <p className="text-[#000] text-[clamp(.8rem,.8vw,2.8rem)] w-[5.5vw] text-center">
                          {pet.petName.length > 10
                            ? pet.petName.slice(0, 10) + ""
                            : pet.petName}
                        </p>
                      </div>
                    ))
                  ) : (
                    <div className="flex items-center space-x-[1vw]">
                      <div
                        className=" flex flex-col items-center justify-center cursor-pointer gap-[.4vw]"
                        onClick={toggleConnectorModal}
                      >
                        <div className="flex flex-col items-center justify-center shadow-[0px_0px_.4vw_rgba(0,0,0,0.4)] w-[6vw] h-[11vh] rounded-[50%] p-[15px]">
                          <DogPettingIcon name="addPet" />
                        </div>
                        <p className="text-[#929292] text-[clamp(.8rem,.8vw,2.8rem)]">
                          Add
                        </p>
                      </div>
                      <div
                        className=" flex flex-col items-center justify-center cursor-pointer gap-[.4vw]"
                        onClick={toggleConnectorModal}
                      >
                        <div className="flex flex-col items-center justify-center shadow-[0px_0px_.4vw_rgba(0,0,0,0.4)] w-[6vw] h-[11vh] rounded-[50%] p-[15px]">
                          <DogPettingIcon name="addPet" />
                        </div>
                        <p className="text-[#929292] text-[clamp(.8rem,.8vw,2.8rem)]">
                          Add
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {login?.login && LostCompanionOpen && (
              <LostCompanion
                isOpen={handleLostCompanion}
                isClose={handleLostCompanion}
              />
            )}
            {!login?.login && (
              <LostFound
                switchToFound={switchToFound}
                setSwitchToFound={setSwitchToFound}
              />
            )}
            {/* Live - Past Sessions */}
            <Sessions handleOpenConnectPet={toggleConnectorModal} />
          </section>

          <section className="pet-map-section">
            <Map
              switchToFound={switchToFound}
              setSwitchToFound={setSwitchToFound}
              savedLatLong={saveLatLong}
            />
          </section>
        </section>
      </div>
      <div className="block md:hidden">
        <DownloadAppScreen />
      </div>

      {/* Modals */}
      {login?.login && connectorModal && (
        <ConnectPetModal
          isClose={toggleConnectorModal}
          header={true}
          className={"looking"}
          headerText={"Looking for code"}
          selectPetModal={selectPetModal}
          setqrCode={setqrCode}
        />
      )}

      {login?.login && choosePetModal && (
        <ChoosePetModal
          isClose={selectPetModal}
          petInfo={login.details.petInfo}
          qrCode={qrCode}
          selectPetModal={selectPetModal}
          updateConnectedPets={updateConnectedPets}
          toggleConnectorModal={toggleConnectorModal}
        />
      )}

      {login?.login && petModal && selectedPet && (
        <ConnectedPetModal
          isClose={openPetModal}
          isOpen={true}
          className={"normal"}
          connectedPet={[selectedPet]}
          openPetModal={openPetModal}
          updateConnectedPets={updateConnectedPets}
        />
      )}
    </div>
  );
}
