import React from "react";
import { XIcon } from "lucide-react";

const PreviewVaccinationDetails = ({ vaccinationData, onClose, petType }) => {
  const getVaccinationDetails = () => {
    if (!vaccinationData) return null;

    // If vaccinated, return the name of the vaccination
    if (vaccinationData.Vaccination && vaccinationData.Vaccination.length > 0) {
      return {
        type: "vaccinated",
        value:
          vaccinationData.Vaccination[0].currentvaccination ||
          "Unknown Vaccine",
      };
    }

    // If not vaccinated, return the selected date
    if (vaccinationData.nonvaccination) {
      console.log(
        vaccinationData.nonvaccination.selectedDate,
        "selectedDate",
        vaccinationData.nonvaccination,
        "date"
      );

      return {
        type: "nonvaccinated",
        value:
          petType === "Dog or Canids" || petType === "Cat or Feline"
            ? `Selected Date: ${vaccinationData?.nonvaccination?.selectedDate}`
            : `Selected Date: ${vaccinationData?.nonvaccination}` || "N/A",
      };
    }

    return null;
  };

  const vaccinationDetails = getVaccinationDetails();
  console.log("Vaccination Details:", vaccinationDetails);

  if (!vaccinationDetails) return null;

  return (
    <div className="mt-[2vh] p-[.7vw] bg-[#f5f5f5] rounded-[.6vw] ">
      <div className="flex justify-between items-center">
        <div>
          <span className="text-[clamp(0.5rem,1vw,3rem)] font-medium text-slate-600">
            {vaccinationDetails.value}
          </span>
        </div>
        <button
          onClick={onClose}
          className="text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-full transition-colors"
        >
          <XIcon className="w-[2vw] h-[2vw] bg-black text-white rounded-[.6vw]" />
        </button>
      </div>
    </div>
  );
};

export default PreviewVaccinationDetails;
