import React, { useState, useEffect } from "react";
import "../ConfirmVerification/ConfirmVerification.css";
import { EnterOTP } from "./EnterOTP";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import axios from "../../../../axios";
import Cookies from "js-cookie";

function ForgotPassword() {
  const [input, setInput] = useState("");
  const [openEnterOTP, setOpenEnterOTP] = useState(false);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contactType, setContactType] = useState("");
  const loginToken = Cookies.get("loginToken");

  // useEffect(() => {
  //   let timer;
  //   if (error) {
  //     timer = setTimeout(() => {
  //       setError("");
  //     }, 3000);
  //   }
  //   return () => clearTimeout(timer);
  // }, [error]);

  const validateInput = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;
    if (emailRegex.test(value)) {
      return "email";
    } else if (phoneRegex.test(value)) {
      return "mobile";
    } else {
      return null;
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.trim();
    setInput(value);
    if (value === "") {
      return;
    }
    const inputType = validateInput(value);
    if (inputType) {
      setError("");
    } else {
      setError("Please enter a valid email or phone number");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) {
      setError("Please enter an email or phone number");
      return;
    }
    const inputType = validateInput(input);
    if (!inputType) {
      setError("Please enter a valid email or phone number");
      return;
    }
    setError("");
    setIsSubmitting(true);

    try {
      const payload = { email: input };
      if (inputType === "mobile") {
        payload.email = input; // For mobile, we still use the 'email' key but with the phone number
      }

      console.log("Payload for /auth/forget/sendmailandmobile:", payload);

      const response = await axios.post(
        "/auth/forget/sendmailandmobile",
        payload,
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );

      console.log(
        "Response from /auth/forget/sendmailandmobile:",
        response.data
      );

      // Check the status code
      if (response.data.status === 200) {
        setContactType(inputType);
        setOpenEnterOTP(true);
      } else {
        // For non-200 status codes, set the error message
        setError(
          response.data.message || "Failed to send OTP. Please try again."
        );
      }
    } catch (err) {
      console.error("Error sending OTP:", err);
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setError(
          err.response.data.message ||
            "An error occurred. Please try again later."
        );
      } else if (err.request) {
        // The request was made but no response was received
        setError("No response from server. Please try again later.");
      } else {
        // Something happened in setting up the request that triggered an Error
        setError("An error occurred. Please try again later.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  if (openEnterOTP) {
    return (
      <EnterOTP
        isOpen={openEnterOTP}
        isClose={() => setOpenEnterOTP(false)}
        contactType={contactType}
        contactValue={input}
        setOpenEnterOTP={setOpenEnterOTP}
        setOpenChangePasswordSetting={() => {}}
        userData={{}}
      />
    );
  }

  return (
    <div className="forgot-password-container flex items-center justify-center gap-[8vh] flex-col min-h-[72vh]">
      <div className="confm-vf-div">
        <h3 className="weight-picker-title mb-0">Forgot Password</h3>
        <p className="leading-none">
          To help keep your account safe, we want to make sure it's really you
          trying to sign in
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="confm-vf-radio-div">
          <div className="form-group">
            <input
              type="text"
              aria-label="Enter Email/Phone Number"
              placeholder="Enter Email/Phone Number"
              className={`form-group-select text-gray-500 placeholder:text-gray-500 ${
                error ? "border border-red-500" : ""
              }`}
              value={input}
              onChange={handleInputChange}
            />
          </div>
          <div
            style={{
              height: "10px",
            }}
          >
            {error && (
              <p className="text-[clamp(0.6rem,.9vw,3rem)] text-red-500">
                {error}
              </p>
            )}
          </div>
        </div>
      </form>
      <div className="confm-code-btn">
        <button type="submit" disabled={isSubmitting}>
          <DogPettingIcon name={"get-code"} />
          {isSubmitting ? "Sending..." : "Get Code"}
        </button>
      </div>
    </div>
  );
}

export default ForgotPassword;
