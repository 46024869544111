import React, { useState } from "react";
import "./HomePageModal.css";
import { FaBirthdayCake } from "react-icons/fa";
import { BsGenderAmbiguous } from "react-icons/bs";
import { FaSyringe } from "react-icons/fa";
import { ImScissors } from "react-icons/im";
import { AiOutlineCloseCircle } from "react-icons/ai";
import DogPettingIcon from "../DogPettingIcon";
import alternateImg from "../../../assets/animated-dog.avif";
import dayjs from "dayjs";

export const HomePageModal = ({
  isOpen,
  isClose,
  className,
  header,
  headerText,
  nfcDiv,
  aboutDiv,
  petInfo,
}) => {
  const [isClosing, setIsClosing] = useState(false);
  const calculateAge = (birthDate) => {
    const birth = new Date(birthDate);
    const today = new Date();
    let years = today.getFullYear() - birth.getFullYear();
    let months = today.getMonth() - birth.getMonth();
    let days = today.getDate() - birth.getDate();

    if (days < 0) {
      months--;
      days += new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    }

    if (months < 0) {
      years--;
      months += 12;
    }

    if (years === 0 && months === 0) {
      days = Math.floor((today - birth) / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    }

    return { years, months, days };
  };

  // Usage in the component
  const getPetAge = (birthDate) => {
    const age = calculateAge(birthDate);
    if (age.years > 0) {
      return `${age.years} Years ${
        age.months > 0 ? age.months + " Months" : ""
      }`;
    } else if (age.months > 0) {
      return `${age.months} Mon ${age.days > 0 ? age.days + " Day" : ""}`;
    } else {
      return `${age.days} Days`;
    }
  };

  console.log("pet:", petInfo);

  const trimBreed = (breed) => {
    if (breed.length > 12) {
      return breed.slice(0, breed.length - 27) + " ..";
    }
    return breed;
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000); // Match the duration of the closing animation
  };
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      isClose();
    }
  };

  if (!isOpen && !isClosing) return null;

  return (
    <div
      className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        {header && (
          <div className={`modal-header ${className}`}>
            <p>{headerText}</p>
          </div>
        )}
        <div className="modal-background">
          <img
            src={petInfo?.petinfo?.petProfileImage || alternateImg}
            alt={petInfo?.petinfo?.petName}
            className={`${className}`}
          />
          <div
            className="modal-close"
            onClick={handleClose}
            style={{ marginTop: "-20px" }}
          >
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
        </div>
        <div className="modal-description">
          <div className={`pet-description ${className}`}>
            <div className="pet-name">
              <p>{petInfo?.petinfo?.petName}</p>
              <span
                style={{
                  textTransform: "capitalize",
                }}
              >
                {trimBreed(petInfo?.petinfo?.petBreed)}
              </span>
            </div>
            <div className="pet-info">
              <p className="birthday">
                <span>
                  <FaBirthdayCake className="cake-icon" />
                </span>
                <span>{getPetAge(petInfo?.petinfo?.birthDate)}</span>
              </p>

              <p className="gender">
                <span>
                  <BsGenderAmbiguous className="gender-icon" />
                </span>
                <span>{petInfo?.petinfo?.sex}</span>
              </p>
            </div>
          </div>
          <div className="pet-address">
            <p>Last Seen: {petInfo?.lastseen}</p>
            <span>
              {petInfo?.petInfo?.bio ||
                `I’m ${petInfo?.petinfo?.petName}, a playful, friendly ${petInfo?.petinfo?.petBreed}. I love outdoor adventures
              and belly rubs`}
            </span>
          </div>
          {aboutDiv && (
            <div className="w-[25vw] h-[20vh] bg-[#F9F9F9] flex items-center justify-center rounded-[1vw]">
              {/* <h3>About</h3>
              <div className="about-cards">
                {petInfo?.petinfo?.vaccination && (
                  <div className="about-card">
                    <p>
                      <FaSyringe /> Vaccinated
                    </p>
                  </div>
                )}

                {petInfo?.petinfo?.vaccination && (
                  <div className="about-card">
                    <p>
                      <ImScissors /> Trained
                    </p>
                  </div>
                )}
              </div> */}

              <div className="flex space-x-[1vw] items-center bg-white p-[1vw] rounded-[2vw]">
                <DogPettingIcon name={"quirks"} />
                <p className="text-[clamp(.6rem,1.1vw,2.5rem)]">
                  Quirks Coming Soon
                </p>
              </div>
            </div>
          )}

          {nfcDiv && (
            <div className="nfc-div">
              <p>
                <DogPettingIcon name={"qr"} /> PETEYE00975
              </p>
              <img
                src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/nfc/pettag.png"
                alt="pet tag"
              />

              <span>
                <DogPettingIcon name={"unlink"} /> 17 June, 2021
              </span>

              <button>
                <DogPettingIcon name={"disconnect"} /> Disconnect
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
