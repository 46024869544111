import React, { useEffect, useState } from "react";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import "./Settingsmodal.css";
import "../Overview/PetFamily/InvitePetFamily.css";
import Cookies from "js-cookie";
import axios from "../../../../axios";

export const OwnerProfileModal = ({ isOpen, isClose }) => {
  const loginToken = Cookies.get("loginToken");
  const [errors, setErrors] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    dateOfBirth: "",
    city: "",
    state: "",
    pincode: "",
    userId: "",
    createdAt: "",
    id: "",
  });
  const [isModalClosing, setIsModalClosing] = useState(false);
  const fieldLabels = {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email Address",
    mobile: "Mobile Number",
    dateOfBirth: "Date of Birth",
    city: "City",
    state: "State",
    pincode: "PIN Code",
    userId: "User ID",
    createdAt: "Created At",
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
      setIsModalClosing(true);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);
    // Add logic to save the data (e.g., API call to save changes)
    try {
      await axios.patch("/user/updateuserdata", profileData, {
        headers: {
          Authorization: loginToken, // Ensure the token is prefixed with 'Bearer '
        },
      });
      // Handle success (e.g., show a success message)
    } catch (error) {
      console.error("Error saving owner profile:", error);
      // Handle error (e.g., show an error message)
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Allow unrestricted input while typing
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    let error = ""; // Temporary error message holder
    let isValid = true;

    switch (name) {
      case "firstName":
      case "lastName":
        isValid = /^[A-Za-z\s]{0,30}$/.test(value);
        if (!isValid) error = `${name} should only contain letters (max 30).`;
        break;
      case "email":
        isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        if (!isValid) error = "Invalid email format.";
        break;
      case "mobile":
        isValid = /^\d{0,10}$/.test(value); // Max 10 digits
        if (!isValid) error = "Mobile number must be 10 digits.";
        break;
      case "city":
      case "state":
        isValid = /^[A-Za-z\s]{0,20}$/.test(value);
        if (!isValid) error = `${name} should only contain letters (max 20).`;
        break;
      case "pincode":
        isValid = /^\d{0,6}$/.test(value); // Allow partial input (0 to 6 digits)
        if (!isValid) error = "Pincode must be exactly 6 digits.";
        break;
      case "userId":
        isValid = /^[A-Za-z0-9]{0,15}$/.test(value);
        if (!isValid)
          error = "User ID must be alphanumeric (max 15 characters).";
        break;
      case "createdAt":
        isValid = !isNaN(new Date(value).getTime());
        if (!isValid) error = "Invalid date format.";
        break;
      default:
        break;
    }

    // Update errors state with timeout to clear the error message
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    if (error) {
      setTimeout(() => {
        setErrors((prevErrors) => {
          const { [name]: _, ...rest } = prevErrors; // Remove error after timeout
          return rest;
        });
      }, 3000); // 3-second timeout
    }
  };

  const fetchOwnerProfile = async () => {
    try {
      const response = await axios.get("/user/userInfo", {
        headers: {
          Authorization: loginToken, // Ensure the token is prefixed with 'Bearer '
        },
      });
      const { message } = response.data;
      console.log("message:", message);
      setProfileData({
        firstName: message.firstName || "",
        lastName: message.lastName || "",
        email: message.email || "",
        mobile: message.mobile || "",
        dateOfBirth: message.dateOfBirth || "",
        city: message.city || "",
        state: message.state || "",
        pincode: message.pincode || "",
        userId: message.userId || "",
        createdAt: message.createdAt || "",
        id: message.id || "",
      });
    } catch (error) {
      console.error("Error fetching owner profile:", error);
    }
  };

  useEffect(() => {
    fetchOwnerProfile();
  }, []);

  const formattedDate = new Date(profileData.createdAt).toLocaleString();

  const handleClose = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setIsModalClosing(false);
      isClose();
    }, 1000); // Match the duration of the closing animation
  };
  return (
    <div
      className={`modal-overlay ${isModalClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isModalClosing ? "close" : ""}`}>
        <div className="modal-top-header">
          <div className="modal-title">
            <p>
              <DogPettingIcon name={"owner-profile"} /> Owner's Profile
            </p>
          </div>

          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
        </div>
        <div className="setting-modal-description">
          <div className="modal-input-group">
            {[
              "firstName",
              "lastName",
              "email",
              "mobile",
              "dateOfBirth",
              "city",
              "state",
              "pincode",
              "userId",
              "createdAt",
            ].map((field) => (
              <div key={field} className="input-container">
                {isEditing && (
                  <label
                    htmlFor={field}
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    {fieldLabels[field]}
                  </label>
                )}
                <input
                  type={field === "email" ? "email" : "text"}
                  name={field}
                  value={profileData[field]}
                  placeholder={
                    profileData[field] || `Enter ${fieldLabels[field]}`
                  }
                  onChange={handleChange}
                  readOnly={!isEditing}
                  className={!isEditing ? "input-disabled" : ""}
                  maxLength={
                    field === "pincode"
                      ? 6
                      : field === "userId"
                      ? 15
                      : undefined
                  }
                />
              </div>
            ))}
          </div>

          <div className=" absolute bottom-0 flex flex-col items-center justify-center gap-[0.5vw]  w-full h-[12vh]  backdrop-blur-sm">
            {isEditing ? (
              <button
                className="flex items-center gap-[0.5vw] text-[clamp(1rem,1.2vw,3.5rem)]  bg-black text-white px-[1.5vw] py-[0.5vw] rounded-[1vw]"
                onClick={handleSaveClick}
              >
                <DogPettingIcon name={"modal-edit"} />
                Save
              </button>
            ) : (
              <button
                className="flex items-center gap-[0.5vw] text-[clamp(1rem,1.2vw,3.5rem)]  bg-black text-white px-[1.5vw] py-[0.5vw] rounded-[1vw]"
                onClick={handleEditClick}
              >
                <DogPettingIcon name={"modal-edit"} />
                Edit
              </button>
            )}
            {Object.entries(errors).map(
              ([field, error]) =>
                error && ( // Only show if there is an error
                  <p key={field} className="error-message">
                    {error}
                  </p>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
