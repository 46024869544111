import React, { useState } from "react";
import DogPettingIcon from "../../../../Homepage/DogPettingIcon";
import axios from "../../../../../axios";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import Modal from "../../../../../shop/components/Modal/Modal";
import ConfirmVerification from "../../../../LoginPage/Component/ConfirmVerification/ConfirmVerification";

export default function RemovePetSubModal({ isClose, pet, petId, onSuccess }) {
  const loginToken = Cookies.get("loginToken");
  const [isClosing, setIsClosing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      setError(null);
      isClose();
    }, 1000);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return (
    <div>
      <Modal
        modaltitle={"Remove Pet"}
        handleClose={handleClose}
        handleOverlayClick={handleOverlayClick}
        isClosing={isClosing}
        iconname={""}
      >
        <div className="h-[60vh] no-scrollbar overflow-auto">
          <ConfirmVerification />
        </div>
      </Modal>
    </div>
  );
}
