import React, { useState } from "react";
import "./Settings.css";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import { OwnerProfileModal } from "./OwnerProfileModal";
import { AddPetModal } from "./AddPetModal";
import { PetLicensesModal } from "./PetLicenseModal";
import { TransferOwnership } from "./TransferOwnership";
import PetFamily from "../Overview/PetFamily/PetFamily";
import { RecordAccess } from "./RecordAccess/RecordAccess";
import { RemovePet } from "./RemovePet/RemovePet";

const Settings = ({ petInfo, login, currentPetId }) => {
  const [activeModal, setActiveModal] = useState(null);

  const settingsData = [
    {
      id: 1,
      img: <DogPettingIcon name={"petowner-transfer"} />,
      data: "Transfer Ownership",
      disabled: false,
    },
    {
      id: 2,
      img: <DogPettingIcon name={"add-pet"} />,
      data: "Add Pet",
      disabled: false,
    },
    {
      id: 3,
      img: <DogPettingIcon name={"petfam"} />,
      data: "Pet Family",
      disabled: false,
    },
    {
      id: 4,
      img: <DogPettingIcon name={"record-access"} />,
      data: "Record Access",
      disabled: false,
    },
    {
      id: 5,
      img: <DogPettingIcon name={"pet-license"} />,
      data: "Pet Licenses",
      disabled: false,
    },

    {
      id: 6,
      img: <DogPettingIcon name={"remove-pet"} />,
      data: "Remove Pet",
      disabled: false,
    },
  ];

  const openModal = (id) => {
    if (!settingsData.find((item) => item.id === id).disabled) {
      setActiveModal(id);
    }
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  return (
    <div>
      <div className="grid-container">
        {settingsData.map((item) => (
          <div
            key={item.id}
            className={`grid-item ${item.disabled ? "disabled" : ""}`}
            onClick={() => openModal(item.id)}
          >
            <div className="svgicon">{item.img}</div>
            <h3>{item.data}</h3>
          </div>
        ))}
      </div>
      {activeModal === 1 && (
        <TransferOwnership isOpen={activeModal === 1} isClose={closeModal} />
      )}
      {activeModal === 2 && (
        <AddPetModal isOpen={activeModal === 2} isClose={closeModal} />
      )}
      {activeModal === 3 && (
        <PetFamily
          isOpen={activeModal === 3}
          onClose={closeModal}
          pet={petInfo}
        />
      )}
      {activeModal === 4 && (
        <RecordAccess isOpen={activeModal === 4} isClose={closeModal} />
      )}
      {activeModal === 5 && (
        <PetLicensesModal isOpen={activeModal === 5} isClose={closeModal} />
      )}
      {activeModal === 6 && (
        <RemovePet
          isOpen={activeModal === 6}
          isClose={closeModal}
          petId={currentPetId}
          pet={petInfo}
        />
      )}
    </div>
  );
};

export default Settings;
