import React, { useEffect, useState } from "react";
// import "../../../LoginPage/Component/Registration/PetInfo/PetInfo.css";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../../axios";
import { petInfoValidateForm } from "../../../LoginPage/Component/Registration/PetInfo/PetInfoValidation";
import { RabiesVaccination } from "./RabiesVaccination";
import PetInfoModal from "../../../LoginPage/Component/Registration/PetInfo/PetInfoModal";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
// import AddPetModal from "./AddPetModal";
import "./Settingsmodal.css";
import PreviewVaccinationDetails from "./PreviewVaccinationDetails";
const dog_Img =
  "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/regImg/dogIcon.png";
const cat_Img =
  "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/regImg/catIcon.png";
const other_Img =
  "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/regImg/OtherIcon.png";

export const AddPetModal = ({ isClose }) => {
  const dispatch = useDispatch();
  const [petName, setPetName] = useState("");
  const [petBreed, setPetBreed] = useState("");
  const [petGender, setPetGender] = useState("");
  const [petBirthday, setPetBirthday] = useState("");
  const [petRegisteredDate, setPetRegisteredDate] = useState("");
  const [petKernelNumber, setPetKernelNumber] = useState("");
  const [isRabiesVaccinated, setIsRabiesVaccinated] = useState(null);
  const [vaccinationSlot, setVaccinationSlot] = useState("");
  const [petWeight, setPetWeight] = useState("");
  const [petDoctorName, setPetDoctorName] = useState("");
  const [doctorContactNumber, setDoctorContactNumber] = useState("");
  const [doctorAddress, setDoctorAddress] = useState("");
  const [petType, setPetType] = useState("Dog or Canids");
  const [isRabiesVaccinationOpen, setIsRabiesVaccinationOpen] = useState(false);
  const [breedList, setBreedList] = useState([]);
  const [errors, setErrors] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);
  const [openAlert, setopenAlert] = useState(false);
  const [openA, setOpenA] = useState(true);
  const [errMessage, setErrMessage] = useState("");
  const [vaccinationData, setVaccinationData] = useState(null);
  const [open, setOpen] = useState(false);
  const [OpenAddPet, setOpenAddPet] = useState(false);
  const [VaccinationList, setVaccinationList] = useState([]);
  const [modalFormData, setModalFormData] = useState([]);
  const [AddPetData, setAddPetData] = useState("");
  const [filteredBreeds, setFilteredBreeds] = useState([]);
  const loginToken = Cookies.get("loginToken");
  const signupToken = Cookies.get("signupToken");
  const [isModalClosing, setIsModalClosing] = useState(false);
  const [petWeightUnit, setPetWeightUnit] = useState("kg"); // Default unit is kg
  const [customPetType, setCustomPetType] = useState("");
  const getPetTypeForAPI = (type) => {
    if (type === "Dog or Canids") return "Dog";
    if (type === "Cat or Feline") return "Cat";
    return customPetType || "Other"; // Use customPetType if available
  };
  useEffect(() => {
    getBreedList();
  }, []);

  useEffect(() => {
    if (breedList.length > 0) {
      filterBreedsByPetType();
    }
  }, [petType, breedList]);
  const getBreedList = async () => {
    try {
      const response = await axios.get("/user/getBreedlist", {
        headers: {
          Authorization: signupToken || loginToken,
        },
      });
      console.log("breed list: ", response.data);
      setBreedList(response.data.message);
    } catch (error) {
      console.error("Error fetching breed list:", error);
    }
  };

  const filterBreedsByPetType = () => {
    if (!petType || petType === "Others") {
      setFilteredBreeds(breedList); // Show all breeds for "Others"
    } else {
      const filtered = breedList.filter(
        (breed) =>
          breed.Petname === (petType === "Dog or Canids" ? "Dog" : "Cat")
      );
      console.log("Filtered Breeds:", filtered);
      setFilteredBreeds(filtered);
    }
  };

  const handlePetTypeSelect = (type) => {
    setPetType(type === petType ? null : type);
    setIsRabiesVaccinated(null);
    setVaccinationData(null);
    filterBreedsByPetType();
  };

  const handleRabiesVaccinationChange = (status) => {
    setIsRabiesVaccinated(status);
    setIsRabiesVaccinationOpen(true);
  };

  const handleVaccinationDataChange = (data) => {
    setVaccinationData(data);
    setIsRabiesVaccinationOpen(false);
  };

  const validateInputs = () => {
    const newErrors = {};
    const errorMessages = [];
    const requiredFields = {
      petName: "Pet name",
      petBreed: "Pet breed",
      petGender: "Pet gender",
      petBirthday: "Pet birthday",
      // petRegisteredDate: "Registered date",
      // petKernelNumber: "Kernel and club number",
      petWeight: "Pet weight",
      // petDoctorName: "Pet doctor's name",
      // doctorContactNumber: "Doctor's contact",
      // doctorAddress: "Doctor's address",
      petType: "Pet type",
      ...(petType === "Others" && { customPetType: "Custom pet type" }),
    };

    const today = new Date().toISOString().split("T")[0];

    // Special character validation for pet name
    const specialCharPattern = /^[a-zA-Z\s ]+$/;
    if (!specialCharPattern.test(petName)) {
      newErrors.petName = "Pet Name contain special characters.";
      errorMessages.push("Pet Name contain special characters.");
    } else if (petName.length > 15) {
      newErrors.petName = "Not more than 15 characters.";
      errorMessages.push("Not more than 15 characters.");
    }
    if (petBirthday && petBirthday > today) {
      // Future date validation for birthdate and registered date
      newErrors.petBirthday = "Pet birthday cannot be a future date.";
      errorMessages.push("Pet birthday cannot be a future date.");
    }
    if (petRegisteredDate && petRegisteredDate > today) {
      newErrors.petRegisteredDate = "Kennel date cannot be a future date.";
      errorMessages.push("Kennel date cannot be a future date.");
    }
    if (petWeight < 0) {
      newErrors.petWeight = "Pet weight cannot be negative.";
      errorMessages.push("Pet weight cannot be negative.");
    } else if (petWeight > 200) {
      newErrors.petWeight = "Petweight can't be more.";
      errorMessages.push("Petweight can't be more.");
    } else if (petWeight === "" || isNaN(petWeight)) {
      newErrors.petWeight = "Please enter a valid weight.";
      errorMessages.push("Please enter a valid weight.");
    }
    if (petDoctorName && specialCharPattern.test(petDoctorName)) {
      newErrors.petDoctorName = "Doc Name contain characters.";
      errorMessages.push("Doc Name contain characters.");
    }
    if (doctorContactNumber) {
      if (isNaN(doctorContactNumber)) {
        newErrors.doctorContactNumber = "contain number Only.";
        errorMessages.push("contain number contain Only.");
      } else if (doctorContactNumber.length !== 10) {
        newErrors.doctorContactNumber = "Contact only 10 digits.";
        errorMessages.push("Contact only 10 digits.");
      }
    }
    if (doctorAddress.length > 50) {
      newErrors.doctorAddress = "Address should be less .";
      errorMessages.push("Address should be less .");
    }

    if (petType === "Others" && !customPetType.trim()) {
      newErrors.customPetType = "Please specify your pet type";
      errorMessages.push("Please specify your pet type");
    }
    // Loop for other required fields
    else
      Object.entries(requiredFields).forEach(([field, label]) => {
        if (!eval(field)) {
          newErrors[field] = `${label} is required.`;
          errorMessages.push(`${label} is required.`);
        }
      });

    setErrors(newErrors);
    setErrorMessages(errorMessages);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Making API call...");

    if (!validateInputs()) {
      console.log("Validation failed", errors);
      // Set errors and error messages
      errorMessages.forEach((error, index) => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [index]: error,
        }));
      });
    }

    console.log(vaccinationData, "vaccinationData from PetInfo");

    if (!vaccinationData) {
      console.log("No vaccination data");
      // Alert the user if there's no vaccination data
      setopenAlert(true);
      setErrMessage(
        "Problem in vaccination. Please provide vaccination information."
      );
      return; // Stop the form submission
    }
    console.log("Vaccination data present:", vaccinationData);

    const petInfo = {
      petName,
      petType: getPetTypeForAPI(petType),
      petBreed,
      birthDate: petBirthday,
      petWeight: `${petWeight}${petWeightUnit}`,
      sex: petGender,
      kennelClubNumber: petKernelNumber,
      KennelClubRegisterdDate: petRegisteredDate,
      vetDoctorName: petDoctorName,
      vetDoctorNumber: doctorContactNumber,
      vetDoctorLocation: doctorAddress,
    };

    // Attach the vaccination data received from RabiesVaccination component
    if (vaccinationData.Vaccination) {
      petInfo.Vaccination = vaccinationData.Vaccination;
    }

    // Always include nonvaccination, even if it's an empty string
    petInfo.nonvaccination = vaccinationData.nonvaccination || "";

    console.log("petInfo:", petInfo);
    const formValidation = petInfoValidateForm(petInfo);
    console.log(formValidation);

    if (formValidation) {
      console.log(formValidation, "formValidation");

      try {
        dispatch({ type: "petInfo", payload: { petInfo: petInfo } });
        console.log("Making API call...");
        const response = await axios.post(
          "/petinfo/secondPetaddInfo",
          petInfo,
          {
            headers: {
              Authorization: loginToken,
            },
          }
        );
        console.log(" New Pet  Added Successfully:", response.data);

        // Clear all fields and close modal after successful submission
        setPetName("");
        setPetBreed("");
        setPetGender("");
        setPetBirthday("");
        setPetRegisteredDate("");
        setPetKernelNumber("");
        setIsRabiesVaccinated(null);
        setVaccinationSlot("");
        setPetWeight("");
        setPetDoctorName("");
        setDoctorContactNumber("");
        setDoctorAddress("");
        setPetType(null);
        isClose();

        dispatch({ type: "refresh" });
      } catch (error) {
        console.error("Error adding pet info:", error);
        setErrors({ submit: "Failed to add pet info. Please try again." });
      }
    } else {
      setopenAlert(true);
      setErrMessage(formValidation);
    }
  };

  const handleClose = () => {
    isClose();
  };
  const handleWeightUnitChange = (e) => {
    setPetWeightUnit(e.target.value);
  };
  console.log(petType, "petType");

  return (
    <>
      <div className={`modal-overlay ${isModalClosing ? "fade-out" : ""}`}>
        <div className={`modal-container ${isModalClosing ? "close" : ""}`}>
          <div className="modal-top-header">
            <div className="modal-title">
              <p>
                <DogPettingIcon name={"add-pet"} /> Add Pet
              </p>
            </div>
            <div className="modal-close" onClick={handleClose}>
              <DogPettingIcon
                name={"closemodal"}
                className="modal-close-icon"
              />
            </div>
          </div>
          <div className="setting-modal-description">
            <div className="add-pet">
              <div className="add-pet-header">
                <h3>Choose your pet type</h3>
                <div className="pet-selection-box">
                  {["Dog or Canids", "Cat or Feline", "Others"].map((type) => (
                    <div
                      key={type}
                      className={`pet-box ${
                        petType === type ? "pet-selected" : ""
                      }`}
                      onClick={() => handlePetTypeSelect(type)}
                    >
                      <p className="flex items-center gap-2">
                        <DogPettingIcon name={type.toLowerCase()} /> {type}
                      </p>
                    </div>
                  ))}
                </div>
                {errors.petType && (
                  <p className="text-red-500 text-[clamp(0.5rem,1vw,2rem)]">
                    {errors.petType}
                  </p>
                )}
              </div>
              <div className="add-pet-center" style={{ padding: "5px" }}>
                {[
                  ...(petType === "Others"
                    ? [
                        {
                          name: "customPetType",
                          placeholder: "Enter pet type",
                          type: "text",
                        },
                      ]
                    : []),

                  { name: "petName", placeholder: "Pet's Name", type: "text" },
                  {
                    name: "petBreed",
                    placeholder: "Pet's Breed",
                    type: petType === "Others" ? "text" : "select",
                    options: filteredBreeds,
                  },
                  {
                    name: "petBirthday",
                    placeholder: "Date Of Birth",
                    type: "date",
                  },
                  {
                    name: "petGender",
                    placeholder: "Gender",
                    type: "select",
                    options: [
                      { id: "Male", BreedName: "Male" },
                      { id: "Female", BreedName: "Female" },
                    ],
                  },

                  ...(petType === "Dog or Canids"
                    ? [
                        {
                          name: "petKernelNumber",
                          placeholder: "Kennel Club Number",
                          type: "text",
                        },
                      ]
                    : []),
                ].map((field) => (
                  <div key={field.name} className="add-pet-input-group ">
                    {field.type === "select" ? (
                      <select
                        value={eval(field.name)}
                        onChange={(e) =>
                          eval(
                            `set${
                              field.name.charAt(0).toUpperCase() +
                              field.name.slice(1)
                            }`
                          )(e.target.value)
                        }
                        aria-label={field.placeholder}
                        className={`${
                          errors[field.name]
                            ? "border border-red-500 placeholder"
                            : "placeholder"
                        }`}
                        style={{
                          width:
                            petType === "Others" && field.name === "petGender"
                              ? "100%"
                              : "14.5vw",
                        }}
                      >
                        <option value="" disabled>
                          {field.placeholder}
                        </option>
                        {field.options.map((option) => (
                          <option
                            key={option.id}
                            value={option.BreedName || option.id}
                          >
                            {option.BreedName || option}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type={field.type === "date" ? "text" : field.type}
                        placeholder={
                          field.name === "petBreed" && petType === "Others"
                            ? "Enter breed"
                            : field.placeholder
                        }
                        value={eval(field.name)}
                        className={`${
                          errors[field.name] ? "border border-red-500" : ""
                        }`}
                        style={{
                          width:
                            field.name === "petKernelNumber" ||
                            (petType === "Others" && field.name === "petGender")
                              ? "100%"
                              : "14.5vw",
                        }}
                        onChange={(e) =>
                          eval(
                            `set${
                              field.name.charAt(0).toUpperCase() +
                              field.name.slice(1)
                            }`
                          )(e.target.value)
                        }
                        onFocus={
                          field.type === "date"
                            ? (e) => (e.target.type = "date")
                            : undefined
                        }
                        onBlur={
                          field.type === "date"
                            ? (e) =>
                                (e.target.type = e.target.value
                                  ? "date"
                                  : "text")
                            : undefined
                        }
                        aria-label={field.placeholder}
                      />
                    )}
                    {errors[field.name] && (
                      <div className="text-red-500 text-[clamp(0.3rem,.8vw,2rem)]">
                        {errors[field.name]}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {petType && (
                <div>
                  <div className="add-pet-center-header">
                    <h3
                      className={
                        "text-[#262427] font-bold text-[clamp(0.5rem,1.2vw,3.5rem)]"
                      }
                    >
                      {petType === "Dog or Canids"
                        ? "Is your dog rabies vaccinated?"
                        : petType === "Others"
                        ? "Vaccination Details"
                        : "Is your pet vaccinated?"}
                    </h3>
                  </div>
                  <div className="selection-input">
                    {petType === "Others" ? (
                      // Show input box with a button to add vaccination details when petType is 'others'
                      <div
                        className="flex bg-[#f5f5f5] text-gray-500 px-[1vw] py-[2vh] justify-between items-center w-full rounded-[.8vw]"
                        onClick={() => setIsRabiesVaccinationOpen(true)}
                      >
                        <button
                          // This should trigger your modal
                          className=" w-full text-left transition text-[clamp(0.5rem,1vw,3rem)] "
                        >
                          Add Past Vaccination Details
                        </button>
                        <p className="cursor-pointer">
                          <DogPettingIcon name={"editPetVaccine"} />
                        </p>
                      </div>
                    ) : (
                      // Only show Yes/No options for other pet types
                      <>
                        <label>
                          <input
                            type="radio"
                            name="rabiesVaccination"
                            checked={isRabiesVaccinated === true}
                            onChange={() => handleRabiesVaccinationChange(true)}
                          />
                          &nbsp; Yes
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="rabiesVaccination"
                            checked={isRabiesVaccinated === false}
                            onChange={() =>
                              handleRabiesVaccinationChange(false)
                            }
                          />
                          &nbsp; No
                        </label>
                      </>
                    )}
                  </div>
                </div>
              )}

              <div className="">
                {vaccinationData && (
                  <PreviewVaccinationDetails
                    vaccinationData={vaccinationData}
                    onClose={() => setVaccinationData(null)}
                    petType={petType}
                  />
                )}
              </div>

              <div className="add-pet-center">
                {[
                  {
                    name: "petWeight",
                    placeholder: "Weight",
                    type: "text",
                  },
                  {
                    name: "petDoctorName",
                    placeholder: "Vet's Name",
                    type: "text",
                  },
                  {
                    name: "doctorContactNumber",
                    placeholder: "Vet's Number",
                    type: "text",
                  },
                  {
                    name: "doctorAddress",
                    placeholder: "Vet's Address",
                    type: "text",
                  },
                ].map((field) => (
                  <div
                    key={field.name}
                    className="add-pet-input-group relative"
                  >
                    <input
                      type={field.type === "date" ? "text" : field.type}
                      placeholder={field.placeholder}
                      value={eval(field.name)}
                      style={{ width: "14.5vw" }}
                      className={`textfield-size-reg ${
                        field.name === "petWeight" ? "relative" : ""
                      }  ${errors[field.name] ? "border border-red-500" : ""}
                      `}
                      onChange={(e) =>
                        eval(
                          `set${
                            field.name.charAt(0).toUpperCase() +
                            field.name.slice(1)
                          }`
                        )(e.target.value)
                      }
                      onFocus={
                        field.type === "date"
                          ? (e) => (e.target.type = "date")
                          : undefined
                      }
                      onBlur={
                        field.type === "date"
                          ? (e) =>
                              (e.target.type = e.target.value ? "date" : "text")
                          : undefined
                      }
                      aria-label={field.placeholder}
                    />

                    {field.name === "petWeight" && (
                      <select
                        value={petWeightUnit}
                        onChange={handleWeightUnitChange}
                        className={`absolute right-0 top-1/2 -translate-y-1/2 bg-gray-200 cursor-pointer text-[clamp(0.9rem,0.9vw,2.5rem)] ${
                          errors[field.name] ? "border border-red-500 " : ""
                        }`}
                      >
                        <option
                          value="kg"
                          className="text-[clamp(0.9rem,0.9vw,2.5rem)]"
                        >
                          kg
                        </option>
                        <option
                          value="lbs"
                          className="text-[clamp(0.9rem,0.9vw,2.5rem)]"
                        >
                          lbs
                        </option>
                      </select>
                    )}
                    {errors[field.name] && (
                      // add the
                      <div className="text-red-500 text-[clamp(0.3rem,.8vw,2rem)]">
                        {errors[field.name]}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="modal-control">
              <button onClick={handleSubmit}>
                <DogPettingIcon name={"accept"} />
                Add Pet
              </button>
            </div>
          </div>
        </div>
      </div>
      {isRabiesVaccinationOpen && (
        <RabiesVaccination
          isOpen={isRabiesVaccinationOpen}
          isClose={() => setIsRabiesVaccinationOpen(false)}
          vaccinated={isRabiesVaccinated}
          petType={petType}
          onVaccinationDataChange={handleVaccinationDataChange}
        />
      )}
    </>
  );
};
