import React, { useState, useEffect } from "react";
import DogPettingIcon from "../../../../Pages/Homepage/DogPettingIcon";
import "./Checkout.css";
import axios from "../../../../axios";
import Cookies from "js-cookie";
import { useShop } from "../../ShopContext";
import CartItems from "../CartItems";
import PharmacyItems from "../PharmacyItems";
import Address from "./Address";
import { useDispatch, useSelector } from "react-redux";
import ShopGif from "../../ShopGif/ShopGifs";
import { useNavigate } from "react-router-dom";
import Failedgif from "../../../../Pages/Failed/Failedgif";
import VaccinationBookingCart from "./VaccinationBookingCart";
import Icons from "../../../../context/Icons";

export default function Checkout({
  isOpen,
  isClose,
  bookingData,
  bookingVaccine = false,
  petId,
}) {
  const navigate = useNavigate();
  const [isClosing, setIsClosing] = useState(false);
  const [isClosing1, setIsClosing1] = useState(false);
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const {
    ActiveTab,
    handleActiveTab,
    calculateCartTotal,
    calculatePharmacyCartTotal,
    totalDiscount,
    cartTotal,
    pharmacyCartTotal,
    pharmacyCartData,
    paraVetChecked,
    cartData,
    cartCount,
    pharmacyCount,
  } = useShop();
  console.log(ActiveTab, "active tab");
  console.log(cartCount.totalCount, "cart count", pharmacyCount.totalCount);
  console.log(bookingVaccine, "booking vaccine");
  const [isCheckedDeworm, setIsCheckedDeworm] = useState(false);
  const [selectedDewormProduct, setSelectedDewormProduct] = useState([]);
  const [dewormProducts, setDewormProducts] = useState([]);
  const [dewormTotal, setDewormTotal] = useState(0);

  const loginToken = Cookies.get("loginToken");
  const [errMessage, setErrMessage] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [countries, setCountries] = useState([]);
  const [nationality, setNationality] = useState({ Name: "" });
  const [openBackdrop, setopenBackdrop] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [openAlert, setopenAlert] = useState(false);
  const [showSuccessGif, setShowSuccessGif] = useState(false);
  const [gifDetails, setGifDetails] = useState({
    gif: "",
    message: "",
  });
  const [open, setOpen] = useState(true);
  const [openOrderSuccess, setopenOrderSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openAuth, setopenAuth] = useState(false);
  const [total, setTotal] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [error, setError] = useState("");
  const { login } = useSelector((state) => state);
  const userInfo = login.details.userInfo;
  const dispatch = useDispatch();
  const [vaccinationBooking, setVaccinationBooking] = useState([]);
  const isPharmacyTab = ActiveTab === "pharmacy" && paraVetChecked;
  const isBookingVaccine = bookingVaccine && paraVetChecked;
  const showParaVet = isPharmacyTab || isBookingVaccine;
  const [vaccinationBookingDiscount, setVaccinationBookingDiscount] =
    useState(0);

  // console.log(userInfo, "user info");
  const [newAddress, setNewAddress] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    secondaryPhone: "",
    pincode: "",
    nationality: "",
    state: "",
    district: "",
    city: "",
    houseFloorNo: "",
    area: "",
  });
  const categories = bookingVaccine
    ? vaccinationBooking?.map((item) => item)
    : Object.keys(pharmacyCartData).filter((category) =>
        pharmacyCartData[category]?.map((item) => item)
      );
  console.log(categories, "pharmacy category");

  const [paraVetAmount, setParaVetAmount] = useState(0);
  const petSelectionDetails = JSON.parse(
    localStorage.getItem("petSelectionDetails") || "[]"
  );
  const selectedPetIds = Array.isArray(petSelectionDetails)
    ? petSelectionDetails.map((item) => item)
    : [];
  console.log(selectedPetIds, "petSelectionDetails");

  console.log(vaccinationBookingDiscount, "vaccineBookingDiscount");

  console.log(vaccinationBooking, "vaccineBookingAmount");

  useEffect(() => {
    fetchAddresses();
  }, [loginToken]);

  useEffect(() => {
    if (bookingVaccine && bookingData?.id) {
      fetchVaccinationCart();
    }
  }, [bookingVaccine, bookingData]);

  useEffect(() => {
    fetchDewormProducts();
  }, []);

  useEffect(() => {
    loadRazorpayScript();
  }, []);
  useEffect(() => {
    fetchParavetPricing(
      categories,
      petSelectionDetails,
      bookingVaccine,
      bookingData
    );
  }, []);
  useEffect(() => {
    console.log("Selected Deworm Product:", selectedDewormProduct);
  }, [selectedDewormProduct]);

  const vaccineTotal = vaccinationBooking.map(
    (item) => item.Product.productvariants[0].price
  );
  const vaccineTotalNumbers = vaccineTotal.map((price) => Number(price));

  // Sum the numbers in the array
  const vaccineTotalSum = vaccineTotalNumbers.reduce(
    (acc, curr) => acc + curr,
    0
  );

  console.log(vaccineTotalSum, "deworm total");

  const handleGifClose = () => {
    setShowSuccessGif(false);
  };

  const fetchDewormProducts = async () => {
    try {
      const response = await axios.get(`/shop/searchproduct?category=Deworm`, {
        headers: {
          Authorization: loginToken,
        },
      });
      console.log("Response dewoming products:", response.data.message);
      setDewormProducts(response.data.message);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
  const handleProductSelection = (product) => {
    setSelectedDewormProduct((currentSelected) =>
      currentSelected?.id === product.id ? null : product
    );
    setDewormTotal(product.productvariants[0]?.price);
  };

  const getPrescriptionFileData = () => {
    const storedData = localStorage.getItem("PrescriptionFile");
    if (!storedData) return null;
    try {
      return JSON.parse(storedData);
    } catch (error) {
      console.error("Error parsing stored file data:", error);
      return null;
    }
  };

  const fetchVaccinationCart = async () => {
    try {
      setIsLoading(true);
      if (!bookingData?.id) {
        throw new Error("No booking data available");
      }
      const response = await axios.get(
        `/petinfo/vaccination/booking/${bookingData.id}`,
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );
      const vaccinationCartData = Array.isArray(response.data.data)
        ? response.data.data
        : [response.data.data];
      console.log(vaccinationCartData, "vaccinationCartData");
      setVaccinationBooking(vaccinationCartData);
      calculateTotalDiscount(vaccinationCartData);
      dispatch({ type: "refresh" });
      setIsLoading(false);
    } catch (err) {
      console.error("Error fetching vaccination cart:", err);
      setError(err);
      setIsLoading(false);
    }
  };

  const calculateTotalDiscount = (cartData) => {
    if (
      !cartData ||
      !cartData[0].Product ||
      !cartData[0].Product.productvariants?.[0]
    ) {
      return 0;
    }

    const variant = cartData[0].Product.productvariants[0];
    const offerPercentage = variant.OfferPercentage || 0;

    // Calculate original price before discount
    const originalPrice = variant.price / (1 - offerPercentage / 100);

    // Calculate total discount
    const discount = originalPrice - variant.price;

    setVaccinationBookingDiscount(discount);
    return discount;
  };
  console.log(vaccinationBooking, bookingData?.id, "vaccinationBooking113");
  // Example usage:
  const prescriptionFileData = getPrescriptionFileData();
  console.log(prescriptionFileData, "prescriptionFileData");

  const fetchParavetPricing = async (
    categories = [],
    petSelectionDetails = [],
    bookingVaccine = false,
    bookingData = null
  ) => {
    console.log("fetchParavetPricing called");
    try {
      console.log("Input data:", {
        categories,
        petSelectionDetails,
        bookingVaccine,
        bookingData,
      });

      // Early return if we don't have the minimum required data
      if (!categories.length && !bookingVaccine) {
        console.log("No categories or booking data available");
        return;
      }

      // Get service type safely
      let serviceType;
      if (bookingVaccine) {
        serviceType = "Vaccination";
      } else {
        // Safely access the first category if it exists
        const firstCategory = Array.isArray(categories)
          ? categories[0]
          : categories;
        serviceType = firstCategory;
      }

      // Transform service type if needed
      if (serviceType === "Vaccine") {
        serviceType = "Vaccination";
      } else if (serviceType === "Deworm") {
        serviceType = "Deworming";
      }
      console.log(serviceType, "service type");

      // Get pet type safely
      let petType;
      if (bookingVaccine && petId && petId.petType) {
        petType = petId.petType;
      } else if (petSelectionDetails && petSelectionDetails[0]) {
        petType = petSelectionDetails[0].petType;
      } else {
        console.log("No pet type available");
        return;
      }
      console.log(petType, "pet type");

      // Only proceed if we have both required values
      if (!serviceType || !petType) {
        console.warn("Missing required data:", { serviceType, petType });
        return;
      }

      const response = await axios.get(
        `/shop/paravetpricing?petType=${petType}&serviceType=${serviceType}`,
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );

      setParaVetAmount(response.data);
      console.log(response.data, paraVetAmount?.data?.price, "paravet amount");
    } catch (err) {
      console.error("Error in fetchParavetPricing:", err);
      setError(err?.message || "Error fetching paravet pricing");
    }
  };

  const loadRazorpayScript = () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = handleRazorpayScriptLoad;
    document.body.appendChild(script);
  };

  const fetchAddresses = async () => {
    try {
      const response = await axios.get("/shop/getaddress", {
        headers: {
          Authorization: loginToken,
        },
      });
      setAddresses(response.data.message);
      console.log("Addresses:", response.data.message);
    } catch (error) {
      console.error("Error fetching addresses:", error);
      setAddresses([]);
    } finally {
      setIsLoading(false);
    }
  };
  const handleRazorpayScriptLoad = () => {};

  const finalCartTotal = (cartTotal - totalDiscount).toFixed(2);

  const finalPharmacyCartTotal = (
    pharmacyCartTotal -
    totalDiscount +
    (paraVetChecked && paraVetAmount?.data?.price
      ? paraVetAmount.data.price
      : 0)
  ).toFixed(2);
  console.log("paraVetAmount.data.price:", paraVetAmount?.data?.price);
  console.log("paraVetChecked:", paraVetChecked);

  const finalVaccineBookingAmount =
    Number(
      vaccinationBooking
        .map((item) => item.Product.productvariants[0].price)
        .reduce((acc, curr) => acc + curr, 0)
    ) +
    (paraVetChecked && paraVetAmount?.data?.price
      ? Number(paraVetAmount.data.price)
      : 0) +
    (selectedDewormProduct ? Number(dewormTotal) : 0);

  console.log(finalVaccineBookingAmount, "Final Vaccine Booking Amount");

  console.log(finalVaccineBookingAmount, "finalVaccineBookingAmount");

  // ORDER CREATION FOR PAYMENT
  const amount = bookingVaccine
    ? finalVaccineBookingAmount
    : ActiveTab === "cart"
    ? finalCartTotal
    : finalPharmacyCartTotal;

  const uploadImageToS3 = async (file) => {
    const allowedTypes = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];

    console.log("File object:", {
      type: file.type,
      name: file.name,
      size: file.size,
      lastModified: file.lastModified,
    });

    if (!allowedTypes.includes(file.type)) {
      console.error("Invalid file type:", file.type);
      throw new Error(
        `File type ${
          file.type
        } is not allowed. Allowed types: ${allowedTypes.join(", ")}`
      );
    }

    const formData = new FormData();

    // Log FormData content for debugging
    console.log("Before append - File details:", {
      type: file.type,
      name: file.name,
    });

    formData.append("file", file);

    // Verify FormData content
    console.log("FormData entries:");
    for (let pair of formData.entries()) {
      console.log(pair[0], pair[1]);
    }
    try {
      const response = await axios.post("/file/uploadFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: loginToken,
        },
      });
      console.log("File uploaded to S3:", response.data);

      return response.data.Message ? response.data.Message.getPeteye : "";
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      return null;
    }
  };

  const handlePayment = async () => {
    if (!addressId) {
      setError("Add address for shipment");
      setTimeout(() => setError(""), 3000);
      return;
    }
    if (bookingVaccine) {
      // First check paraVetChecked
      if (!paraVetChecked) {
        setError("Please select paraVet option");
        setTimeout(() => setError(""), 3000);
        return;
      }

      // Then check vaccination date and time regardless of paraVetChecked
      if (!localStorage.getItem("selectedVaccineDate")?.trim()) {
        setError("Please select a date for vaccination");
        setTimeout(() => setError(""), 3000);
        return;
      }

      if (!localStorage.getItem("selectedVaccineTimeSlot")?.trim()) {
        setError("Please select a time slot for vaccination");
        setTimeout(() => setError(""), 3000);
        return;
      }
    }

    // Check for prescription file
    const prescriptionFileData = getPrescriptionFileData();

    if (prescriptionFileData) {
      // Handle case with prescription file
      try {
        // Log the stored prescription data
        console.log("Stored prescription data:", prescriptionFileData);

        // Ensure correct MIME type
        const fileType = prescriptionFileData[0].type.toLowerCase();
        const file = new File(
          [prescriptionFileData],
          prescriptionFileData[0].name,
          {
            type: fileType,
            lastModified: prescriptionFileData.lastModified,
          }
        );

        // Log the created File object
        console.log("Created File object:", {
          name: file.name,
          type: file.type,
          size: file.size,
        });

        // Upload to S3
        const prescriptionUrl = await uploadImageToS3(file);

        try {
          const prescriptionResponse = await axios.post(
            "/shop/addprescription",
            {
              prescription: prescriptionUrl,
              fileType: fileType.split("/")[1],
              selectedPetIds: selectedPetIds,
            },
            {
              headers: {
                Authorization: loginToken,
                "Content-Type": "application/json",
              },
            }
          );
          console.log("Prescription added:", prescriptionResponse.data);
        } catch (prescriptionError) {
          console.error("Prescription API error:", {
            message: prescriptionError.message,
            response: prescriptionError.response?.data,
          });
          throw prescriptionError;
        }
      } catch (error) {
        console.error("Error handling prescription:", error);
        // Continue with payment even if prescription handling fails
      }
    }

    // Proceed with normal payment flow
    axios
      .post(
        "/razerpay/ordercreate",
        {
          amount: parseInt(amount),
        },
        {
          headers: {
            Authorization: loginToken,
          },
        }
      )
      .then((response) => {
        if (response?.data?.status == "200") {
          const orderId = response.data?.message?.getresponse.id;
          const key = response.data?.message?.rzp_key;
          initiateRazorpayPayment(orderId, key);
        }
        console.log(response, "payment response");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Helper functions

  // INITIALIZATION OF RAZORPAY

  const initiateRazorpayPayment = (orderId, key) => {
    const options = {
      key: key,
      amount:  Math.round(amount) * 100,
      currency: "INR",
      name: "petEYE",
      description: "Online Transaction",
      image:
        "https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-Footer/image+16407.png",
      order_id: orderId,
      handler: async (response) => {
        try {
          await verifyPayment(response, orderId);
        } catch (error) {
          console.error(error);
          setopenAlert(!openAlert);
          setErrMessage("Payment verification failed");
        }
      },
      prefill: {
        name: addressId?.firstname,
        email: "",
        contact: addressId?.mobilenumber || "",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#262427",
      },
    };

    console.log(options, "options werwrw");

    if (window.Razorpay) {
      const rzp = new window.Razorpay(options);
      rzp.open();
    } else {
      console.error("Razorpay script is not loaded");
    }
  };

  // PAYMENT VERIFICATION

  const verifyPayment = async (payment) => {
    try {
      const response = await axios.post(
        "/razerpay/verifyorder",
        {
          razorpay_order_id: payment?.razorpay_order_id,
          razorpay_payment_id: payment?.razorpay_payment_id,
        },
        {
          headers: {
            Authorization: loginToken,
            razorpaysignature: payment?.razorpay_signature,
          },
        }
      );
      console.log(response);
      if (response.data.success == "200") {
        purchaseAdd(payment);
      } else {
        setopenAlert(!openAlert);
        setErrMessage("Payment failed");
      }
    } catch (error) {
      console.error(error);
      setopenAlert(!openAlert);
      setErrMessage("Payment verification failed");
    }
  };

  // const createProductInfo = (
  //   productIds,
  //   variantIds,
  //   productAmounts,
  //   quantities,
  //   taxes,
  //   overallAmounts
  // ) => {
  //   return productIds.map((productId, index) => ({
  //     productid: productId,
  //     variantId: variantIds[index],
  //     Productamount: productAmounts[index],
  //     quantity: quantities[index],
  //     Tax: taxes[index],
  //     overAllAmount: overallAmounts[index],
  //   }));
  // };

  // const calculateTotals = (productinfo) => {
  //   return {
  //     totalQuantity: productinfo.reduce((sum, item) => sum + item.quantity, 0),
  //     totalTax: productinfo.reduce((sum, item) => sum + item.Tax, 0),
  //   };
  // };
  const getCartData = (
    ActiveTab,
    cartData,
    pharmacyCartData,
    vaccinationBooking,
    bookingVaccine,
    selectedDewormProduct
  ) => {
    const sourceData = bookingVaccine
      ? vaccinationBooking
      : ActiveTab === "cart"
      ? cartData
      : pharmacyCartData;

    if (!sourceData || typeof sourceData !== "object") {
      console.error("Invalid source data:", sourceData);
      return {
        taxAmount: [],
        productAmount: [],
        quantity: [],
        variantId: [],
        productId: [],
        productOverallAmount: [],
        productName: [],
      };
    }

    const getPrice = (item) => {
      if (bookingVaccine) {
        return item?.Product?.productvariants[0]?.price;
      }
      return item?.productvariant?.price;
    };

    const getTax = (item) => {
      if (bookingVaccine) {
        return item?.Product?.productvariants[0]?.Tax;
      }
      return item?.productvariant?.Tax;
    };

    const getId = (item) => {
      if (bookingVaccine) {
        return item?.Product?.id;
      }
      return item?.Products?.id;
    };

    const getProductName = (item) => {
      if (bookingVaccine) {
        return item?.Product?.ProductName;
      }
      return item?.Products?.ProductName;
    };


    // Custom getter for selectedDewormProduct
    const getDewormPrice = () => {
      if(selectedDewormProduct){
      return selectedDewormProduct?.productvariants?.[0]?.price;
      }
      return null;
    };

    const getDewormTax = () => {
      if(selectedDewormProduct){
      return selectedDewormProduct?.productvariants?.[0]?.Tax;
      }
      return null;
    };

    const getDewormProductName = () => {
      if(selectedDewormProduct){
      return selectedDewormProduct?.ProductName;
      }
      return null;
    };

    const getDewormProductId = () => {
      if(selectedDewormProduct){
      return selectedDewormProduct?.id;
      }
      return null;
    };

    const getDewormVariantId = () => {
      if(selectedDewormProduct){
      return selectedDewormProduct?.productvariants?.[0]?.id;
      }
      return null;
    };

    const safeMap = (array, accessor) => {
      if (!Array.isArray(array)) {
        console.warn("Expected array but got:", array);
        return [];
      }
      return array
        .map((item) => {
          try {
            return accessor(item);
          } catch (error) {
            console.error("Error accessing item property:", error);
            return null;
          }
        })
        .filter((item) => item !== null);
    };

    const arrays = Object.values(sourceData)
      .map((value) => (Array.isArray(value) ? value : [value]))
      .filter(Array.isArray);

      const result = {
        taxAmount: [
          ...arrays.flatMap((array) => safeMap(array, (item) => getTax(item))),
          ...(bookingVaccine && selectedDewormProduct && getDewormTax()
            ? [getDewormTax()] 
            : [])
        ],
        productAmount: [
          ...arrays.flatMap((array) => safeMap(array, (item) => getPrice(item))),
          ...(bookingVaccine && selectedDewormProduct && getDewormPrice()
            ? [getDewormPrice()] 
            : [])
        ],
        quantity: [
          ...arrays.flatMap((array) => 
            safeMap(array, (item) => (bookingVaccine ? 1 : item?.quantity))
          ),
          ...(bookingVaccine && selectedDewormProduct ? [1] : [])
        ],
        variantId: [
          ...arrays.flatMap((array) => 
            safeMap(array, (item) =>
              bookingVaccine
                ? item?.Product?.productvariants[0]?.id
                : item?.variantId
            )
          ),
          ...(bookingVaccine && selectedDewormProduct && getDewormVariantId()
            ? [getDewormVariantId()] 
            : [])
        ],
        productId: [
          ...arrays.flatMap((array) => safeMap(array, (item) => getId(item))),
          ...(bookingVaccine && selectedDewormProduct && getDewormProductId()
            ? [getDewormProductId()] 
            : [])
        ],
        productOverallAmount: [
          ...arrays.flatMap((array) => safeMap(array, (item) => getPrice(item))),
          ...(bookingVaccine && selectedDewormProduct && getDewormPrice()
            ? [getDewormPrice()] 
            : [])
        ],
        productName: [
          ...arrays.flatMap((array) => safeMap(array, (item) => getProductName(item))),
          ...(bookingVaccine && selectedDewormProduct && getDewormProductName()
            ? [getDewormProductName()] 
            : [])
        ],
      };

    return result;
  };

  const purchaseAdd = async (payment) => {
    console.log("Payment Details:", payment);
    console.log(
      "Address Details:",
      addresses.map((item) => item)
    );

    const {
      taxAmount,
      productAmount,
      quantity,
      variantId,
      productId,
      productName,
      productOverallAmount,
    } = getCartData(
      ActiveTab,
      cartData,
      pharmacyCartData,
      vaccinationBooking,
      bookingVaccine,
      selectedDewormProduct,
    );

    // Log extracted data - now showing all items
    console.log("Extracted Cart Data:", {
      taxAmount,
      productAmount,
      quantity,
      variantId,
      productId,
      productOverallAmount,
    });

    // Create productinfo array with all items
    const productinfo = productId.map((id, index) => ({
      ProductName: String(productName[index]),
      productid: Number(productId[index]),
      variantId: Number(variantId[index]),
      Productamount: String(productAmount[index]),
      quantity: String(quantity[index]),
      Tax: Number(taxAmount[index]),
      overAllAmount: Number(productOverallAmount[index]),
    }));

    // Validate address details
    const requiredAddressFields = [
      "firstname",
      "mobilenumber",
      "area",
      "city",
      "state",
      "pincode",
      "HouseFloorNo",
      "district",
    ];
    const missingFields = addresses.flatMap((address) =>
      requiredAddressFields.filter((field) => !address[field])
    );

    if (missingFields.length > 0) {
      console.error("Missing address fields:", missingFields);
      throw new Error("Complete address information is required");
    }

    // Format shipping address
    const shippingAddress = addresses
  .filter((address) => address.id === selectedAddressId)
  .map((address) => ({
    name: address.firstname + " " + address.lastname,
    mobile: address.mobilenumber,
    houseNo: address.HouseFloorNo,
    area: address.area,
    district: address.district,
    city: address.city,
    state: address.state,
    pincode: address.pincode,
  }));

    // Validate payment details
    if (
      !payment.razorpay_order_id ||
      !payment.razorpay_signature ||
      !payment.razorpay_payment_id
    ) {
      console.error("Missing payment details:", payment);
      throw new Error("Complete payment information is required");
    }

    const formData = {
      Productamount: productAmount.reduce(
        (sum, amount) => sum + Number(amount),
        0
      ),
      Tax: taxAmount.reduce((sum, tax) => sum + Number(tax), 0),
      quantity: quantity.reduce((sum, qty) => sum + Number(qty), 0),
      productinfo: productinfo.map((item) => ({
        ...item,
        Productamount: Number(item.Productamount),
        quantity: Number(item.quantity),
        overAllAmount: Number(item.overAllAmount),
      })),
      overAllAmount: amount,
      razorpay_order_id: payment.razorpay_order_id,
      Signature: payment.razorpay_signature,
      razorpay_id: payment.razorpay_payment_id,
      ShippingAddress: shippingAddress,
      orderType: bookingVaccine
        ? "Medical"
        : ActiveTab === "cart"
        ? "General"
        : "Medical",
      paravetSelected: bookingVaccine
        ? "Yes"
        : ActiveTab === "cart"
        ? ""
        : paraVetChecked
        ? "Yes"
        : "No",
      selectedDate: bookingVaccine
        ? localStorage.getItem("selectedVaccineDate")
        : ActiveTab === "cart"
        ? ""
        : localStorage.getItem("selectedVaccineDate") ?? "",
      timeslot: bookingVaccine
        ? localStorage.getItem("selectedVaccineTimeSlot")
        : ActiveTab === "cart"
        ? ""
        : localStorage.getItem("selectedVaccineTimeSlot") ?? "",
      PrescriptionId: ActiveTab === "cart" ? "" : "",
      selectedPetIds:
        ActiveTab === "cart"
          ? ""
          : petSelectionDetails.map((item) => ({
              petId: Number(item.petId),
              productId: Number(item.productId),
            })),
    };

    console.log("Final Request Payload:", JSON.stringify(formData, null, 2));

    try {
      const purchaseResponse = await axios.post("/shop/purchaseadd", formData, {
        headers: {
          Authorization: loginToken,
          "Content-Type": "application/json",
        },
      });

      if (purchaseResponse.data.status === 200) {
        console.log("Purchase successful:", purchaseResponse.data);
        setGifDetails({
          gif: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/remove-wishlist.png",
          message: "Removed from wishlist Successfully!",
        });
        setShowSuccessGif(true);
        setopenOrderSuccess(true);
        localStorage.clear();
        handleClose();
        navigate("/");
      } else {
        console.error("Purchase response not OK:", purchaseResponse.data);
      }
    } catch (error) {
      console.error("Purchase Error:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        requestData: formData,
      });
      throw error;
    }
  };

  const handleSelectAddress = (addressId) => {
    setSelectedAddressId(addressId);
    setAddressId(addressId);
    console.log("Selected Address ID:", addressId); // Save the selected address ID
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      isClose();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000);
  };

  const handleClose1 = () => {
    setIsClosing1(true);
    setTimeout(() => {
      setIsClosing1(false);
      setAddNewAddress((prev) => !prev);
      setIsEditing(null);
    }, 1000);
  };

  const handleAddNewAddress = () => {
    setAddNewAddress((prev) => !prev);
  };

  // Handle the edit button click
  const handleEditAddress = (index) => {
    if (!addNewAddress) {
      // Only set editing if address form isn't already open
      const selectedAddress = addresses[index];
      setIsEditing(selectedAddress.id);
      setAddNewAddress(true); // This will open the address form
    }
  };
  console.log(addresses, "addresses");

  return (
    <div
      className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        <div className="checkout-container">
          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
          <div className="shop-checkout-title">
            <DogPettingIcon name={"cart-checkout"} />
            <h2>Proceed to Check - Out</h2>
          </div>
        </div>
        <>
          <div className="checkout-delivery">
            <div className="checkout-delivery-details">
              <div>
                <h2>Delivery to</h2>
                <div className="delivery-details">
                  {isLoading ? (
                    <p>Loading addresses...</p>
                  ) : addresses.length > 0 ? (
                    addresses.map((address, index) => (
                      <div
                        key={address.id} // Assuming `address.id` is a unique identifier
                        className={`delivery-details-address ${
                          selectedAddressId === address.id ? "selected" : ""
                        }`}
                        onClick={() => handleSelectAddress(address.id)} // Select the address
                        style={{
                          border:
                            selectedAddressId === address.id
                              ? "2px solid black"
                              : "",
                        }}
                      >
                        <h4>{`${address.firstname} `}</h4>
                        <h5>{`${
                          address?.HouseFloorNo ? address.HouseFloorNo : ""
                        }, ${address.area}, ${address.city}, ${
                          address.state
                        }, ${address.pincode}`}</h5>
                        <p>Phone No. {address.mobilenumber}</p>
                        <div
                          className="delivery-details-edit"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent click from triggering select
                            handleEditAddress(index);
                          }}
                        >
                          <p>Edit</p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        alignContent: "center",
                        fontSize: "clamp(.8rem, 1vw, 3.6rem)",
                      }}
                    >
                      Add a new address below.
                    </p>
                  )}
                  <div
                    className="delivery-details-addaddress "
                    onClick={() => setAddNewAddress((prev) => !prev)}
                  >
                    <DogPettingIcon name="add-address" />
                    <h2>Add Address</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="overflow-hidden ">
              {!bookingVaccine && (
                <>
                  {ActiveTab === "cart" && <CartItems />}
                  {ActiveTab === "pharmacy" && <PharmacyItems />}
                </>
              )}
              {bookingVaccine && (
                <>
                  <PharmacyItems
                    bookingData={bookingData}
                    bookingVaccine={bookingVaccine}
                  />
                </>
              )}

              {bookingVaccine && (
                <div className="max-w-100vw overflow-auto bg-gray-100 p-[1vw] mx-[1vw] rounded-[1vw] cursor-pointer">
                  {/* Header with checkbox */}
                  <div
                    className={`flex items-center gap-[.5vw] cursor-pointer ${
                      isCheckedDeworm ? "mb-[4vh]" : "mb-0"
                    }`}
                    onClick={() => setIsCheckedDeworm(!isCheckedDeworm)}
                  >
                    <input
                      type="checkbox"
                      className="h-[2.5vh] w-[2vw] cursor-pointer"
                      onChange={() => setIsCheckedDeworm(!isCheckedDeworm)}
                      checked={isCheckedDeworm}
                    />
                    <h2 className="text-[clamp(1rem,1vw,3.5rem)] font-[500]">
                      Deworm Your Dog For Health
                    </h2>
                  </div>

                  {/* Products container with transition */}
                  <div
                    className={`transition-all duration-500 ease-in-out overflow-hidden ${
                      isCheckedDeworm
                        ? "max-h-[60vh] opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                  >
                    <div className="flex gap-[1vw] overflow-x-auto pb-[1vw] scrollbar-hide">
                      {dewormProducts.map((product, index) => (
                        <div
                          key={index}
                          className="relative flex-shrink-0 w-[20vw] flex flex-col gap-[2.5vh] bg-white rounded-[1vw] p-[1.2vw] shadow-sm"
                          onClick={() => handleProductSelection(product)}
                        >
                          {/* Selection Circle */}
                          <div className="absolute top-[1vw] right-[1vw]">
                            <div className="w-[1.5vw] h-[1.5vw] border-2 border-gray-300 rounded-full flex items-center justify-center transition-all duration-200">
                              {selectedDewormProduct?.id === product.id && (
                                <div className="w-[0.8vw] h-[0.8vw] bg-gray-500 rounded-full" />
                              )}
                            </div>
                          </div>

                          <div className="flex items-center gap-[1vw]">
                            <div className="w-[3vw] h-[6vh] bg-gray-200 rounded-[.5vw] flex items-center justify-center">
                              <Icons
                                name={
                                  product.Type === "Tablet"
                                    ? "Dewormtablet"
                                    : "Dewormcapsule"
                                }
                              />
                            </div>
                            <h3 className="font-[550] text-[clamp(1rem,1vw,3.5rem)]">
                              {product.ProductName}
                            </h3>
                          </div>

                          <p className="text-[clamp(.8rem,.8vw,2rem)] text-[#676767] line-clamp-3">
                            {product.ProductDetails[0].Description}
                          </p>

                          <div className="flex items-center justify-end gap-[1vw]">
                            <span className="text-[clamp(1rem,1vw,3.5rem)] text-black font-[550]">
                              ₹{product.productvariants[0].price}
                            </span>
                            <span className="text-[clamp(1rem,1vw,3.5rem)] text-[color: rgba(0, 0, 0, 0.70)] font-[550] line-through">
                              ₹{product.productvariants[0].MRP}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              <div className="p-[1vw]">
                <div className="shop-cart-ordersummary">
                  <h3>Order Summary</h3>
                  <div className="shop-cart-ordersummary-details">
                    <div className="shop-cart-ordersummary-list">
                      <h6>Subtotal</h6>
                      <p>
                        ₹
                        {!bookingVaccine
                          ? (ActiveTab === "cart"
                              ? calculateCartTotal()
                              : calculatePharmacyCartTotal()
                            ).toFixed(2)
                          : vaccineTotalSum +
                            (selectedDewormProduct && Number(dewormTotal))}
                      </p>
                    </div>
                    <div className="shop-cart-ordersummary-list">
                      <h6>Discount on MRP</h6>
                      <p>
                        - ₹
                        {!bookingVaccine
                          ? totalDiscount.toFixed(2)
                          : vaccinationBookingDiscount.toFixed(2)}
                      </p>
                    </div>
                    {showParaVet && (
                      <div className="shop-cart-ordersummary-list">
                        <h6>ParaVet</h6>
                        <p>₹{paraVetAmount?.data?.price || 0}</p>
                      </div>
                    )}
                    {/* <div className="shop-cart-ordersummary-list">
                        <h6>GST</h6>
                        <p>
                          - ₹{" "}
                          {(
                            (ActiveTab === "cart"
                              ? calculateCartTotal()
                              : calculatePharmacyCartTotal()) * 0.18
                          ).toFixed(2)}
                        </p>
                      </div> */}
                    {/* <div className="shop-cart-ordersummary-list">
                      <h6>Shipping</h6>
                      <p>FREE</p>
                    </div> */}
                    <div className="shop-cart-line"></div>
                    <div className="shop-cart-ordersummary-list">
                      <h6>Total</h6>
                      <p>
                        ₹
                        {!bookingVaccine
                          ? ActiveTab === "cart"
                            ? finalCartTotal
                            : finalPharmacyCartTotal
                          : finalVaccineBookingAmount}
                      </p>
                    </div>
                  </div>
                  <div className="shop-cart-ordersummary-list cart-total-savings">
                    <h6>Your total savings</h6>
                    <p>
                      ₹
                      {!bookingVaccine
                        ? totalDiscount.toFixed(2)
                        : vaccinationBookingDiscount.toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shopcart-checkoutbuttons flex items-center gap-[.5vw] ">
            {error && <p className="error-message">{error}</p>}
            <button className="shop-cart-button" onClick={handlePayment}>
              <DogPettingIcon name="check" />
              &ensp;₹
              {!bookingVaccine
                ? ActiveTab === "cart"
                  ? finalCartTotal
                  : finalPharmacyCartTotal
                : finalVaccineBookingAmount}
              &nbsp; Pay now
            </button>
          </div>
        </>

        {(addNewAddress || isEditing !== null) && (
          <Address
            isClosing={isClosing1}
            handleClose={handleClose1}
            isEditing={isEditing}
            initialAddress={
              isEditing !== null
                ? addresses.find((addr) => addr.id === isEditing)
                : null
            }
            loginToken={loginToken}
            setAddresses={setAddresses}
            addresses={addresses}
            fetchAddresses={fetchAddresses}
          />
        )}
        {showSuccessGif && (
          <ShopGif
            onClose={handleGifClose}
            message={gifDetails.message}
            gif={gifDetails.gif}
          />
        )}
      </div>
    </div>
  );
}
