import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import DogPettingIcon from "../../Pages/Homepage/DogPettingIcon";
import Notification from "./Notification/Notification";
import Faqs from "./Faqs/Faqs";
import { OwnerProfileModal } from "../../Pages/Profile/Petprofile/Settings/OwnerProfileModal";
import { PrivacySecurityModal } from "../../Pages/Profile/Petprofile/Settings/PrivacySecurityModal";
import { LogoutModal } from "../../Pages/Profile/Petprofile/Settings/LogoutModal";
import Reminder from "../../Pages/Profile/Petprofile/Overview/Reminder/Reminder";
import TrackOrder from "../../shop/components/TrackOrder/TrackOrder";

export default function HeaderMenu({ isMenuOpen, isClose }) {
  const [isModalClosing, setIsModalClosing] = useState(false);
  const [trackOrderOpen, setTrackOrderOpen] = useState(false);
  const [FaqOpen, setFaqOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [PrivacySecurityOpen, setPrivacySecurityOpen] = useState(false);
  const [LogoutOpen, setLogoutOpen] = useState(false);
  const [isReminderOpen, setIsReminderOpen] = useState(false);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const handleClose = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setIsModalClosing(false);
      isClose();
    }, 300);
  };

  const handleItemClick = (name) => {
    switch (name.toLowerCase()) {
      case "owner's profile":
        setProfileOpen(true);
        break;
      case "trackorder":
        setTrackOrderOpen(true);
        break;
      case "reminder":
        setIsReminderOpen(true);
        break;
      case "privacy & security":
        setPrivacySecurityOpen(true);
        break;
      case "logout":
        setLogoutOpen(true);
        break;
      case "faq":
        setFaqOpen(true);
        break;
      default:
        break;
    }
  };

  const ProfileData = [
    {
      name: "Owner's Profile",
      icon: "owner-profile",
      onClick: () => handleItemClick("Owner's Profile"),
    },
    {
      name: "Track Order",
      icon: "track-order",
      onClick: () => handleItemClick("trackorder"),
    },
    {
      name: "Reminder",
      icon: "reminders-icon",
      onClick: () => handleItemClick("Reminder"),
    },
    {
      name: "Privacy & Security",
      icon: "privacy",
      onClick: () => handleItemClick("Privacy & Security"),
    },
    {
      name: "Logout",
      icon: "logout",
      onClick: () => handleItemClick("Logout"),
    },
    // {
    //   name: "FAQ",
    //   icon: "faqicon",
    //   onClick: () => handleItemClick("FAQ"),
    // },
  ];

  const modalContent = (
    <div
      className={`fixed inset-0 modal-overlay z-[9999]
        ${isModalClosing ? "opacity-0 fade-out" : "opacity-100"}`}
      onClick={handleOverlayClick}
    >
      <div
        className={`modal-container
          ${
            isModalClosing
              ? "scale-95 opacity-0 close"
              : "scale-100 opacity-100"
          }`}
      >
        <div className="modal-top-header">
          <div className="modal-title">
            <p>
              <DogPettingIcon name={"owner-profile"} /> Profile
            </p>
          </div>

          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
        </div>
        <div className="medical-grid-container px-[1.5vw] py-[1vw]">
          {ProfileData.map((data, index) => (
            <div
              key={index}
              className="medical-grid-item cursor-pointer hover:bg-gray-50 transition-colors"
              onClick={data.onClick}
            >
              <div className="medical-svgicon">
                <DogPettingIcon name={data.icon} />
              </div>
              <h3>{data.name}</h3>
            </div>
          ))}
        </div>

        {trackOrderOpen && (
          <TrackOrder isOpen={true} isClose={() => setTrackOrderOpen(false)} />
        )}
        {FaqOpen && <Faqs isOpen={true} isClose={() => setFaqOpen(false)} />}
        {profileOpen && (
          <OwnerProfileModal
            isOpen={true}
            isClose={() => setProfileOpen(false)}
          />
        )}
        {isReminderOpen && (
          <Reminder isOpen={true} onClose={() => setIsReminderOpen(false)} />
        )}
        {PrivacySecurityOpen && (
          <PrivacySecurityModal
            isOpen={true}
            isClose={() => setPrivacySecurityOpen(false)}
          />
        )}
        {LogoutOpen && (
          <LogoutModal isOpen={true} isClose={() => setLogoutOpen(false)} />
        )}
      </div>
    </div>
  );

  return createPortal(modalContent, document.body);
}
