import React, { useState, useEffect } from "react";
import Modal from "../Modal/Modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "../../../axios";
import DogPettingIcon from "../../../Pages/Homepage/DogPettingIcon";
import BillDetails from "./BillDetails";
import TrackDetails from "./TrackDetails";

export default function OrderDetails({ isOpen, isClose, Eachorder }) {
  const { login } = useSelector((state) => state);
  const navigate = useNavigate();
  const loginToken = Cookies.get("loginToken");
  const [order, setOrder] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [trackBill, setTrackBill] = useState(false);

  const [isClosing, setIsClosing] = useState(false);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000); // Reduced animation time for better UX
  };
  return (
    <div>
      <Modal
        modaltitle={"Track Order"}
        handleClose={handleClose}
        handleOverlayClick={handleOverlayClick}
        isClosing={isClosing}
        iconname={""}
      >
        <div
          className={`live-past-sessions-toggle  track-sessions-toggle shadow-[0px_4.428px_5.905px_0px_rgba(0,0,0,0.05),0px_-8.857px_32.475px_0px_rgba(0,0,0,0.10)] ${
            trackBill ? "past-tab-active" : ""
          }`}
        >
          <>
            <button
              className={!trackBill ? "active" : ""}
              onClick={() => setTrackBill(false)}
            >
              Track Order
            </button>
            <button
              className={trackBill ? "active" : ""}
              onClick={() => setTrackBill(true)}
            >
              Bill Details
            </button>
          </>
        </div>
        <div className="h-[52vh] overflow-auto flex flex-col gap-[1.5vh] no-scrollbar w-full">
          {trackBill ? <BillDetails /> : <TrackDetails Eachorder={Eachorder} />}
        </div>
      </Modal>
    </div>
  );
}
