import React, { useState, useEffect } from "react";
import "./VerificationOTP.css";
import OtpInput from "react-otp-input";
import NewPassword from "../ForgotPassword/NewPassword";
import { useSelector } from "react-redux";
import axios from "../../../../axios";
import AlertBox from "../../../../Components/Alert/Alert";
import Cookies from "js-cookie";
import PetOwnerDetails from "../Registration/PetOwnerDetails/PetOwnerDetails";
import { useLocation } from "react-router-dom";

function VerificationOTP({ status, value }) {
  const { user } = useSelector((state) => state);
  const [otp, setOtp] = useState("");
  const [showReg, setShowReg] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [open, setOpen] = useState(true);
  const [errMessage, setErrMessage] = useState("");
  const [timer, setTimer] = useState(90);
  const [canResend, setCanResend] = useState(false);
  const [resendAttempts, setResendAttempts] = useState(0);
  const MAX_RESEND_ATTEMPTS = 3;
  const signupToken = Cookies.get("signupToken");
  const loginToken = Cookies.get("loginToken");
  const location = useLocation();

  const isLoginRoute = location.pathname === "/login";

  useEffect(() => {
    startTimer();
  }, []);

  const startTimer = () => {
    setCanResend(false);
    setTimer(90);
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(interval);
          // Only allow resend if attempts are less than max
          if (resendAttempts < MAX_RESEND_ATTEMPTS) {
            setCanResend(true);
          }
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  const isEmail = (value) => {
    return value.includes("@");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        "/auth/verifyOtpEmail",
        { otp: otp },
        {
          headers: {
            Authorization: signupToken || loginToken,
          },
        }
      )
      .then((response) => {
        console.log(response.data.status, "confirmVf");

        if (response.data.status == 400) {
          // setOpenAlert(true);
          setErrMessage(response.data.message);
          return;
        }
        if (response.data.status === 200) {
          status === "confirmVf" ? setShowReg(true) : setShowNewPass(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(errMessage);

  const handleResendOtp = () => {
    if (!canResend || resendAttempts >= MAX_RESEND_ATTEMPTS) {
      if (resendAttempts >= MAX_RESEND_ATTEMPTS) {
        setOpenAlert(true);
        setErrMessage(
          "Maximum resend attempts reached. Please register again."
        );
      }
      return;
    }

    const endpoint = isEmail(value)
      ? "/auth/sendOtpEmail"
      : "/auth/sendOtpMobile";

    axios
      .get(endpoint, {
        headers: {
          Authorization: signupToken,
        },
      })
      .then((response) => {
        if (response.data.status == "200") {
          setResendAttempts((prevAttempts) => {
            const newAttempts = prevAttempts + 1;
            // If this was the last allowed attempt, don't allow any more resends
            if (newAttempts >= MAX_RESEND_ATTEMPTS) {
              setCanResend(false);
            }
            return newAttempts;
          });
          startTimer();
        } else {
          setOpenAlert(true);
          setErrMessage(response.data.message || "Failed to resend OTP");
        }
      })
      .catch((err) => {
        console.log(err);
        setOpenAlert(true);
        setErrMessage("An error occurred while resending OTP");
      });
  };

  return (
    <>
      {showReg ? (
        <PetOwnerDetails />
      ) : showNewPass ? (
        <NewPassword />
      ) : (
        <>
          <div className="confm-vf-div">
            {isLoginRoute && <h3>Verification OTP</h3>}
            <p>
              We have sent a verification code to your{" "}
              <strong>
                {isEmail(value) ? "email address" : "mobile number"}
              </strong>
            </p>
            <h4 style={{ fontWeight: "600", color: "#000" }}>
              {isEmail(value) ? value : `+91 ${value}`}
            </h4>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="vf-otp-box">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>&nbsp;</span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    style={{
                      width: "2.5vw",
                      height: "7vh",
                      fontSize: "clamp(0.8rem, 1.2vw, 3rem)",
                      margin: "0 .5vw",
                      textAlign: "center",
                      background: "#F1F5F9",
                      borderRadius: ".5vw",
                      border: "none",
                    }}
                  />
                )}
              />
            </div>
            <div className="vf-otp-p">
              <p
                style={{
                  color: "#707070",
                  fontSize: "clamp(0.8rem, 1vw, 3rem)",
                }}
              >
                {canResend ? (
                  <>
                    Didn't receive any OTP?{" "}
                    <strong
                      style={{
                        color:
                          resendAttempts >= MAX_RESEND_ATTEMPTS
                            ? "#707070"
                            : "#000",
                        cursor:
                          resendAttempts >= MAX_RESEND_ATTEMPTS
                            ? "not-allowed"
                            : "pointer",
                        fontSize: "clamp(0.8rem, 1vw, 3rem)",
                      }}
                      onClick={handleResendOtp}
                    >
                      {resendAttempts >= MAX_RESEND_ATTEMPTS
                        ? "Maximum attempts reached, try again later"
                        : `Resend Now (${
                            MAX_RESEND_ATTEMPTS - resendAttempts
                          } attempts left)`}
                    </strong>
                  </>
                ) : (
                  `Resend OTP in ${timer} seconds`
                )}
              </p>
            </div>
            {errMessage && (
              <p className="text-red-500 text-sm text-center ">{errMessage}</p>
            )}
            <div className="vf-otp-btn">
              <button type="submit">Continue</button>
            </div>
          </form>
        </>
      )}
      {/* {openAlert && (
        <AlertBox
          open={true}
          setOpen={setOpen}
          setopenAlert={setOpenAlert}
          errMessage={errMessage}
        />
      )} */}
    </>
  );
}

export default VerificationOTP;
